import { stringify } from 'qs'
import config from '../config'
import { API, API_START, API_END, ACCESS_DENIED, UNAUTHORIZED, API_ERROR, UPLOAD, UPLOAD_END, UPLOAD_START, UPLOAD_ERROR } from './types';

export function apiAction({
  resource = '',
  params = {},
  method = 'GET',
  data = null,
  accessToken = null,
  onSuccess = () => { },
  onFailure = () => { },
  carry = null,
  headersOverride = null
}) {
  const composedResource = `${config.apiBaseUrl}${resource}`
  const remainingParams = { ...params }
  delete remainingParams.range
  delete remainingParams.filters
  delete remainingParams.sort
  const url = prepareUrlForQuery(composedResource, params.range, params.filters, params.sort, remainingParams)
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      carry,
      headersOverride
    }
  };
}

export function uploadAction({
  url = '',
  method = 'POST',
  data = null,
  accessToken = null,
  onSuccess = () => { },
  onFailure = () => { },
  carry = null,
  headersOverride = null
}) {
  return {
    type: UPLOAD,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      carry,
      headersOverride: {
        ...headersOverride,
        'Content-Type': 'multipart/form-data' // set Content-Type header to multipart/form-data
      }
    }
  };
}

export const apiStart = (carry) => ({
  type: API_START,
  payload: carry
});

export const apiEnd = (carry) => ({
  type: API_END,
  payload: carry
});

export const accessDenied = (url) => ({
  type: ACCESS_DENIED,
  payload: {
    url
  }
});

export const unauthorized = (payload) => ({
  type: UNAUTHORIZED,
  payload
});

export const apiError = (error) => ({
  type: API_ERROR,
  error
});

export const uploadStart = (carry) => ({
  type: UPLOAD_START,
  payload: carry
});

export const uploadEnd = (carry) => ({
  type: UPLOAD_END,
  payload: carry
});

export const uploadError = (error) => ({
  type: UPLOAD_ERROR,
  error
});

function prepareUrlForQuery(resource, range, filters, sort, remainingParams) {
  const { page, limit } = range || {}
  let query = {}
  if (filters) {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(filters)) {
      query[`filters[${key}]`] = value
    }
  }
  if (page) {
    query = { ...query, 'range[page]': page }
  }
  if (limit) {
    query = { ...query, 'range[limit]': limit }
  }
  if (sort) {
    const { field, direction } = sort
    const parts = field.split('.')
    if (parts.length > 0) {
      const sortKey = parts.reduce((key, part) => `${key}[${part}]`, 'sort')
      query = {
        ...query,
        [sortKey]: direction,
      }
    }
  }

  if (remainingParams) {
    query = { ...query, ...remainingParams }
  }

  const stringifiedQuery = stringify(query, { arrayFormat: 'brackets' })
  return [resource, stringifiedQuery].filter((el) => el).join('?')
}