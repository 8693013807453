export const GET_INSPECTIONS = 'GET_INSPECTIONS';
export const GET_INSPECTIONS_SUCCESS = 'GET_INSPECTIONS_SUCCESS';
export const GET_INSPECTIONS_FAILED = 'GET_INSPECTIONS_FAILED';
export const GET_INSPECTION_ITEMS = 'GET_INSPECTION_ITEMS';
export const GET_INSPECTION_ITEMS_SUCCESS = 'GET_INSPECTION_ITEMS_SUCCESS';
export const GET_INSPECTION_ITEMS_FAILED = 'GET_INSPECTION_ITEMS_FAILED';
export const DELETE_INSPECTION = 'DELETE_INSPECTION';
export const DELETE_INSPECTION_SUCCESS = 'DELETE_INSPECTION_SUCCESS';
export const DELETE_INSPECTION_FAILED = 'DELETE_INSPECTION_FAILED';

