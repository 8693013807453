import React from 'react'
import { ReactComponent as Flows } from '../../assets/ic_flows.svg';
import '../../theme/overrides/ative.css';
import '../../theme/overrides/inative.css';

const FlowsIcon = (state) => (
  <Flows className={state ? 'ative' : 'inative'}/>
);

export default FlowsIcon;
