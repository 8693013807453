import { AUTH_LOGIN, AUTH_LOGIN_FAILED, AUTH_LOGIN_SUCCESS, AUTH_GET_WORKSPACES, AUTH_GET_WORKSPACES_SUCCESS, AUTH_GET_WORKSPACES_FAILED, AUTH_CHANGE_WORKSPACE, AUTH_CHANGE_WORKSPACE_SUCCESS, AUTH_CHANGE_WORKSPACE_FAILED } from './types';
import { apiAction } from '../../api/actions';
import { setSession } from '../../session/actions';

export function login(dispatch, email, password) {
  return apiAction({
    resource: '/su/auth/login',
    method: 'POST',
    data: {
      email,
      password
    },
    onSuccess: (data) => onSuccessLogin(dispatch, data),
    onFailure: (error) => onFailedLogin(error),
    carry: {
      label: AUTH_LOGIN
    },
  });
}

function onSuccessLogin(dispatch, data) {
  dispatch(setSession(data));
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload: data
  };
}

function onFailedLogin(error) {
  return {
    type: AUTH_LOGIN_FAILED,
    payload: {
      error
    }
  };
}

export function getWorkspaces(onFailure) {
  return apiAction({
    resource: '/su/auth/get-workspaces',
    method: 'GET',
    onSuccess: (data) => onSuccessGetWorkspaces(data),
    onFailure: (error) => onFailedGetWorkspaces(error, onFailure),
    carry: {
      label: AUTH_GET_WORKSPACES
    },
  });
}

function onSuccessGetWorkspaces(data) {
  return {
    type: AUTH_GET_WORKSPACES_SUCCESS,
    payload: data
  };
}

function onFailedGetWorkspaces(error, onFailure) {
  onFailure();
  return {
    type: AUTH_GET_WORKSPACES_FAILED,
    payload: {
      error
    }
  };
}

export function changeWorkspace(dispatch, workspaceId, onSuccess, onFailure) {
  return apiAction({
    resource: '/su/auth/change-workspace',
    method: 'POST',
    data: {
      workspaceId,
    },
    onSuccess: (data) => onSuccessChangeWorkspace(dispatch, data, onSuccess),
    onFailure: (error) => onFailedChangeWorkspace(error, onFailure),
    carry: {
      label: AUTH_CHANGE_WORKSPACE
    },
  });
}

function onSuccessChangeWorkspace(dispatch, data, onSuccess) {
  dispatch(setSession(data));
  onSuccess();
  return {
    type: AUTH_CHANGE_WORKSPACE_SUCCESS,
    payload: data
  };
}

function onFailedChangeWorkspace(error, onFailure) {
  onFailure();
  return {
    type: AUTH_CHANGE_WORKSPACE_FAILED,
    payload: {
      error
    }
  };
}