import axios from 'axios';
import { API, UPLOAD } from '../api/types';
import { accessDenied, apiError, apiStart, apiEnd, unauthorized, uploadStart, uploadEnd, uploadError } from '../api/actions';
import { clearSession } from '../session/actions';

const apiMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      async (action) => {
        next(action);

        if (action.type !== API && action.type !== UPLOAD) return;

        const { url, params, method, data, onSuccess, onFailure, carry, headers } = action.payload;

        // axios default configs
        axios.defaults.baseURL = process.env.REACT_API_BASE_URL || '';

        if (action.type === API) {
          axios.defaults.headers.common['Content-Type'] = 'application/json';
          let session = null;
          try {
            session = getState().session.current;
          } catch (err) {
            dispatch(clearSession());
          }

          if (session) {
            axios.defaults.headers.common.Authorization = `Bearer ${session.token}`;
          }
        } else {
          axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
          axios.defaults.headers.common.Authorization = ' ';
        }

        if (carry) {
          dispatch(action.type === API ? apiStart(carry) : uploadStart(carry));
        }

        let requestHeaders = headers;
        if (headers == null) {
          requestHeaders = axios.defaults.headers.common;
        }

        axios
          .request({
            url,
            method,
            requestHeaders,
            params,
            data
          })
          .then(({ data }) => {
            dispatch(onSuccess(data));
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              dispatch(unauthorized(action.payload));
              dispatch(onFailure(error));
            } else {
              dispatch(action.type === API ? apiError(error) : uploadError(error));
              dispatch(onFailure(error));

              if (error.response && error.response.status === 403) {
                dispatch(accessDenied(window.location.pathname));
              }
            }
          })
          .finally(() => {
            if (carry) {
              dispatch(action.type === API ? apiEnd(carry) : uploadEnd(carry));
            }
          });
      };

export default apiMiddleware;
