import { BOOTSTRAP_APP } from '../events/types';
import { SET_SESSION, CLEAR_SESSION } from '../session/types';
import { setSession, clearSession, refreshSession } from '../session/actions';
import LocalStorage from '../utils/storage';
import { UNAUTHORIZED } from '../api/types';

const STORAGE_KEY_PREFIX = 'session_';

let isRefreshing = false;

const sessionMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);

        let session = getState().session.current;

        switch (action.type) {
          case BOOTSTRAP_APP: {
            const storedSession = loadSessionFromStorage(LocalStorage);
            if (storedSession) {
              dispatch(setSession(storedSession));
            }
            break;
          }
          case SET_SESSION:
            isRefreshing = false;
            storeSessionInStorage(LocalStorage, action.payload);
            break;
          case CLEAR_SESSION:
            clearSessionFromStorage(LocalStorage);
            break;
          case UNAUTHORIZED:
            if (!isRefreshing) {
              try {
                session = getState().session.current;
              } catch (err) {
                dispatch(clearSession());
                // Redirect to login
              }

              // Trigger refresh endpoint and retry failed request
              isRefreshing = true;
              dispatch(refreshSession(dispatch, session.refreshToken, action))
            }
            break;
          default:
            if (!session) {
              const storedSession = loadSessionFromStorage(LocalStorage);
              if (storedSession) {
                dispatch(setSession(storedSession));
              }
            }
            break;
        }
      };

function loadSessionFromStorage(storage) {
  const key = `${STORAGE_KEY_PREFIX}current`;
  return storage.get(key, true);
}

function storeSessionInStorage(storage, session) {
  const key = `${STORAGE_KEY_PREFIX}current`;
  return storage.set(key, session, true);
}

function clearSessionFromStorage(storage) {
  const key = `${STORAGE_KEY_PREFIX}current`;
  storage.remove(key);
}

export default sessionMiddleware;
