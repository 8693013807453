import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
// material 
import { styled } from '@mui/material/styles'
import { Grid, Typography, Container, IconButton } from '@mui/material';
// component
import Page from '../../../components/Page';
import PageHeader from "../../../components/page-header";
import InfoCard from '../../../components/resource/see-resource/info-resource-card';
import { ReactComponent as ReturnIcon } from '../../../assets/ic_return.svg';

const ContainerStyle = styled(Grid)(({ theme }) => ({
  shadowColor: theme.palette.secondary,
  backgroundColor: theme.palette.grey,
  marginTop: 25,
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const ReturnButton = {
  marginTop: 'calc(100vh - 120vh)',
};

export default function SeeResource() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [resource, setResource] = useState(null);


  useEffect(() => {
    if (location.state) {
      const { resource } = location.state;
      setResource(resource);
    }
  }, [location]);

  return (
    <>
      <Page title={t('resource.see-resource.tab-title', { name: resource?.name })}>
        <IconButton
          onClick={() => navigate('/dashboard/resources')}
          style={ReturnButton}
        >
          <ReturnIcon />
        </IconButton>
        <Container>
          <PageHeader title={t('resource.see-resource.page-title', { name: resource?.name })} />
          <Typography variant="subtitle1">{t('resource.see-resource.page-sub-title', { name: resource?.resourceType.name })}</Typography>
          <ContainerStyle>
            <Grid container direction="column" spacing={2} marginBottom={5}>
              <Grid item>
                <SubTitle variant="overline">{t('resource.see-resource.resource-id')}</SubTitle>
              </Grid>
              <Grid item>
                <InfoCard
                  t={t}
                  data={resource}
                />
              </Grid>
            </Grid>
          </ContainerStyle>
        </Container>
      </Page>
    </>
  );
};