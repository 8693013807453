import { GET_FLOWS_SUCCESS, GET_FLOWS_FAILED } from './types';

const initialState = {
    current: {}
};

export default function flowsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_FLOWS_SUCCESS:
            return {
                ...state,
                current: action.payload,
            };
        case GET_FLOWS_FAILED:
            return {
                ...state,
                error: action.payload,
            };
        default:
            break;
    }
    return state
};