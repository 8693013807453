export const GET_RESOURCE_TYPES = 'GET_RESOURCE_TYPES';
export const GET_RESOURCE_TYPES_SUCCESS = 'GET_RESOURCE_TYPES_SUCCESS';
export const GET_RESOURCE_TYPES_FAILED = 'GET_RESOURCE_TYPES_FAILED';
export const ADD_RESOURCE_TYPE = 'ADD_RESOURCE_TYPE';
export const ADD_RESOURCE_TYPE_SUCCESS = 'ADD_RESOURCE_TYPE_SUCCESS';
export const ADD_RESOURCE_TYPE_FAILED = 'ADD_RESOURCE_TYPE_FAILED';
export const EDIT_RESOURCE_TYPE = 'EDIT_RESOURCE_TYPE';
export const EDIT_RESOURCE_TYPE_SUCCESS = 'EDIT_RESOURCE_TYPE_SUCCESS';
export const EDIT_RESOURCE_TYPE_FAILED = 'EDIT_RESOURCE_TYPE_FAILED';
export const DEACTIVATE_RESOURCE_TYPE = 'DEACTIVATE_RESOURCE_TYPE';
export const DEACTIVATE_RESOURCE_TYPE_SUCCESS = 'DEACTIVATE_RESOURCE_TYPE_SUCCESS';
export const DEACTIVATE_RESOURCE_TYPE_FAILED = 'DEACTIVATE_RESOURCE_TYPE_FAILED';