import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// layouts
import DashboardLayout from './layouts/dashboard';
// pages
import Blog from './pages/Blog';
import ResourceType from './pages/resource-type';
import Resource from './pages/resources';
import SeeResource from './pages/resources/see-resource';
import Items from './pages/items';
import Flows from './pages/flows';
import Login from './pages/login';
import ForgotPassword from './pages/forgot-password';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/dashboard';
import User from './pages/users';
import Permissions from './pages/permissions';
import Inspections from './pages/inspections';
import SeeInspection from './pages/inspections/see-inspection';
import LocalStorage from "./utils/storage";

// ----------------------------------------------------------------------
const STORAGE_KEY_PREFIX = 'session_';

function loadSessionFromStorage(storage) {
  const key = `${STORAGE_KEY_PREFIX}current`;
  return storage.get(key, true);
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired
};

function RequireAuth({ children }) {
  const authed = useSelector((state) => !!state.session.current) || loadSessionFromStorage(LocalStorage) !== null;
  return authed === true ? children : <Navigate to="/login" replace />;
}

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: (
        <RequireAuth>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'inspections', element: <Inspections /> },
        { path: 'inspections/:id', element: <SeeInspection /> },
        { path: 'resource-type', element: <ResourceType /> },
        { path: 'resources', element: <Resource /> },
        { path: 'resources/:id', element: <SeeResource /> },
        { path: 'items', element: <Items /> },
        { path: 'users', element: <User /> },
        { path: 'permissions', element: <Permissions /> },
        { path: 'flows', element: <Flows /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: '/',
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}