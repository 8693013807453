export const GET_RESOURCES= 'GET_RESOURCES';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAILED = 'GET_RESOURCES_FAILED';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const ADD_RESOURCE_SUCCESS = 'ADD_RESOURCE_SUCCESS';
export const ADD_RESOURCE_FAILED = 'ADD_RESOURCE_FAILED';
export const EDIT_RESOURCE = 'EDIT_RESOURCE';
export const EDIT_RESOURCE_SUCCESS = 'EDIT_RESOURCE_SUCCESS';
export const EDIT_RESOURCE_FAILED = 'EDIT_RESOURCE_FAILED';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_FAILED = 'DELETE_RESOURCE_FAILED';