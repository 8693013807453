import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
// material
import { Grid } from '@mui/material';
// component
import BaseDialog from '../../base-dialog';
import { selectItems } from '../../../pages/items/selectors';
import { getItems } from '../../../pages/items/actions';
import { findObjectById } from '../../../pages/items';
import generateColumns from './columns';
import BFHierarchyTable, { getLeafsIds } from '../../hierarchy-table';
import { FlowTypes } from '../../../entities/flow-type'

ItemFlowDialog.propTypes = {
    flow: PropTypes.object,
    previouslySelectedItems: PropTypes.array,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onAddItems: PropTypes.func,
}

export default function ItemFlowDialog({ flow, previouslySelectedItems, open, onClose, onAddItems }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(selectItems());

    const [loading, setLoading] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);

    const [itemsTableData, setItemsTableData] = useState(null);
    const [itemsTableState, setItemsTableState] = useState({
        page: 0,
        sort: null,
        filters: null
    });

    const resource = `item`;

    const resetState = () => {
        setLoading(false);
        setSelectedItems(previouslySelectedItems);
    };

    // Method to get the object that contains all selected items if it has.
    function getPrimaryObject(data, id) {
        let found = null;
        data.forEach(item => {
            const leafs = getLeafsIds(data, 'children', item.id);
            if (leafs.length > 0 && leafs.includes(id)) {
                found = item;
            } else if (item.id === id) {
                found = item; // If the item itself is selected and has no children
            }
        });
        return found;
    };

    // Method to find an item by ID
    function findItemById(items, itemId) {
        return items.find(item => item.id === itemId);
    }

    const onCloseDialog = (event, reason) => {
        if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
            onClose();
        } else {
            resetState();
            onClose();
        }
    };

    const onConfirm = () => {
        const newItems = [];

        if (selectedItems.length === 0) {
            return;
        }

        // Depending on the type, set the array to different 
        // arrangement of objects.
        if (flow?.flowType?.name === FlowTypes.SEQUENTIAL) {
            selectedItems.forEach(item => {
                newItems.push(findObjectById(itemsTableData.items, 'children', item));
            });

            // Remove duplicates
            onAddItems(newItems.filter((item, index, self) => self.findIndex(t => t.id === item.id) === index));
            resetState();
            onClose();
        } else {
            selectedItems.forEach(item => {
                const object = getPrimaryObject(itemsTableData.items, item) || findItemById(itemsTableData.items, item);

                if (object && !newItems.some(nItem => nItem.id === object.id)) {
                    newItems.push(object);
                }
            });

            onAddItems(newItems);
            resetState();
            onClose();
        }
    };

    const itemsTableColumns = generateColumns({
        t,
    });

    useEffect(() => {
        dispatch(getItems({
            range: { page: itemsTableState.page + 1 },
            filters: itemsTableState.filters,
            sort: itemsTableState.sort,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsTableState]);

    useEffect(() => {
        if (items) {
            setItemsTableData({ ...items });
        }
        setSelectedItems(previouslySelectedItems);
    }, [items, previouslySelectedItems]);

    return (
        <BaseDialog
            title={`${flow?.name} - ${flow?.flowType?.translatedName}`}
            open={open}
            onClose={onCloseDialog}
            onConfirm={onConfirm}
            loading={loading}
            openButtonTitle={t('common.save')}
            confirmDisabled={selectedItems.length < 1}
        >
            <Grid container direction="column" spacing={4} marginTop={2}>
                <BFHierarchyTable
                    id={resource}
                    data={{ ...itemsTableData }}
                    columns={itemsTableColumns}
                    leaf={'children'}
                    page={itemsTableState.page}
                    sort={itemsTableState.sort}
                    onChangePage={(currentPage) => {
                        setItemsTableState({ ...itemsTableState, page: currentPage });
                    }}
                    onColumnSortChange={(changedColumn, direction) => {
                        const newSort = {
                            field: changedColumn,
                            direction: direction.toUpperCase(),
                        };
                        setItemsTableState({ ...itemsTableState, sort: newSort });
                    }}
                    selectableRows
                    fullSelector
                    rowsSelected={selectedItems}
                    onRowSelectionChange={setSelectedItems}
                />
            </Grid>
        </BaseDialog>
    );
};