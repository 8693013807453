import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Grid, Typography } from '@mui/material';
// components
import InfoCard from '../../components/info-card';
import Page from '../../components/Page';
// Redux
import { getStatistics } from './actions';
import { selectStatistics } from './selectors';


export default function Dashboard() {
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const statistics = useSelector(selectStatistics());

  const [items, setItems] = useState([])

  useEffect(() => {
    dispatch(getStatistics());
  }, [dispatch]);

  useEffect(() => {
    if (statistics) {
      setItems(statistics.cards)
    }
  }, [statistics]);

  return (
    <Page title={t('dashboard.tab-title')}>
      <Container>
        <Typography variant="h3" sx={{ mb: 5 }}>
          {t('dashboard.page-title')}
        </Typography>
        <Grid container alignContent="start" spacing={3}>
          {items && items.map((item) => (
            <Grid item key={item.label}>
              <InfoCard title={item.label} value={item.count.toString()} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
