import React from 'react'
// components
import {
  IconButton,
} from '@mui/material';

import { ReactComponent as EditIcon } from '../../assets/ic_edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/ic_delete.svg'
import { ReactComponent as SeeIcon } from '../../assets/ic_see.svg'

export default function generate({ t, onSee, onModify, onDelete, hasEditPermission, hasRemovePermission }) {
  const columns = [
    {
      name: 'name',
      label: t('resource.items.identifier'),
    },
    {
      name: 'resourceType.name',
      label: t('resource.items.resource-type'),
    },
    {
      name: 'see',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <IconButton
            onClick={() => onSee(tableMeta.rowIndex)}
            color="primary"
          >
            <SeeIcon />
          </IconButton>
        ),
      },
    }
  ];

  if (hasEditPermission) {
    columns.push({
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <IconButton
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        ),
      },
    });
  }

  if (hasRemovePermission) {
    columns.push({
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <IconButton
            onClick={() => onDelete(tableMeta.rowIndex)}
            color="primary"
          >
            <DeleteIcon />
          </IconButton>
        ),
      },
    });
  }

  return columns;
}
