// component
import { useSelector } from 'react-redux';
import i18 from '../../i18n';
import DashboardIcon from '../../pages/dashboard/Icon';
import InspectionsIcon from '../../pages/inspections/Icon';
import ResourceTypeIcon from '../../pages/resource-type/Icon';
import ResourceIcon from '../../pages/resources/Icon';
import ItemsIcon from '../../pages/items/Icon';
import FlowsIcon from '../../pages/flows/Icon';
import UsersIcon from '../../pages/users/Icon';
import { hasPermission } from '../../pages/login/selectors';
import { Permissions } from '../../entities/permissions';

// ----------------------------------------------------------------------

export default function NavConfig() {
  const navConfig = [];

  if (useSelector(hasPermission(Permissions.PAGE_DASHBOARD_ROOT)))
    navConfig.push({
      title: i18.t('sidebar.dashboard'),
      path: '/dashboard/app',
      icon: DashboardIcon,
    });

  if (useSelector(hasPermission(Permissions.PAGE_INSPECTIONS_ROOT))) {
    navConfig.push({
      title: i18.t('sidebar.inspections'),
      path: '/dashboard/inspections',
      icon: InspectionsIcon,
    });
  }
  if (useSelector(hasPermission(Permissions.PAGE_RESOURCE_TYPES_ROOT))) {
    navConfig.push({
      title: i18.t('sidebar.resource-type'),
      path: '/dashboard/resource-type',
      icon: ResourceTypeIcon,
    });
  }
  if (useSelector(hasPermission(Permissions.PAGE_RESOURCES_ROOT))) {
    navConfig.push({
      title: i18.t('sidebar.resources'),
      path: '/dashboard/resources',
      icon: ResourceIcon,
    });
  }
  if (useSelector(hasPermission(Permissions.PAGE_ITEMS_ROOT))) {
    navConfig.push({
      title: i18.t('sidebar.items'),
      path: '/dashboard/items',
      icon: ItemsIcon,
    });
  }
  if (useSelector(hasPermission(Permissions.PAGE_FLOWS_ROOT))) {
    navConfig.push({
      title: i18.t('sidebar.flows'),
      path: '/dashboard/flows',
      icon: FlowsIcon,
    });
  }
  const userMenuChildren = [];
  if (useSelector(hasPermission(Permissions.PAGE_USERS_ROOT))) {
    userMenuChildren.push({
      title: i18.t('sidebar.users-list'),
      path: '/dashboard/users',
    });
  }
  if (useSelector(hasPermission(Permissions.PAGE_PERMISSIONS_ROOT))) {
    userMenuChildren.push({
      title: i18.t('sidebar.permissions'),
      path: '/dashboard/permissions',
    });
  }
  if (userMenuChildren.length > 0) {
    navConfig.push({
      title: i18.t('sidebar.users'),
      icon: UsersIcon,
      children: userMenuChildren,
    });
  }

  return navConfig;
}
