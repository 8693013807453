import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import { Container, SvgIcon, Grid } from "@mui/material";
import { useNotificationsProvider } from '../../context/NotificationsContext'
// components
import Page from '../../components/Page';
import PageHeader from '../../components/page-header';
import { ReactComponent as AddIcon } from '../../assets/ic_add.svg';
import { getUsers, deleteUser } from './actions';
import { selectUsers } from './selectors';
import generateColumns from './columns';
import AddUserDialog from '../../components/users/add-user-dialog';
import EditUserDialog from '../../components/users/edit-user-dialog';
import BFTable from '../../components/table';
import BaseDialog from '../../components/base-dialog'

const ContainerStyle = styled(Grid)(({ theme }) => ({
    shadowColor: theme.palette.secondary,
    backgroundColor: theme.palette.grey,
    marginTop: 25,
}));

export default function User() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { showNotification } = useNotificationsProvider()

    const users = useSelector(selectUsers());

    const [selected, setSelected] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
    const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
    const [loading, setLoading] = useState(false)

    const [itemsTableState, setItemsTableState] = useState({
        page: 0,
        sort: null,
        filters: null,
    });

    const [itemsTableData, setItemsTableData] = useState(null);

    const user = `user`;

    const onAdd = () => {
        if (!openAddUserDialog) {
            setOpenAddUserDialog(true);
        }
    }

    const onEdit = (rowIndex) => {
        if (!openEditUserDialog) {
            setSelectedUser(itemsTableData.items[rowIndex]);
            setOpenEditUserDialog(true);
        }
    }

    const onDelete = (rowIndex) => {
        if (!openDeleteUserDialog) {
            setSelectedUser(itemsTableData.items[rowIndex]);
            setOpenDeleteUserDialog(true)
        }
    }

    const onDeleted = () => {
        setLoading(true)

        dispatch(deleteUser(selectedUser.id, () => {
            setLoading(false)
            setOpenDeleteUserDialog(false)
            showNotification('success', t('user.delete-user-dialog.success', { name: selectedUser.fullName }));
            dispatch(getUsers({
                range: { page: itemsTableState.page + 1 },
                filters: itemsTableState.filters,
                sort: itemsTableState.sort,
            }));
        }, () => {
            setLoading(false)
            showNotification('error', t('user.delete-user-dialog.error', { name: selectedUser.fullName }));
        }));
    }

    const onChangeSelected = (rowIndexs) => {
        // rowID is an array of the selected checkboxs
        if (rowIndexs.length > 1) {
            // Select all
            setSelected([]);
            setSelected(rowIndexs.map(item => item.index));
        } else if (rowIndexs.length === 1) {
            // Select one at a time
            const id = rowIndexs[0].index;
            if (selected.includes(id)) {
                setSelected(selected.filter((selectedRow) => selectedRow !== id));
            } else {
                setSelected([...selected, id]);
            }
        } else {
            // Deselect all
            setSelected([]);
        }
    }

    const itemsTableColumns = generateColumns({
        t,
        onEdit,
        onDelete,
    });

    useEffect(() => {
        dispatch(getUsers({
            range: { page: itemsTableState.page + 1 },
            filters: itemsTableState.filters,
            sort: itemsTableState.sort,
        }));
    }, [dispatch, itemsTableState]);

    useEffect(() => {
        if (users) {
            setItemsTableData(users);
        }
    }, [users]);

    return (
        <>
            <BaseDialog
                open={openDeleteUserDialog}
                onClose={() => setOpenDeleteUserDialog(false)}
                onConfirm={onDeleted}
                title={t('user.delete-user-dialog.title', { name: selectedUser.fullName })}
                loading={loading}
            />
            <AddUserDialog
                title={t('user.add-user-dialog.title')}
                open={openAddUserDialog}
                onClose={() => setOpenAddUserDialog(false)}
                onCreated={() => {
                    dispatch(getUsers({
                        range: { page: itemsTableState.page + 1 },
                        filters: itemsTableState.filters,
                        sort: itemsTableState.sort,
                    }));
                    setOpenAddUserDialog(false);
                }}
            />
            <EditUserDialog
                title={t('user.edit-user-dialog.title')}
                value={selectedUser}
                open={openEditUserDialog}
                onClose={() => setOpenEditUserDialog(false)}
                onEdited={() => {
                    dispatch(getUsers({
                        range: { page: itemsTableState.page + 1 },
                        filters: itemsTableState.filters,
                        sort: itemsTableState.sort,
                    }));
                    setOpenEditUserDialog(false);
                }}
            />
            <Page title={t('user.tab-title')}>
                <Container>
                    <PageHeader title={t('user.page-title')} buttonTitle={t('user.add-user')} buttonIcon={(<SvgIcon>
                        {<AddIcon />}
                    </SvgIcon>)} onButtonClick={onAdd} />
                    <ContainerStyle>
                        <BFTable
                            id={user}
                            columns={itemsTableColumns}
                            data={itemsTableData}
                            page={itemsTableState.page}
                            sort={itemsTableState.sort}
                            onChangePage={(currentPage) => {
                                setItemsTableState({ ...itemsTableState, page: currentPage });
                            }}
                            onColumnSortChange={(changedColumn, direction) => {
                                const newSort = {
                                    field: changedColumn,
                                    direction: direction.toUppperCase(),
                                };
                                setItemsTableState({ ...itemsTableState, sort: newSort });
                            }}
                            rowsSelected={selected}
                            onRowSelectionChange={(rows) => onChangeSelected(rows)}
                        />
                    </ContainerStyle>
                </Container>
            </Page>
        </>
    );
};