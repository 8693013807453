// material
import { styled } from "@mui/material/styles";
import { IconButton, Typography } from "@mui/material";
// component
import { ReactComponent as SeeIcon } from '../../assets/ic_see.svg';
import { ReactComponent as DeleteIcon } from '../../assets/ic_delete.svg';

const Validation = styled(Typography)(({ theme, state }) => ({
    color: state === '1' ? theme.palette.success.main : theme.palette.error.main,
}));

export default function generate({ t, onSee, onDelete, hasRemovePermission }) {
    const columns = [
        {
            name: 'resource.name',
            label: t('inspections.items.resource'),
        },
        {
            name: 'resource.resourceType.name',
            label: t('inspections.items.resource-type'),
        },
        {
            name: 'flow.name',
            label: t('inspections.items.flow-type'),
        },
        {
            name: 'controlDate',
            label: t('inspections.items.date'),
        },
        {
            name: 'conformity.id',
            label: t('inspections.items.appropriate'),
            options: {
                customBodyRender: (val) => (
                    <Validation state={val.toString()}>{val ? t('inspections.items.yes') : t('inspections.items.no')}</Validation>
                ),
            },
        },
        {
            name: 'onSee',
            label: ' ',
            options: {
                customBodyRender: (val, tableMeta) => (
                    <IconButton
                        onClick={() => onSee(tableMeta.rowIndex)}
                        color="primary"
                    >
                        <SeeIcon />
                    </IconButton>
                ),
            },
        }
    ];

    if (hasRemovePermission) {
        columns.push({
            name: 'onDelete',
            label: ' ',
            options: {
                customBodyRender: (val, tableMeta) => (
                    <IconButton
                        onClick={() => onDelete(tableMeta.rowIndex)}
                        color="primary"
                    >
                        <DeleteIcon />
                    </IconButton>
                ),
            },
        })
    }

    return columns;
};
