import PropTypes from 'prop-types';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import BaseDialog from '../../base-dialog'
import { editResourceType } from '../../../pages/resource-type/actions'
import AutocompleteInput from '../../autocomplete-input'
import { useNotificationsProvider } from '../../../context/NotificationsContext'

EditResourceTypeDialog.propTypes = {
  title: PropTypes.string,
  value: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onEdited: PropTypes.func,
};

export default function EditResourceTypeDialog({
  title,
  value,
  open,
  onClose,
  onEdited,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [name, setName] = useState('')

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError(null)
    setName('')
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (name.length === 0) {
      setError('name_required')
      return
    }

    setLoading(true)

    const data = {
      name
    }

    dispatch(editResourceType(value.id, data, () => {
      resetState()
      onEdited()
    }, () => {
      showNotification('error', t('common.api-error'))
    }))
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={title}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item marginTop={2}>
          <AutocompleteInput
            error={error === 'name_required'}
            onChange={(val) => {
              setName(val)
            }}
            onInputChange={(val) => {
              setName(val)
            }}
            defaultValue={value && { id: value.id, title: value.name }}
            label={t('resource-type.add-resource-type-dialog.name-title')}
            resource="resource-type/autocomplete"
            required
            canDelete
            refreshOnInputChange
            clearOnBlur={false}
            freeSolo
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}