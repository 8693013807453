import React from 'react'
import { ReactComponent as Items } from '../../assets/ic_items.svg';
import '../../theme/overrides/ative.css';
import '../../theme/overrides/inative.css';

const ItemsIcon = (state) => (
  <Items className={state ? 'ative' : 'inative'}/>
);

export default ItemsIcon;
