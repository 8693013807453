// material
import { IconButton, SvgIcon } from '@mui/material';
// component
import { ReactComponent as PhotoPlaceholder } from '../../assets/ic_photo_placeholder.svg';
import { ReactComponent as AddIcon } from '../../assets/ic_add.svg';
import { ReactComponent as EditIcon } from '../../assets/ic_edit.svg';
import './styles.css';

export default function generate({ t, itemsColumns, onAdd, onEdit, hasEditPermission, hasAddPermission }) {
  const columns = [
    {
      name: 'photos',
      label: t('items.items.icon'),
      options: {
        customBodyRender: (val) => (
          val.length > 0 ?
            <img
              src={val[0]}
              alt=''
              style={{ width: 50, height: 50, borderRadius: 5, objectFit: 'cover' }}
            /> :
            <PhotoPlaceholder width={28} style={{ marginLeft: '10px' }} />
        )
      },
    },
    {
      name: 'title',
      label: t('items.items.identifier'),
    },
    {
      name: 'description',
      label: t('items.items.description'),
    },
  ]

  if (itemsColumns) {
    itemsColumns.forEach((key) => {
      const extras = {
        name: key,
        label: t(`items.items.extras.${key}`)
      }

      columns.push(extras);
    });
  }

  if (hasEditPermission) {
    columns.push({
      name: 'editItem',
      label: ' ',
      options: {
        customBodyRender: (val, tableMeta) => (
          <IconButton
            onClick={() => onEdit(tableMeta.value)}
            color="primary"
          >
            <SvgIcon><EditIcon /></SvgIcon>
          </IconButton>
        )
      }
    });
  }

  if (hasAddPermission) {
    columns.push({
      name: 'addItem',
      label: ' ',
      options: {
        customBodyRender: (val, tableMeta) => {
          if (tableMeta.value.level < 3) {
            return (
              <IconButton
                onClick={() => onAdd(tableMeta.value)}
                color="primary"
              >
                <SvgIcon><AddIcon className='custom-add-icon' /></SvgIcon>
              </IconButton>
            )
          }
          return null;

        },
      }
    });
  }

  return columns;
}
