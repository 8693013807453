import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button } from '@mui/material';
// component
import BaseDialog from '../../base-dialog';
import { addFlow } from '../../../pages/flows/actions';
import { getFlowTypes } from '../../../redux/flow-types/actions';
import { selectFlowTypes } from '../../../redux/flow-types/selectors';
import { selectIsFeatureEnabled } from '../../../redux/features/selectors';
import { useNotificationsProvider } from '../../../context/NotificationsContext';
import { FlowTypes } from '../../../entities/flow-type'
import { Features } from '../../../entities/features';
import { ReactComponent as AddIcon } from '../../../assets/ic_add.svg';
import DynamicFieldSelectorDialog from '../../dynamic-field-selector';
import DynamicFieldValueComponent from '../../dynamic-field-value';


AddFlowDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onCreated: PropTypes.func,
};

export default function AddFlowDialog({ title, open, onClose, onCreated }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { showNotification } = useNotificationsProvider();

    const flowTypes = useSelector(selectFlowTypes());

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [flowTitle, setFlowTitle] = useState('');
    const [type, setType] = useState('');
    const [hasSignatures, setHasSignatures] = useState(false);
    const [signaturesCount, setSignaturesCount] = useState(1);
    const [signatureOneTitle, setSignatureOneTitle] = useState('');
    const [signatureTwoTitle, setSignatureTwoTitle] = useState('');
    const [isResourceEvaluationFormActive, setIsResourceEvaluationFormActive] = useState(false);
    const [openDynamicFieldSelector, setOpenDynamicFieldSelector] = useState(false);
    const [dynamicFields, setDynamicFields] = useState([]);
    const [selectedDynamicField, setSelectedDynamicField] = useState(null);

    const [flowTypesList, setFlowTypesList] = useState([]);

    const isSignatureFeatureEnabled = useSelector(selectIsFeatureEnabled(Features.FT_INSPECTION_SIGNATURES));
    const isResourceEvaluationFormFeatureEnabled = useSelector(selectIsFeatureEnabled(Features.FT_RESOURCE_EVALUATION_FORM));

    const resetState = () => {
        setError(null);
        setLoading(false);
        setFlowTitle('');
        setType('');
        setHasSignatures(false);
        setSignaturesCount(1);
        setSignatureOneTitle('');
        setSignatureTwoTitle('');
        setIsResourceEvaluationFormActive(false);
        setDynamicFields([]);
        setSelectedDynamicField(null);
    };

    const onCloseDialog = (event, reason) => {
        if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
            onClose();
        } else {
            resetState();
            onClose();
        }
    };

    const onFieldAdded = (data) => {
        setOpenDynamicFieldSelector(false);

        if (selectedDynamicField) {
            // Replace previous value with new one
            const newFields = dynamicFields.map((field) => {
                if (field === selectedDynamicField) {
                    return data;
                }
                return field;
            });
            setDynamicFields([...newFields]);
            setSelectedDynamicField(null);
            return;
        }

        setDynamicFields([...dynamicFields, data]);
    };

    const onEditField = (data) => {
        setSelectedDynamicField(data);
        setOpenDynamicFieldSelector(true);
    };

    const onDeleteField = (data) => {
        // Delete field from dynamicFields
        const newFields = dynamicFields.filter((field) => field !== data);
        setDynamicFields(newFields);
    };

    const onConfirm = () => {
        if (flowTitle.length === 0) {
            setError('title_required');
            return;
        }

        if (type === '' || type === 'none') {
            setError('type_required');
            return;
        }

        setLoading(true);

        // Create array of string with signature titles if it has signatures
        let signatureTitles = [];
        if (hasSignatures) {
            if (signaturesCount === 1) {
                signatureTitles = [signatureOneTitle];
            } else {
                signatureTitles = [signatureOneTitle, signatureTwoTitle];
            }
        }

        const data = {
            name: flowTitle,
            flowTypeId: type,
            hasSignatures,
            isResourceEvaluationFormActive,
        };

        if (hasSignatures) {
            data.signatureTitles = signatureTitles;
        }

        if (isResourceEvaluationFormActive) {
            data.evaluationFormDynamicFields = dynamicFields;
        }

        dispatch(addFlow(data, () => {
            resetState();
            onCreated();
        }, (error) => {
            const errorCode = error.response.data.validationErrors && error.response.data.validationErrors[0].errorCode;
            if (errorCode === 'FLOW_DETAILS_ALREADY_USED') {
                showNotification('error', t('flows.add-flow-dialog.already-exists-error'));
            } else if (errorCode === 'FLOW_SIGNATURE_TITLES_MISSING') {
                if (signaturesCount === 1) {
                    setError('signature_one_title_required');
                    showNotification('error', t('flows.add-flow-dialog.signature-title-missing'));
                } else {
                    if (signatureOneTitle.length === 0) {
                        setError('signature_one_title_required');
                    } else if (signatureTwoTitle.length === 0) {
                        setError('signature_two_title_required');
                    }
                    showNotification('error', t('flows.add-flow-dialog.signature-titles-missing'));
                }
            } else {
                showNotification('error', t('common.api-error'));
            }
            setLoading(false);
        }));
    };

    useEffect(() => {
        if (flowTypes === undefined) {
            dispatch(getFlowTypes());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (flowTypes) {
            setFlowTypesList(flowTypes.flowTypes.map((flowType) => {
                if (flowType.name === FlowTypes.FREE) {
                    return {
                        id: flowType.id,
                        title: t('flows.add-flow-dialog.select-type.free'),
                    }
                } if (flowType.name === FlowTypes.SEQUENTIAL) {
                    return {
                        id: flowType.id,
                        title: t('flows.add-flow-dialog.select-type.sequencial'),
                    }
                }
                return {
                    id: flowType.id,
                    title: flowType.name,
                }
            }));
        }
    }, [flowTypes, t]);

    return (
        <BaseDialog
            open={open}
            onClose={onCloseDialog}
            onConfirm={onConfirm}
            title={title}
            loading={loading}
        >
            <DynamicFieldSelectorDialog
                open={openDynamicFieldSelector}
                onClose={() => {
                    setSelectedDynamicField(null);
                    setOpenDynamicFieldSelector(false);
                }}
                onCreated={onFieldAdded}
                title={t('components.dynamic-field-selector.title')}
                value={selectedDynamicField}
                titleOnly
            />
            <Grid container direction="column" spacing={4}>
                <Grid item marginTop={2}>
                    <TextField
                        error={error === 'title_required'}
                        onChange={(event) => {
                            setFlowTitle(event.target.value);
                        }}
                        label={t('flows.add-flow-dialog.flow-title')}
                        variant="standard"
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item marginBottom={isSignatureFeatureEnabled ? 0 : 2}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="flow-type">{t('flows.add-flow-dialog.type')}</InputLabel>
                        <Select
                            labelId="flow-type"
                            id="type-selector"
                            value={type}
                            onChange={(event) => setType(event.target.value)}
                            label={t('flows.add-flow-dialog.type')}
                        >
                            {
                                flowTypesList.map((flowType) => (
                                    <MenuItem key={flowType.id} value={flowType.id}>{flowType.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {isSignatureFeatureEnabled && (
                    <Grid item>
                        <hr />
                        <FormControlLabel control={
                            <Checkbox checked={hasSignatures} onChange={(event) => setHasSignatures(event.target.checked)} value={hasSignatures} />
                        } label={t('flows.add-flow-dialog.add-signatures')} />
                    </Grid>
                )}
                {hasSignatures && (
                    <Grid item>
                        <Grid item>
                            <FormControl fullWidth>
                                <InputLabel id="signatures-select-label">{t('flows.add-flow-dialog.signatures-number')}</InputLabel>
                                <Select
                                    labelId="signatures-select-label"
                                    id="signatures-select"
                                    value={signaturesCount}
                                    label={t('flows.add-flow-dialog.signatures-number')}
                                    onChange={(event) => setSignaturesCount(event.target.value)}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item marginTop={2}>
                            <TextField
                                error={error === 'signature_one_title_required'}
                                onChange={(event) => {
                                    setSignatureOneTitle(event.target.value);
                                }}
                                label={t('flows.add-flow-dialog.signature-one-title')}
                                variant="standard"
                                fullWidth
                                required
                            />
                        </Grid>
                        {signaturesCount === 2 && (
                            <Grid item marginTop={2} marginBottom={2}>
                                <TextField
                                    error={error === 'signature_two_title_required'}
                                    onChange={(event) => {
                                        setSignatureTwoTitle(event.target.value);
                                    }}
                                    label={t('flows.add-flow-dialog.signature-two-title')}
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                {isResourceEvaluationFormFeatureEnabled && (
                    <Grid item marginTop={hasSignatures ? 0 : -4} marginBottom={isResourceEvaluationFormActive ? -4 : 0}>
                        <hr />
                        <FormControlLabel control={
                            <Checkbox checked={isResourceEvaluationFormActive} onChange={(event) => setIsResourceEvaluationFormActive(event.target.checked)} value={isResourceEvaluationFormActive} />
                        } label={t('flows.add-flow-dialog.enable-resource-evaluation-form')} />
                    </Grid>
                )}
                {isResourceEvaluationFormActive && (
                    <Grid item>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                {dynamicFields?.map((field) => (
                                    <Grid item key={`${field.title} - ${field.dataType} - ${field.value}`} marginTop={2}>
                                        <DynamicFieldValueComponent value={field} onEdit={onEditField} onDelete={onDeleteField} titleOnly />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item marginTop={2} marginBottom={2}>
                            <Button
                                variant="text"
                                color="primary"
                                startIcon={<AddIcon style={{ fill: 'currentColor' }} />}
                                iconColor="blue"
                                onClick={() => {
                                    setOpenDynamicFieldSelector(true);
                                }}
                            >
                                {t('resource.add-resource-dialog.add-field')}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </BaseDialog>
    );
};