import * as TYPE from './types';
import { apiAction } from '../../api/actions';

export function getStatistics() {
  return apiAction({
    resource: '/su/dashboard',
    onSuccess: (data) => onGetSuccess(data),
    onFailure: (error) => onGetFailed(error),
    carry: {
      label: TYPE.GET_DASHBOARD_STATISTICS
    },
  });
};

function onGetSuccess(data) {
  return {
    type: TYPE.GET_DASHBOARD_STATISTICS_SUCCESS,
    payload: data,
  };
};

function onGetFailed(error) {
  return {
    type: TYPE.GET_DASHBOARD_STATISTICS_FAILED,
    payload: {
      error
    }
  };
};