class Storage {
  constructor(backend = window.localStorage) {
    this.backend = backend;
  }

  get(key, decode = false) {
    const data = this.backend.getItem(key);
    if (decode) {
      return JSON.parse(data);
    }
    return data;
  }

  set(key, value, encode = false) {
    let data = value;
    if (encode) {
      data = JSON.stringify(data);
    }
    return this.backend.setItem(key, data);
  }

  remove(key) {
    return this.backend.removeItem(key);
  }

  clear() {
    return this.backend.clear();
  }
}

const LocalStorage = new Storage(window.localStorage);

export default LocalStorage;
