import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { Container, Grid, SvgIcon } from '@mui/material';
// components
import PageHeader from '../../components/page-header';
import InfoCard from '../../components/info-card';
import Page from '../../components/Page';
import AddResourceTypeDialog from '../../components/resource-type/add-resource-type-dialog'
import EditResourceTypeDialog from '../../components/resource-type/edit-resource-type-dialog'
import BaseDialog from '../../components/base-dialog'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { ReactComponent as AddIcon } from '../../assets/ic_add.svg';
import { getResourceTypes, deactivateResourceType } from './actions'
import { selectResourceTypes } from './selectors'
import { hasPermission } from '../login/selectors';
import { Permissions } from '../../entities/permissions';
import AutocompleteInput from '../../components/autocomplete-input';
import FiltersCard from '../../components/filters-card';


export default function ResourceType() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { showNotification } = useNotificationsProvider()

  const resourceTypes = useSelector(selectResourceTypes())

  // Permissions
  const hasAddPermission = useSelector(hasPermission(Permissions.PAGE_RESOURCE_TYPES_ADD))
  const hasEditPermission = useSelector(hasPermission(Permissions.PAGE_RESOURCE_TYPES_EDIT))
  const hasRemovePermission = useSelector(hasPermission(Permissions.PAGE_RESOURCE_TYPES_REMOVE))

  const [openAddResourceTypeDialog, setOpenAddResourceTypeDialog] = useState(false)
  const [openEditResourceTypeDialog, setOpenEditResourceTypeDialog] = useState(false)
  const [openDeleteResourceTypeDialog, setOpenDeleteResourceTypeDialog] = useState(false)
  const [selectedResourceType, setSelectedResourceType] = useState({})
  const [loading, setLoading] = useState(false)
  const [gridResourceTypes, setGridResourceTypes] = useState(false)

  // Filters
  const [resourceType, setResourceType] = useState('');

  const onCancel = () => {
    setResourceType('');
  }

  const onSearch = () => {
    dispatch(getResourceTypes({
      filters: computedFilters,
    },));
  };

  const computedFilters = useMemo(() => {
    const filtersObj = {
      id: resourceType ? resourceType.id : null
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key]
    )
    return filtersObj
  }, [resourceType])

  const onEdit = () => {
    if (!openEditResourceTypeDialog) {
      setOpenEditResourceTypeDialog(true)
    }
  }

  const onDelete = () => {
    if (!openDeleteResourceTypeDialog) {
      setOpenDeleteResourceTypeDialog(true)
    }
  }

  const onDeleted = () => {
    setLoading(true)

    if (selectedResourceType) {
      dispatch(deactivateResourceType(selectedResourceType.id, () => {
        dispatch(getResourceTypes({
          filters: computedFilters,
        }))
        setLoading(false)
        setOpenDeleteResourceTypeDialog(false)
        showNotification('success', t('resource-type.delete-resource-type-dialog.success', { name: selectedResourceType.name }))
      }, () => {
        showNotification('error', t('common.api-error'))
        setLoading(false)
        setOpenDeleteResourceTypeDialog(false)
      }))
    } else {
      showNotification('error', t('common.api-error'))
      setLoading(false)
      setOpenDeleteResourceTypeDialog(false)
    }
  }

  const onAdd = () => {
    if (!openAddResourceTypeDialog) {
      setOpenAddResourceTypeDialog(true)
    }
  }

  useEffect(() => {
    dispatch(getResourceTypes({
      filters: computedFilters,
    },));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (resourceTypes) {
      setGridResourceTypes(resourceTypes)
    }
  }, [resourceTypes]);


  return (
    <>
      <BaseDialog
        open={openDeleteResourceTypeDialog}
        onClose={() => setOpenDeleteResourceTypeDialog(false)}
        onConfirm={onDeleted}
        title={t('resource-type.delete-resource-type-dialog.title', { name: selectedResourceType.name })}
        loading={loading}
      />
      <AddResourceTypeDialog
        title={t('resource-type.add-resource-type-dialog.title')}
        open={openAddResourceTypeDialog}
        onClose={() => setOpenAddResourceTypeDialog(false)}
        onCreated={() => {
          dispatch(getResourceTypes({
            filters: computedFilters,
          }));
          setOpenAddResourceTypeDialog(false)
        }}
      />
      <EditResourceTypeDialog
        title={t('resource-type.edit-resource-type-dialog.title')}
        value={selectedResourceType}
        open={openEditResourceTypeDialog}
        onClose={() => setOpenEditResourceTypeDialog(false)}
        onEdited={() => {
          dispatch(getResourceTypes({
            filters: computedFilters,
          }));
          setOpenEditResourceTypeDialog(false)
        }}
      />
      <Page title={t('resource-type.tab-title')}>
        <Container>
          <PageHeader title={t('resource-type.page-title')} buttonTitle={hasAddPermission ? t('resource-type.add-resource-type') : null}
            buttonIcon={hasAddPermission ? (<SvgIcon>
              {<AddIcon />}
            </SvgIcon>) : null} onButtonClick={onAdd} />
          <Grid container direction="column" marginTop={3} marginBottom={-3}>
            <Grid item>
              <FiltersCard onSearch={onSearch} onCancel={onCancel} children={
                <Grid item container direction="row" justifyContent="flex-start">
                  <Grid item width="30%" marginRight={4}>
                    <AutocompleteInput
                      onChange={setResourceType}
                      label={t('resource-type.filter.resource-type')}
                      resource="resource-type/autocomplete"
                      value={resourceType}
                      defaultValue={resourceType}
                      refreshOnInputChange
                      clearOnBlur={false}
                      canDelete
                      freeSolo
                    />
                  </Grid>
                </Grid>
              } />
            </Grid>
          </Grid>
          <Grid container alignContent="start" spacing={3} marginTop={2}>
            {gridResourceTypes && gridResourceTypes.items && gridResourceTypes.items.map((item) => (
              <Grid item key={item.id}>
                <InfoCard title={item.name} icon={item.icon} onEdit={hasEditPermission ? () => {
                  setSelectedResourceType(item)
                  onEdit()
                } : null
                } onDelete={hasRemovePermission ? () => {
                  setSelectedResourceType(item)
                  onDelete()
                } : null} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Page>
    </>
  );
}
