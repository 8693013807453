import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';
import BaseDialog from '../../base-dialog';
import { addResource } from '../../../pages/resources/actions';
import AutocompleteInput from '../../autocomplete-input';
import { useNotificationsProvider } from '../../../context/NotificationsContext';
import { ReactComponent as AddIcon } from '../../../assets/ic_add.svg';
import DynamicFieldSelectorDialog from '../../dynamic-field-selector';
import DynamicFieldValueComponent from '../../dynamic-field-value';

AddResourceTypeDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
};

export default function AddResourceTypeDialog({ title, open, onClose, onCreated }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showNotification } = useNotificationsProvider();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [openDynamicFieldSelector, setOpenDynamicFieldSelector] = useState(false);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [selectedDynamicField, setSelectedDynamicField] = useState(null);

  const resetState = () => {
    // Clean form
    setLoading(false);
    setError(null);
    setName('');
    setType('');
    setDynamicFields([]);
    setSelectedDynamicField(null);
  };

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose();
    } else {
      resetState();
      onClose();
    }
  };

  const onFieldAdded = (data) => {
    setOpenDynamicFieldSelector(false);

    if (selectedDynamicField) {
      // Replace previous value with new one
      const newFields = dynamicFields.map((field) => {
        if (field === selectedDynamicField) {
          return data;
        }
        return field;
      });
      setDynamicFields([...newFields]);
      setSelectedDynamicField(null);
      return;
    }

    setDynamicFields([...dynamicFields, data]);
  };

  const onEditField = (data) => {
    setSelectedDynamicField(data);
    setOpenDynamicFieldSelector(true);
  };

  const onDeleteField = (data) => {
    // Delete field from dynamicFields
    const newFields = dynamicFields.filter((field) => field !== data);
    setDynamicFields(newFields);
  };

  const onConfirm = () => {
    if (name.length === 0) {
      setError('name_required');
      return;
    }

    if (type.length === 0) {
      setError('type_required');
      return;
    }

    setLoading(true);

    const data = {
      name,
      resourceTypeId: type.id,
      dynamicData: dynamicFields,
    };

    dispatch(
      addResource(
        data,
        () => {
          resetState();
          onCreated();
        },
        () => {
          showNotification('error', t('common.api-error'));
        }
      )
    );
  };

  return (
    <BaseDialog open={open} onClose={onCloseDialog} onConfirm={onConfirm} title={title} loading={loading}>
      <DynamicFieldSelectorDialog
        open={openDynamicFieldSelector}
        onClose={() => {
          setSelectedDynamicField(null);
          setOpenDynamicFieldSelector(false);
        }}
        onCreated={onFieldAdded}
        title={t('components.dynamic-field-selector.title')}
        value={selectedDynamicField}
      />
      <Grid container direction="column" spacing={4}>
        <Grid item marginTop={2}>
          <AutocompleteInput
            error={error === 'name_required'}
            onChange={(val) => {
              setName(val);
            }}
            onInputChange={(val) => {
              setName(val);
            }}
            label={t('resource.add-resource-dialog.name-title')}
            resource="resource/name-autocomplete"
            required
            canDelete
            refreshOnInputChange
            clearOnBlur={false}
            freeSolo
          />
        </Grid>
        <Grid item marginTop={2}>
          <AutocompleteInput
            error={error === 'type_required'}
            onChange={(val) => {
              setType(val);
            }}
            onInputChange={(val) => {
              setType(val);
            }}
            label={t('resource.add-resource-dialog.resource-type-title')}
            resource="resource-type/autocomplete"
            required
            canDelete
            refreshOnInputChange
            clearOnBlur={false}
            freeSolo
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            {dynamicFields?.map((field) => (
              <Grid item key={`${field.title} - ${field.dataType} - ${field.value}`} marginTop={2}>
                <DynamicFieldValueComponent value={field} onEdit={onEditField} onDelete={onDeleteField} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item marginTop={2} marginBottom={2}>
          <Button
            variant="text"
            color="primary"
            startIcon={<AddIcon style={{ fill: 'currentColor' }} />}
            iconColor="blue"
            onClick={() => {
              setOpenDynamicFieldSelector(true);
            }}
          >
            {t('resource.add-resource-dialog.add-field')}
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
}
