import * as TYPE from './types';
import { apiAction } from '../../../api/actions';

export function generateInspectionPdf(inspectionId) {
  return apiAction({
    resource: `/exports/control/${inspectionId}`,
    method: 'GET',
    onSuccess: (data) => onGenerateSuccess(data),
    onFailure: (error) => onGenerateFailed(error),
    carry: {
      label: TYPE.GENERATE_INSPECTION_PDF
    }
  });
};

function onGenerateSuccess(data) {
  return {
    type: TYPE.GENERATE_INSPECTION_PDF_SUCCESS,
    payload: data,
  };
};

function onGenerateFailed(error) {
  return {
    type: TYPE.GENERATE_INSPECTION_PDF_FAILED,
    payload: {
      error
    }
  };
};

export function startInspectionPdfGeneration(inspectionId) {
  return apiAction({
    resource: `/exports/control/${inspectionId}/pdf`,
    method: 'POST',
    data: {},
    onSuccess: (data) => onStartPdfGenerationSuccess(data),
    onFailure: (error) => onStartPdfGenerationFailed(error),
    carry: {
      label: TYPE.START_INSPECTION_PDF_GENERATION
    }
  });
};

function onStartPdfGenerationSuccess(data) {
  return {
    type: TYPE.START_INSPECTION_PDF_GENERATION_SUCCESS,
    payload: data,
  };
};

function onStartPdfGenerationFailed(error) {
  return {
    type: TYPE.START_INSPECTION_PDF_GENERATION_FAILED,
    payload: {
      error
    }
  };
};