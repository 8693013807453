import { CLEAR_SESSION, SET_SESSION } from './types';
import { AUTH_LOGIN_SUCCESS } from '../pages/login/types'

const initialState = {
  current: null,
};

export default function sessionReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        current: action.payload,
      };
    case CLEAR_SESSION:
      return {
        ...state,
        current: null,
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        current: {
          token: action.payload.token,
          refreshToken: action.payload.refreshToken,
          tenant: action.payload.tenant,
        }
      };
    default:
      break;
  }
  return state;
}
