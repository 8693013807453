
const FT_INSPECTION_DOCUMENTS = "ft_inspection_documents"
const FT_INSPECTION_SIGNATURES = "ft_inspection_signatures"
const FT_RESOURCE_EVALUATION_FORM = "ft_resource_evaluation_form"

const Features = Object.freeze({
  FT_INSPECTION_DOCUMENTS,
  FT_INSPECTION_SIGNATURES,
  FT_RESOURCE_EVALUATION_FORM
});

const FeaturesMap = Object.freeze({
  [Features.FT_INSPECTION_DOCUMENTS]: false,
  [Features.FT_INSPECTION_SIGNATURES]: false,
  [Features.FT_RESOURCE_EVALUATION_FORM]: false
});

export { FeaturesMap, Features };