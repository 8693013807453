import {
  GET_RESOURCE_TYPES_SUCCESS,
  GET_RESOURCE_TYPES_FAILED,
} from './types';

const initialState = {
  current: {}
};

export default function resourceTypeReducer(action = {}, state = initialState) {
  switch (action.type) {
    case GET_RESOURCE_TYPES_SUCCESS:
      return {
        ...initialState,
        current: action.payload,
      };
    case GET_RESOURCE_TYPES_FAILED:
      return {
        ...initialState,
        error: action.payload
      };
    default:
      break;
  }
  return state;
}