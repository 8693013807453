import PropTypes from 'prop-types'
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Container, Grid, Card, CardContent, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// component
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import 'dayjs/locale/fr';
import 'dayjs/locale/en'; // Default locale for fallback
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { getInspections, deleteInspection } from './actions';
import { selectInspections } from './selectors';
import BaseDialog from '../../components/base-dialog'
import Page from '../../components/Page';
import PageHeader from '../../components/page-header';
import generateColumns from './columns';
import AutocompleteInput from '../../components/autocomplete-input';
import BFTable from '../../components/table';
// entities
import { hasPermission } from '../login/selectors';
import { Permissions } from '../../entities/permissions';

const ContainerStyle = styled(Grid)(({ theme }) => ({
    shadowColor: theme.palette.secondary,
    backgroundColor: theme.palette.grey,
    marginTop: 25,
}));

// TODO - IMPLEMENT ENDPOINTS TO GET AND REMOVE INSPECTIONS
export default function Inspections() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showNotification } = useNotificationsProvider()

    const inspections = useSelector(selectInspections());

    const [itemsTableData, setItemsTableData] = useState(null);
    const [itemsTableState, setItemsTableState] = useState({
        page: 0,
        sort: null,
        filters: null,
    });

    const [openDeleteInspectionDialog, setOpenDeleteInspectionDialog] = useState(false)
    const [selectedInspection, setSelectedInspection] = useState({})
    const [loading, setLoading] = useState(false)

    // Permissions
    const hasRemovePermission = useSelector(hasPermission(Permissions.PAGE_INSPECTIONS_REMOVE))

    const resource = `inspections`;

    const onSee = (rowIndex) => {
        const inspection = itemsTableData.items[rowIndex];
        navigate(`/dashboard/inspections/${inspection.id}`, { state: { inspection } });
    };

    const onDelete = (rowIndex) => {
        if (!openDeleteInspectionDialog) {
            setSelectedInspection(itemsTableData.items[rowIndex])
            setOpenDeleteInspectionDialog(true)
        }
    }

    const onDeleted = () => {
        setLoading(true)

        if (selectedInspection) {
            dispatch(deleteInspection(selectedInspection.id, () => {
                dispatch(getInspections({
                    includeReportGenerating: true, // Always include inspections which reports are still generating
                    range: { page: itemsTableState.page + 1 },
                    filters: itemsTableState.filters,
                    sort: itemsTableState.sort,
                }));
                setLoading(false)
                setOpenDeleteInspectionDialog(false)
                showNotification('success', t('inspections.delete-inspection-dialog.success', { resource: selectedInspection?.resource?.name, flow: selectedInspection?.flow?.name, date: selectedInspection?.controlDate }))
            }, () => {
                showNotification('error', t('common.api-error'))
                setLoading(false)
                setOpenDeleteInspectionDialog(false)
            }))
        } else {
            showNotification('error', t('common.api-error'))
            setLoading(false)
            setOpenDeleteInspectionDialog(false)
        }
    }

    const onSearch = (filters) => {
        setItemsTableState({ ...itemsTableState, filters });
    };

    const itemsTableColumns = generateColumns({
        t,
        onSee,
        onDelete,
        hasRemovePermission,
    });

    useEffect(() => {
        dispatch(getInspections({
            includeReportGenerating: true, // Always include inspections which reports are still generating
            range: { page: itemsTableState.page + 1 },
            filters: itemsTableState.filters,
            sort: itemsTableState.sort,
        }));
    }, [dispatch, itemsTableState]);

    useEffect(() => {
        if (inspections) {
            setItemsTableData(inspections);
        }

        if (inspections?.error) {
            setItemsTableData({ items: [], range: { limit: 15 } });
        }
    }, [inspections]);

    return (
        <Page title={t('inspections.tab-title')}>
            <BaseDialog
                open={openDeleteInspectionDialog}
                onClose={() => setOpenDeleteInspectionDialog(false)}
                onConfirm={onDeleted}
                title={t('inspections.delete-inspection-dialog.title', { resource: selectedInspection?.resource?.name, flow: selectedInspection?.flow?.name, date: selectedInspection?.controlDate })}
                loading={loading}
            />
            <Container>
                <PageHeader title={t('inspections.page-title')} />
                <ContainerStyle container direction="column">
                    <Grid item marginBottom={5}>
                        <FiltersCard t={t} onSearch={onSearch} />
                    </Grid>
                    <Grid item>
                        <BFTable
                            id={resource}
                            columns={itemsTableColumns}
                            data={itemsTableData}
                            page={itemsTableState.page}
                            sort={itemsTableState.sort}
                            onChangePage={(currentPage) => {
                                setItemsTableState({ ...itemsTableState, page: currentPage });
                            }}
                            onColumnSortChange={(changedColumn, direction) => {
                                const newSort = {
                                    field: changedColumn,
                                    direction: direction.toUpperCase(),
                                };
                                setItemsTableState({ ...itemsTableState, sort: newSort });
                            }}
                        />
                    </Grid>
                </ContainerStyle>
            </Container>
        </Page>
    );
};

FiltersCard.propTypes = {
    t: PropTypes.func,
    onSearch: PropTypes.func,
};

export function FiltersCard({ t, onSearch }) {

    const [resource, setResource] = useState('');
    const [resourceType, setResourcesType] = useState('');
    const [flow, setFlow] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const onCancel = () => {
        setResource('');
        setResourcesType('');
        setFlow('');
        setStartDate(null);
        setEndDate(null);
    };

    const computedFilters = useMemo(() => {
        console.log(startDate)
        const filtersObj = {
            startDate: startDate instanceof dayjs ? startDate.format() : null,
            endDate: endDate instanceof dayjs ? endDate.format() : null,
            resourceId: resource ? resource.id : null,
            resourceTypeId: resourceType ? resourceType.id : null,
            flowId: flow || null
        }
        // remove nulls
        Object.keys(filtersObj).forEach(
            (key) => filtersObj[key] === null && delete filtersObj[key]
        )
        return filtersObj
    }, [startDate, endDate, resource, resourceType, flow])

    // Define the format based on the locale
    const getDateFormat = (locale) => {
        switch (locale) {
            case 'en':
                dayjs.locale('en');
                return 'MM/DD/YYYY';
            case 'fr':
                dayjs.locale('fr');
                return 'DD/MM/YYYY';
            default:
                dayjs.locale('pt'); // Default locale
                return 'DD/MM/YYYY';
        }
    };

    const locale = (navigator.language || navigator.userLanguage).substring(0, 2);
    const dateFormat = getDateFormat(locale);

    return (
        <Card>
            <CardContent>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="subtitle1">{t('inspections.filter.title')}</Typography>
                    </Grid>
                    <Grid item container direction="row" justifyContent="space-around">
                        <Grid item width="30%">
                            <AutocompleteInput
                                onChange={setResource}
                                label={t('inspections.filter.resource')}
                                resource="resource/name-autocomplete"
                                value={resource}
                                defaultValue={resource}
                                refreshOnInputChange
                                clearOnBlur={false}
                                canDelete
                                freeSolo
                            />
                        </Grid>
                        <Grid item width="30%">
                            <AutocompleteInput
                                onChange={setResourcesType}
                                label={t('inspections.filter.resource-type')}
                                resource="resource-type/autocomplete"
                                value={resourceType}
                                defaultValue={resourceType}
                                refreshOnInputChange
                                clearOnBlur={false}
                                canDelete

                            />
                        </Grid>
                        <Grid item width="30%">
                            <FormControl fullWidth>
                                <InputLabel id="flow-type">{t('flows.add-flow-dialog.type')}</InputLabel>
                                <Select
                                    labelId="flow-type"
                                    id="type-selector"
                                    value={flow}
                                    onChange={(event) => setFlow(event.target.value)}
                                    label={t('flows.add-flow-dialog.type')}
                                >
                                    <MenuItem value="2">{t('flows.add-flow-dialog.select-type.free')}</MenuItem>
                                    <MenuItem value="1">{t('flows.add-flow-dialog.select-type.sequencial')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" justifyContent="space-around">
                        <Grid item width="30%">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                <DatePicker
                                    label={t('inspections.filter.start-date')}
                                    value={startDate}
                                    onChange={(val) => setStartDate(val)}
                                    dateFormat={dateFormat}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item width="30%">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                <DatePicker
                                    label={t('inspections.filter.end-date')}
                                    value={endDate}
                                    onChange={(val) => setEndDate(val)}
                                    dateFormat={dateFormat}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item container direction="row" justifyContent="space-evenly" width="30%" marginTop="16px">
                            <Grid item paddingLeft={6}>
                                <Button
                                    variant="outlined"
                                    onClick={() => onCancel()}
                                >
                                    {t('common.cancel')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained"
                                    onClick={() => onSearch(computedFilters)}>
                                    {t('components.table.toolbar.search')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
