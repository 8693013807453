import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import { Container, Grid, SvgIcon, Button } from '@mui/material';
// components
import { getItems, deleteItems } from './actions';
import { selectItems } from './selectors';
import Page from '../../components/Page';
import PageHeader from '../../components/page-header';
import BaseDialog from '../../components/base-dialog';
import AddItemDialog from '../../components/item/add-item-dialog';
import EditItemDialog from '../../components/item/edit-item-dialog';
import { ReactComponent as AddIcon } from '../../assets/ic_add.svg';
import generateColumns from './columns';
import BFHierarchyTable from '../../components/hierarchy-table';
import { useNotificationsProvider } from '../../context/NotificationsContext';
import { hasPermission } from '../login/selectors';
import { Permissions } from '../../entities/permissions';
import AutocompleteInput from '../../components/autocomplete-input';
import FiltersCard from '../../components/filters-card';

const ContainerStyle = styled(Grid)(({ theme }) => ({
  shadowColor: theme.palette.secondary,
  backgroundColor: theme.palette.grey,
  marginTop: 25,
}));

export default function Item() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showNotification } = useNotificationsProvider();

  const resource = `item`

  const items = useSelector(selectItems())

  // Permissions
  const hasAddPermission = useSelector(hasPermission(Permissions.PAGE_ITEMS_ADD))
  const hasEditPermission = useSelector(hasPermission(Permissions.PAGE_ITEMS_EDIT))
  const hasRemovePermission = useSelector(hasPermission(Permissions.PAGE_ITEMS_REMOVE))

  const [itemsColumns, setItemsColumns] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openAddItemDialog, setOpenAddItemDialog] = useState(false)
  const [openEditItemDialog, setOpenEditItemDialog] = useState(false)
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [loading, setLoading] = useState(false)
  const [parentToAddEdit, setParentToAddEdit] = useState({})

  const [itemsTableState, setItemsTableState] = useState({
    page: 0,
    sort: null,
    filters: null
  })
  const [itemsTableData, setItemsTableData] = useState(null)

  // Filters
  const [item, setItem] = useState('');
  const [parentItem, setParentItem] = useState('');

  const onCancel = () => {
    setItem('');
    setParentItem('');
  }

  const onSearch = () => {
    setItemsTableState({ ...itemsTableState, filters: computedFilters });
  };

  const computedFilters = useMemo(() => {
    const filtersObj = {
      id: item ? item.id : null,
      parentId: parentItem ? parentItem.id : null,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key]
    )
    return filtersObj
  }, [item, parentItem])

  const onAdd = (item) => {
    if (!openAddItemDialog) {
      if (item) {
        setParentToAddEdit({
          id: item.id,
          title: item.title,
        })
      }
      setOpenAddItemDialog(true)
    }
  }

  const onEdit = (item) => {
    if (!openEditItemDialog) {
      setSelectedItem(item);

      if (item.parentId != null) {
        const parentItem = findObjectById(items.items, 'children', item.parentId);
        if (parentItem) {
          setParentToAddEdit({
            id: parentItem.id,
            title: parentItem.title,
          })
        }
      }
      setOpenEditItemDialog(true)
    }
  }

  const onDelete = () => {
    if (!openDeleteItemDialog) {
      if (selectedItems.length === 1) {
        setSelectedItem(findObjectById(items.items, 'children', selectedItems[0]))
      }
      setOpenDeleteItemDialog(true)
    }
  }

  const onDeleted = () => {
    setLoading(true)

    if (selectedItems && selectedItems.length > 0) {
      const data = {
        items: selectedItems
      }
      dispatch(deleteItems(data, () => {
        dispatch(getItems({
          range: { page: itemsTableState.page + 1 },
          filters: itemsTableState.filters,
          sort: itemsTableState.sort,
        }));
        setLoading(false)
        setOpenDeleteItemDialog(false)
        showNotification('success', selectedItems.length === 1 ? t('items.delete-item-dialog.delete-success', { name: selectedItem.title }) : t('items.delete-items-dialog.delete-success', { count: selectedItems.length }))
        setSelectedItems([])
        setSelectedItem({})
      }, () => {
        showNotification('error', t('common.api-error'))
        setLoading(false)
        setOpenDeleteItemDialog(false)
      }))
    } else {
      showNotification('error', t('common.api-error'))
      setLoading(false)
      setOpenDeleteItemDialog(false)
    }
  }

  // Method to extract the extra columns the user may have
  // added to the table, ignoring those considered default.
  const extractColumns = (data) => {
    if (!data || data.length === 0) {
      return []
    }
    const keys = Object.keys(data[0]);
    const columnsIgnore = ['id', 'parentId', 'title', 'description', 'photos', 'children', 'level'];
    return keys.filter((valor) => !columnsIgnore.includes(valor))
  };

  const itemsTableColumns = generateColumns({
    t,
    itemsColumns,
    onAdd,
    onEdit,
    hasEditPermission,
    hasAddPermission,
  });

  useEffect(() => {
    dispatch(getItems({
      range: { page: itemsTableState.page + 1 },
      filters: itemsTableState.filters,
      sort: itemsTableState.sort,
    }));
  }, [dispatch, itemsTableState]);

  useEffect(() => {
    if (items) {
      setItemsTableData(items);
      setItemsColumns(extractColumns(items.items));
    }
  }, [items, selectedItems]);

  return (
    <>
      <BaseDialog
        open={openDeleteItemDialog}
        onClose={() => setOpenDeleteItemDialog(false)}
        onConfirm={onDeleted}
        title={selectedItems.length === 1 ? t('items.delete-item-dialog.title', { name: selectedItem.title }) : t('items.delete-items-dialog.title', { count: selectedItems.length })}
        loading={loading}
      />
      <AddItemDialog
        title={t('items.add-item-dialog.title')}
        parent={parentToAddEdit}
        open={openAddItemDialog}
        onClose={() => {
          setOpenAddItemDialog(false)
          setParentToAddEdit({})
        }}
        onCreated={() => {
          dispatch(getItems({
            range: { page: itemsTableState.page + 1 },
            filters: itemsTableState.filters,
            sort: itemsTableState.sort,
          }));
          setOpenAddItemDialog(false)
          setParentToAddEdit({})
        }}
      />
      <EditItemDialog
        title={t('items.edit-item-dialog.title')}
        value={selectedItem}
        parent={parentToAddEdit}
        open={openEditItemDialog}
        onClose={() => {
          setOpenEditItemDialog(false)
          setSelectedItem({})
          setParentToAddEdit({})
        }}
        onEdited={() => {
          dispatch(getItems({
            range: { page: itemsTableState.page + 1 },
            filters: itemsTableState.filters,
            sort: itemsTableState.sort,
          }));
          setOpenEditItemDialog(false)
          setSelectedItem({})
          setParentToAddEdit({})
        }}
      />
      <Page title={t('items.tab-title')}>
        <Container>
          <PageHeader title={t('items.page-title')} buttonTitle={hasAddPermission ? t('items.add-item') : null} buttonIcon={hasAddPermission ? (<SvgIcon>
            {<AddIcon />}
          </SvgIcon>) : null} onButtonClick={onAdd} />
          <Grid container direction="column" marginTop={3} marginBottom={-3}>
            <Grid item>
              <FiltersCard onSearch={onSearch} onCancel={onCancel} children={
                <Grid item container direction="row" justifyContent="flex-start">
                  <Grid item width="30%" marginRight={4}>
                    <AutocompleteInput
                      onChange={setItem}
                      label={t('items.filter.item')}
                      resource="items/autocomplete"
                      value={item}
                      defaultValue={item}
                      refreshOnInputChange
                      clearOnBlur={false}
                      canDelete
                      freeSolo
                    />
                  </Grid>
                  <Grid item width="30%" marginRight={4}>
                    <AutocompleteInput
                      onChange={setParentItem}
                      label={t('items.filter.parent-item')}
                      resource="items/autocomplete"
                      value={parentItem}
                      defaultValue={parentItem}
                      refreshOnInputChange
                      clearOnBlur={false}
                      canDelete
                    />
                  </Grid>
                </Grid>
              } />
            </Grid>
          </Grid>
          {itemsTableData && hasRemovePermission &&
            <Grid container direction="row" marginTop={2} spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onDelete}
                  disabled={selectedItems.length < 1}
                >
                  {t('items.delete-item')}
                </Button>
              </Grid>
            </Grid>
          }
          <ContainerStyle>
            <BFHierarchyTable
              id={resource}
              data={itemsTableData}
              columns={itemsTableColumns}
              leaf={'children'}
              page={itemsTableState.page}
              sort={itemsTableState.sort}
              onChangePage={(currentPage) => {
                setItemsTableState({ ...itemsTableState, page: currentPage })
              }}
              onColumnSortChange={(changedColumn, direction) => {
                const newSort = {
                  field: changedColumn,
                  direction: direction.toUpperCase(),
                };
                setItemsTableState({ ...itemsTableState, sort: newSort });
              }}
              expandableRows
              selectableRows={hasRemovePermission}
              rowsSelected={selectedItems}
              onRowSelectionChange={setSelectedItems}
            />
          </ContainerStyle>
        </Container>
      </Page>
    </>
  );
};

/* Attention! This method uses recursion. */
// Method to find a object in data by there id.
export const findObjectById = (data, leaf, id) => {
  let object = null;
  if (data) {
    Object.values(data).forEach(item => {
      if (item.id === id) {
        object = item
      }

      if (item[leaf] && item[leaf].length > 0) {
        const found = findObjectById(item[leaf], leaf, id);
        if (found) {
          object = found
        }
      }
    });
  }
  return object;
};
