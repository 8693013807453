import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Button, MenuItem, Typography, useTheme } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
import { selectUser } from '../../pages/login/selectors';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { clearSession } from '../../session/actions';
import palette from '../../theme/palette';
import NavConfig from './NavConfig';
import LoggedCard from '../../components/logged-card';
import PopoverMenu from '../../components/logged-card/popover-menu';
import WorkspaceSelectorDialog from '../../components/workspace-selector-dialog';


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [anchor, setAnchor] = useState(null);
  const [openWorkspaceSelector, setOpenWorkspaceSelector] = useState(false);
  const isDesktop = useResponsive('up', 'lg');

  const user = useSelector(selectUser());

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <NavSection navConfig={NavConfig()} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <WorkspaceSelectorDialog
          title={t('sidebar.choose-workspace')}
          open={openWorkspaceSelector}
          onClose={(reload) => {
            setOpenWorkspaceSelector(false)
            if (reload) {
              window.location.reload();
            }
          }} />
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Button fullWidth onClick={(event) => setAnchor(event.target)}>
              <LoggedCard user={user} />
            </Button>
          </AccountStyle>
        </Link>
        <PopoverMenu
          anchor={anchor}
          setAnchor={setAnchor}
          children={(
            <>
              <AccountStyle style={{ backgroundColor: palette.background.neutral, padding: '8px 18px', margin: 15 }}>
                <LoggedCard user={user} />
              </AccountStyle>
              <MenuItem
                onClick={() => {
                  setAnchor(null)
                  setOpenWorkspaceSelector(true);
                }}
                style={{ padding: '5px 15px 10px 15px' }}
              >
                <Typography variant='button' style={{ color: theme.palette.primary.main }}>{t('sidebar.choose-workspace')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(clearSession());
                  localStorage.clear();
                }}
                style={{ padding: '5px 15px 10px 15px' }}
              >
                <Typography variant='button' style={{ color: theme.palette.error.main }}>{t('sidebar.logout')}</Typography>
              </MenuItem>
            </>
          )}
        />
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'secondary.main',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};