import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import { Container, Grid, TableRow, TableCell } from '@mui/material';
// components
import Page from '../../components/Page';
import PageHeader from '../../components/page-header';
import { selectPermissions } from './selectors';
import { getPermissions, editPermission, deletePermission } from './actions';
import generate from './columns';
import BFTable from '../../components/table';
import { permissionTree, translate } from '../../entities/permissions';
import { useNotificationsProvider } from '../../context/NotificationsContext';

const ContainerStyle = styled(Grid)(({ theme }) => ({
    shadowColor: theme.palette.secondary,
    backgroundColor: theme.palette.grey,
    marginTop: 25,
}));

const TableRowStyle = styled(TableRow)(({ theme, condition }) => ({
    backgroundColor: condition === "true" && theme.palette.background.neutral,
}));

export default function Permissions() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { showNotification } = useNotificationsProvider();

    const permissions = useSelector(selectPermissions());

    const [itemsColumns, setItemsColumns] = useState(null);
    const [itemsTableData, setItemsTableData] = useState(null);

    const permission = `permissions`;

    const updateRole = (rowIndex, role, value) => {
        const { key } = itemsTableData.items[rowIndex];

        const data = {
            role,
            permission: key,
        }

        if (value) {
            // Disable role
            dispatch(deletePermission(data, () => {
                dispatch(getPermissions());
            }, () => {
                showNotification('error', t('common.api-error'));
            }));
        } else {
            // Enable role
            dispatch(editPermission(data, () => {
                dispatch(getPermissions());
            }, () => {
                showNotification('error', t('common.api-error'));
            }));
        }
    };

    const itemsTableColumns = generate({
        t,
        itemsColumns,
        updateRole,
    });

    const tempPermissionsFunction = (array) => {
        const permissions = array.permissions || {};
        const temp = [];

        Object.keys(permissionTree).forEach(key => {
            temp.push({
                key,
                translation: translate(key, t),
                page: key.split(':')[0],
                func: key.split(':')[1],
                roles: permissions[key]
            });
            permissionTree[key].forEach((subKey) => {
                temp.push({
                    key: subKey,
                    translation: translate(subKey, t),
                    page: subKey.split(':')[0],
                    func: subKey.split(':')[1],
                    roles: permissions[subKey]
                })
            })
        });

        return { itemCount: temp.length, items: temp, range: { limite: 15 } };
    }

    /* TODO: REMOVE FIXED TREE FROM BACKOFFICE
    const permissionsObjArranged = (array) => {
        
    }
    */

    const customRow = (data, dataIndex, rowIndex) => {
        const isRoot = itemsTableData.items[rowIndex].func === 'root';
        return (
            <TableRowStyle
                key={rowIndex}
                condition={isRoot.toString()}
            >
                <TableCell key="translation" style={{ fontWeight: isRoot && 'bold' }}> {data[0]} </TableCell>
                {Object.keys(itemsColumns).map((item, index) => (
                    <TableCell key={item}> {data[index + 1]} </TableCell>
                ))}
            </TableRowStyle>
        );
    };

    useEffect(() => {
        dispatch(getPermissions());
    }, [dispatch]);

    const memoizedTempPermissionsFunction = useCallback(tempPermissionsFunction, [t]);

    useEffect(() => {
        if (permissions) {
            setItemsTableData(memoizedTempPermissionsFunction(permissions));
            setItemsColumns(permissions.roles);
        }
    }, [permissions, memoizedTempPermissionsFunction]);

    return (
        <>
            <Page title={t('permissions.tab-title')}>
                <Container>
                    <PageHeader title={t('permissions.page-title')} />
                    <ContainerStyle>
                        <BFTable
                            id={permission}
                            columns={itemsTableColumns}
                            data={itemsTableData}
                            customRowRender={customRow}
                        />
                    </ContainerStyle>
                </Container>
            </Page>
        </>
    );
};