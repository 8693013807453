import {
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILED,
  REQUEST_UPLOAD_ITEM_IMAGE_SUCCESS,
  REQUEST_UPLOAD_ITEM_IMAGE_FAILED,
  UPLOAD_ITEM_IMAGE_SUCCESS,
  UPLOAD_ITEM_IMAGE_FAILED
} from './types';

const initialState = {
  current: {
    items: null,
    requestUploadFormData: null
  }
};

export default function itemsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          items: action.payload,
        },
      };
    case GET_ITEMS_FAILED:
      return {
        ...state,
        error: action.payload
      };
    case REQUEST_UPLOAD_ITEM_IMAGE_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          requestUploadFormData: action.payload,
        },
      };
    case REQUEST_UPLOAD_ITEM_IMAGE_FAILED:
      return {
        ...state,
        error: action.payload
      };
    case UPLOAD_ITEM_IMAGE_SUCCESS:
      return {
        ...state
      };
    case UPLOAD_ITEM_IMAGE_FAILED:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}