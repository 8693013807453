export const GET_ITEMS = 'GET_ITEMS';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILED = 'GET_ITEMS_FAILED';
export const ADD_ITEM = 'ADD_ITEM';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAILED = 'ADD_ITEM_FAILED';
export const EDIT_ITEM = 'EDIT_ITEM';
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export const EDIT_ITEM_FAILED = 'EDIT_ITEM_FAILED';
export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILED = 'DELETE_ITEM_FAILED';
export const REQUEST_UPLOAD_ITEM_IMAGE = 'REQUEST_UPLOAD_ITEM_IMAGE';
export const REQUEST_UPLOAD_ITEM_IMAGE_SUCCESS = 'REQUEST_UPLOAD_ITEM_IMAGE_SUCCESS';
export const REQUEST_UPLOAD_ITEM_IMAGE_FAILED = 'REQUEST_UPLOAD_ITEM_IMAGE_FAILED';
export const UPLOAD_ITEM_IMAGE = 'UPLOAD_ITEM_IMAGE';
export const UPLOAD_ITEM_IMAGE_SUCCESS = 'UPLOAD_ITEM_IMAGE_SUCCESS';
export const UPLOAD_ITEM_IMAGE_FAILED = 'UPLOAD_ITEM_IMAGE_FAILED';