export function selectSession() {
  return (state) => state.session.current;
}

export function selectUserError() {
  return (state) => state.user.error;
}

export function selectUser() {
  return (state) => state.user.current?.user ? state.user.current?.user : JSON.parse(localStorage.getItem('session_current'))?.user;
}

export function selectWorkspaces() {
  return (state) => state.user.current?.workspaces;
}

export function hasPermission(key) {
  return (state) => state.user.current?.user ? state.user.current?.user?.permissions?.includes(key) : JSON.parse(localStorage.getItem('session_current'))?.user?.permissions?.includes(key);
}