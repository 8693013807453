import PropTypes from 'prop-types';
import React from 'react'
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CloseIcon } from '../../assets/ic_close.svg'

const ActionsContainerCenter = {
  marginTop: 2,
  justifyContent: 'center',
};

const ActionsContainerEnd = {
  marginTop: 2,
};

const TitleContainer = {
  borderBottom: '2px solid #e3e3ec',
  paddingLeft: 1,
};

BaseDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
  hideCloseButton: PropTypes.bool,
  loading: PropTypes.bool,
  openButtonTitle: PropTypes.string,
  closeButtonTitle: PropTypes.string,
  hideActions: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
  classes: PropTypes.any,
  width: PropTypes.string,
};

export default function BaseDialog({
  open,
  onClose,
  onConfirm,
  title,
  children,
  hideCloseButton,
  loading,
  openButtonTitle,
  closeButtonTitle,
  hideActions,
  confirmDisabled = false,
  classes: overrideClasses,
  width = 'sm',
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const openButtonTitleTranslation = openButtonTitle || t('common.confirm')
  const closeButtonTitleTranslation = closeButtonTitle || t('common.cancel')

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} classes={overrideClasses} maxWidth={width} fullWidth={!!width}>
      {!hideCloseButton && (
        <DialogTitle style={TitleContainer}>
          <Grid container alignItems="center" justify="flex-start">

            <Grid item marginLeft={1}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item marginLeft={2}>
              <Typography variant="h6">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
      )}

      <DialogContent classes={overrideClasses}>
        {loading
          ? (
            <Grid container justify="space-around" justifyContent="center" marginTop={2}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )
          : (
            <>
              {children}
              {!hideActions && (
                <DialogActions className={hideCloseButton
                  ? ActionsContainerCenter
                  : ActionsContainerEnd}
                >
                  <Button
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                  >
                    {closeButtonTitleTranslation}
                  </Button>
                  <Button
                    onClick={onConfirm}
                    color="primary"
                    variant="contained"
                    disabled={confirmDisabled}
                  >
                    {openButtonTitleTranslation}
                  </Button>
                </DialogActions>
              )}
            </>
          )}

      </DialogContent>
    </Dialog>
  )
}
