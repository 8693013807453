import {
  GET_RESOURCES,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILED,
  ADD_RESOURCE,
  ADD_RESOURCE_SUCCESS,
  ADD_RESOURCE_FAILED,
  EDIT_RESOURCE,
  EDIT_RESOURCE_SUCCESS,
  EDIT_RESOURCE_FAILED,
  DELETE_RESOURCE,
  DELETE_RESOURCE_SUCCESS,
  DELETE_RESOURCE_FAILED
} from './types';
import { apiAction } from '../../api/actions';

export function getResources(params) {
  return apiAction({
    resource: '/su/resource',
    params,
    method: 'GET',
    onSuccess: (data) => onGetSuccess(data),
    onFailure: (error) => onGetFailed(error),
    carry: {
      label: GET_RESOURCES
    },
  });
}

function onGetSuccess(data) {
  return {
    type: GET_RESOURCES_SUCCESS,
    payload: data
  };
}

function onGetFailed(error) {
  return {
    type: GET_RESOURCES_FAILED,
    payload: {
      error
    }
  };
}

export function addResource(data, onSuccess, onFailure) {
  return apiAction({
    resource: '/su/resource',
    method: 'POST',
    data,
    onSuccess: (data) => onAddSuccess(data, onSuccess),
    onFailure: (error) => onAddFailed(error, onFailure),
    carry: {
      label: ADD_RESOURCE
    },
  });
}

function onAddSuccess(data, onSuccess) {
  onSuccess()
  return {
    type: ADD_RESOURCE_SUCCESS,
    payload: data
  };
}

function onAddFailed(error, onFailure) {
  onFailure()
  return {
    type: ADD_RESOURCE_FAILED,
    payload: {
      error
    }
  };
}

export function editResource(id, data, onSuccess, onFailure) {
  return apiAction({
    resource: `/su/resource/${id}/edit`,
    method: 'PUT',
    data,
    onSuccess: (data) => onEditSuccess(data, onSuccess),
    onFailure: (error) => onEditFailed(error, onFailure),
    carry: {
      label: EDIT_RESOURCE
    },
  });
}

function onEditSuccess(data, onSuccess) {
  onSuccess()
  return {
    type: EDIT_RESOURCE_SUCCESS,
    payload: data
  };
}

function onEditFailed(error, onFailure) {
  onFailure()
  return {
    type: EDIT_RESOURCE_FAILED,
    payload: {
      error
    }
  };
}

export function deleteResource(id, onSuccess, onFailure) {
  return apiAction({
    resource: `/su/resource/${id}/delete`,
    method: 'POST',
    data: {},
    onSuccess: (data) => onDeleteSuccess(data, onSuccess),
    onFailure: (error) => onDeleteFailed(error, onFailure),
    carry: {
      label: DELETE_RESOURCE
    },
  });
}

function onDeleteSuccess(data, onSuccess) {
  onSuccess()
  return {
    type: DELETE_RESOURCE_SUCCESS,
    payload: data
  };
}

function onDeleteFailed(error, onFailure) {
  onFailure()
  return {
    type: DELETE_RESOURCE_FAILED,
    payload: {
      error
    }
  };
}