import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Card, CardContent, Grid, Typography } from "@mui/material";

const ContainerStyle = styled(Grid)(() => ({
    margin: '5px 0',
}));

const Label = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginRight: 5,
}));

const Validation = styled(Typography)(({ theme, state }) => ({
    color: state === '1' ? theme.palette.success.main : theme.palette.error.main,
}));

InfoCard.propTypes = {
    t: PropTypes.func,
    data: PropTypes.object,
}

export default function InfoCard({ t, data }) {
    return (
        <Card>
            <CardContent>
                <Grid container spacing={5}>
                    <Grid item>
                        <ContainerStyle container direction="row">
                            <Label>
                                {`${t('inspections.items.date')}:`}
                            </Label>
                            <Typography>
                                {data?.controlDate}
                            </Typography>
                        </ContainerStyle>
                        <ContainerStyle container direction="row">
                            <Label>
                                {`${t('inspections.info-card.employee')}:`}
                            </Label>
                            <Typography>
                                {data?.employee}
                            </Typography>
                        </ContainerStyle>
                        <ContainerStyle container direction="row">
                            <Label>
                                {`${t('inspections.info-card.state')}:`}
                            </Label>
                            <Validation state={data?.conformity.id.toString()}>
                                {data?.conformity.id === 1 ? t('inspections.info-card.complaint') : t('inspections.info-card.non-complaint')}
                            </Validation>
                        </ContainerStyle>
                    </Grid>
                    <Grid item>
                        <ContainerStyle container direction="row">
                            <Label>
                                {`${t('inspections.items.resource')}:`}
                            </Label>
                            <Typography>
                                {data?.resource.name}
                            </Typography>
                        </ContainerStyle>
                        <ContainerStyle container direction="row">
                            <Label>
                                {`${t('inspections.items.resource-type')}:`}
                            </Label>
                            <Typography>
                                {data?.resource.resourceType.name}
                            </Typography>
                        </ContainerStyle>
                        <ContainerStyle container direction="row">
                            <Label>
                                {`${t('inspections.items.flow-type')}:`}
                            </Label>
                            <Typography>
                                {data?.flow.name}
                            </Typography>
                        </ContainerStyle>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
