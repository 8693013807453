import {
  LOAD_PERMISSIONS,
  SET_PERMISSIONS,
  FETCH_PERMISSIONS,
  RECEIVED_PERMISSIONS,
  ERROR_FETCH_PERMISSIONS
} from './types';
import { apiAction } from '../api/actions';

export function loadPermissions(session) {
  return {
    type: LOAD_PERMISSIONS,
    payload: session
  };
}

export function fetchPermissions() {
  return apiAction({
    resource: '/su/permissions',
    onSuccess: (data) => broadcastReceivedPermissions(data),
    onFailure: onFetchPermissionsError,
    carry: {
      label: FETCH_PERMISSIONS
    }
  });
}

function broadcastReceivedPermissions(data) {
  return {
    type: RECEIVED_PERMISSIONS,
    payload: {
      permissions: data
    }
  };
}

function onFetchPermissionsError(error) {
  return {
    type: ERROR_FETCH_PERMISSIONS,
    error
  };
}

export function setEnabledPermissions(permissionIds) {
  return {
    type: SET_PERMISSIONS,
    payload: {
      data: permissionIds || []
    }
  };
}
