import React from 'react';
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Button, Grid, Typography } from '@mui/material';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
// component
import BaseDialog from '../base-dialog';
import { ReactComponent as PhotoPlaceholder } from '../../assets/ic_photo_placeholder.svg';

const ButtonContainer = styled(Grid)(() => ({
    marginTop: 20,
}));

const SlideStyle = styled(Slide)(() => ({
    'div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    'video': {
        maxWidth: 500,
        minWidth: 500
    },
    'img': {
        maxWidth: 500,
        minWidth: 500
    }
}));

const PhotoPlaceholderStyle = styled(PhotoPlaceholder)(({ theme }) => ({
    margin: 20,
    width: 250,
    height: 250,
    'path': {
        fill: theme.palette.grey[600],
    }
}));

MediaCarouselDialog.propTypes = {
    files: PropTypes.array,
    titles: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

// TODO - IMPLEMENT AND TEST VIDEO FILES

// Documentation for the 'pure-react-carousel' material can be viewed on this
// link: https://www.npmjs.com/package/pure-react-carousel?activeTab=readme
export default function MediaCarouselDialog({ files, titles, open, onClose }) {
    return (
        <BaseDialog
            title={titles.title}
            open={open}
            onClose={onClose}
            hideActions
        >
            <CarouselProvider
                naturalSlideWidth={150}
                naturalSlideHeight={150}
                totalSlides={files?.length}
                infinite
            >
                <Slider>
                    {files.map((media, index) => (
                        <SlideStyle key={index} index={index}>
                            {getMediaType(media, titles.invalid)}
                        </SlideStyle>
                    ))}
                </Slider>
                <ButtonContainer container justifyContent="space-between">
                    <ButtonBack style={{ border: 0, borderRadius: 10 }}>
                        <Button variant="contained">
                            {titles.back}
                        </Button>
                    </ButtonBack>
                    <ButtonNext style={{ border: 0, borderRadius: 10 }}>
                        <Button variant="contained">
                            {titles.next}
                        </Button>
                    </ButtonNext>
                </ButtonContainer>
            </CarouselProvider>
        </BaseDialog>
    );
};

// This method receives a url (of type file) and 
// determines what type of media the url is (audio, 
// video, image), returning the correct component 
// for display. 
// The object 'type' contains 3 different keys corresponding 
// to the type of file we are expecting to receive. Each key 
// contains 10 types of extensions that we can receive from 
// each category of file. The 10 in each category were taken 
// from ChatGPT's answer to the question "10 extensions more
// used by (...)" them the respective category.
export function getMediaType(url, message) {
    const types = {
        video: ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', 'mpeg', 'webm', '3gp', 'ogv'],
        audio: ['mp3', 'wav', 'ogg', 'aac', 'flac', 'wma', 'm4a', 'mp2', 'ac3', 'ra'],
        imagem: ['jpg', 'jpeg', 'png', 'eps', 'bmp', 'tiff', 'webp', 'raw', 'psd', 'ai']
    }

    if (typeof url === 'string' && url !== null) {
        const extension = url.split('.').pop().toLowerCase();

        if (types.video.includes(extension)) {
            return (
                <video controls>
                    <source src={url} type={`video/${extension}`} />
                    <track kind="captions" />
                </video>
            );
        }

        if (types.audio.includes(extension)) {
            return (
                <audio controls>
                    <source src={url} type={`audio/${extension}`} />
                    <track kind="captions" />
                </audio>
            );
        }

        // Default to images. S3 files do not have extensions in the filenames
        return <img src={url} alt="img" style={{ minWidth: 250, maxWidth: 550 }} />
    }
    return <InvalidAttachment message={message} />;
};

InvalidAttachment.propTypes = {
    message: PropTypes.string,
}

function InvalidAttachment({ message }) {
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <PhotoPlaceholderStyle />
            </Grid>
            <Grid item>
                <Typography>{message}</Typography>
            </Grid>
        </Grid>
    );
};