import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// material
import { Card, CardContent, Grid, Typography, Button } from '@mui/material';

FiltersCard.propTypes = {
  onSearch: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node,
};

export default function FiltersCard({ onSearch, onCancel, children }) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="subtitle1">{t('inspections.filter.title')}</Typography>
          </Grid>
          {children}
          <Grid item container direction="row" justifyContent="flex-end">
            <Grid item container direction="row" justifyContent="space-evenly" width="30%" marginTop="16px">
              <Grid item paddingLeft={6}>
                <Button
                  variant="outlined"
                  onClick={() => onCancel()}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained"
                  onClick={() => onSearch()}>
                  {t('components.table.toolbar.search')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
