import React from 'react';
import { ReactComponent as ResourceType } from '../../assets/ic_resource_type.svg';
import '../../theme/overrides/ative.css';
import '../../theme/overrides/inative.css';

const ResourceTypeIcon = (ative) => (
  <ResourceType className={ative ? 'ative' : 'inative'}/>
);

export default ResourceTypeIcon;
