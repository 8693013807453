import { GET_DASHBOARD_STATISTICS_SUCCESS, GET_DASHBOARD_STATISTICS_FAILED } from './types';

const initialState = {
  current: {}
};

export default function flowsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DASHBOARD_STATISTICS_SUCCESS:
      return {
        ...state,
        current: action.payload,
      };
    case GET_DASHBOARD_STATISTICS_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      break;
  }
  return state
};