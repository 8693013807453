import {
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,
} from './types';

const initialState = {
    current: null
};

export default function usersReducer(action = {}, state = initialState) {
    switch (action.type) {
        case GET_USERS_SUCCESS:
            return {
                ...initialState,
                current: action.payload,
            };
        case GET_USERS_FAILED:
            return {
                ...initialState,
                error: action.payload,
            };
        default:
            break;
    }
    return state;
};