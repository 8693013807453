import PropTypes from 'prop-types';
import { Popover } from "@mui/material";

PopoverMenu.propTypes = {
    anchor: PropTypes.any,
    setAnchor: PropTypes.func,
    children: PropTypes.any
};

export default function PopoverMenu({ anchor, setAnchor, children }) {
    return (
        <Popover
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={() => setAnchor(null)}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            style={{ marginLeft: 15 }}
        >
            {children}
        </Popover>
    );
};