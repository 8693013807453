import {
  AUTOCOMPLETE_SUCCESS,
  AUTOCOMPLETE_FAILED,
} from './types';

const initialState = {
  current: {}, // Object to hold results by resource name
  error: null, // Error specific to the last failed action
};

export default function autocompleteReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          [action.resource]: action.payload, // Use resource name as key
        },
        error: null, // Clear error on successful fetch
      };

    case AUTOCOMPLETE_FAILED:
      return {
        ...state,
        error: {
          resource: action.resource,
          message: action.payload,
        },
      };

    default:
      return state;
  }
}