import { GENERATE_INSPECTION_PDF_SUCCESS, GENERATE_INSPECTION_PDF_FAILED } from "./types";

const initialState = {
  current: {
    inspectionReport: null
  }
};

export default function inspectionsReducer(action = {}, state = initialState) {
  switch (action.type) {
    case GENERATE_INSPECTION_PDF_SUCCESS:
      return {
        ...state,
        current: {
          inspectionReport: action.payload
        },
      };
    case GENERATE_INSPECTION_PDF_FAILED:
      return {
        ...state,
        current: {
          error: action.payload
        }
      };
    default:
      break;
  }
  return state;
};