import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, SvgIcon, Typography, IconButton, Stack } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/ic_edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/ic_delete.svg';

const CardStyle = styled('div')(({ theme }) => ({
  display: 'inline-block',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[100], 1),
}));

InfoCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default function InfoCard({ title, value, icon, onEdit, onDelete }) {
  return (
    <CardStyle>
      <Box>
        {
          (onEdit || onDelete) && (
            <Stack direction="row" justifyContent="flex-end">
              {
                onEdit && (
                  <IconButton onClick={onEdit}>
                    <EditIcon />
                  </IconButton>
                )
              }
              {
                onDelete && (
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                )
              }
            </Stack>
          )
        }
        {
          (icon) && (
            <Box>
              <SvgIcon>
                {icon}
              </SvgIcon>
            </Box>
          )
        }
        <Box>
          <Typography variant="subtitle1">
            {title}
          </Typography>
        </Box>
        {
          (value) && (
            <Box>
              <Typography variant="h3">
                {value}
              </Typography>
            </Box>
          )
        }
      </Box>
    </CardStyle>
  );
}