import { GET_FLOW_TYPES_SUCCESS, GET_FLOW_TYPES_FAILED } from './types';

const initialState = {
    current: {}
};

export default function flowsReducer(action = {}, state = initialState) {
    switch (action.type) {
        case GET_FLOW_TYPES_SUCCESS:
            return {
                ...state,
                current: action.payload,
            };
        case GET_FLOW_TYPES_FAILED:
            return {
                ...state,
                error: action.payload,
            };
        default:
            break;
    }
    return state
};