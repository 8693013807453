import * as TYPE from './types';
import { apiAction } from '../../api/actions';

export function getInspections(params) {
    return apiAction({
        resource: '/su/control',
        params,
        method: 'GET',
        onSuccess: (data) => onGetSuccess(data),
        onFailure: (error) => onGetFailed(error),
        carry: {
            label: TYPE.GET_INSPECTIONS
        }
    });
};

function onGetSuccess(data) {
    return {
        type: TYPE.GET_INSPECTIONS_SUCCESS,
        payload: data,
    };
};

function onGetFailed(error) {
    return {
        type: TYPE.GET_INSPECTIONS_FAILED,
        payload: {
            error
        }
    };
};

export function getInspectionItems(controlId, params) {
    return apiAction({
        resource: `/su/control/${controlId}/items`,
        params,
        method: 'GET',
        onSuccess: (data) => onGetInspectionItemsSuccess(data),
        onFailure: (error) => onGetInspectionItemsFailed(error),
        carry: {
            label: TYPE.GET_INSPECTION_ITEMS
        }
    });
};

function onGetInspectionItemsSuccess(data) {
    return {
        type: TYPE.GET_INSPECTION_ITEMS_SUCCESS,
        payload: data,
    };
};

function onGetInspectionItemsFailed(error) {
    return {
        type: TYPE.GET_INSPECTION_ITEMS_FAILED,
        payload: {
            error
        }
    };
};

export function deleteInspection(id, onSuccess, onFailure) {
    return apiAction({
        resource: `/su/control/${id}/delete`,
        method: 'POST',
        data: {},
        onSuccess: (data) => onDeleteSuccess(data, onSuccess),
        onFailure: (error) => onDeleteFailed(error, onFailure),
        carry: {
            label: TYPE.DELETE_INSPECTION
        },
    });
}

function onDeleteSuccess(data, onSuccess) {
    onSuccess()
    return {
        type: TYPE.DELETE_INSPECTION_SUCCESS,
        payload: data
    };
}

function onDeleteFailed(error, onFailure) {
    onFailure()
    return {
        type: TYPE.DELETE_INSPECTION_FAILED,
        payload: {
            error
        }
    };
}