import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// material
import { Grid, TextField, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel } from '@mui/material';
// component
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import BaseDialog from '../base-dialog';
import { DynamicFieldTypes } from '../../entities/dynamic-field-type';
import 'dayjs/locale/pt';
import 'dayjs/locale/fr';
import 'dayjs/locale/en'; // Default locale for fallback

DynamicFieldSelectorDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  value: PropTypes.object,
  titleOnly: PropTypes.bool,
};

export default function DynamicFieldSelectorDialog({ title, open, onClose, onCreated, value, titleOnly = false }) {
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fieldTitle, setFieldTitle] = useState('');
  const [dataType, setDataType] = useState('');
  const [dataTypeTextFieldInput, setDataTypeTextFieldInput] = useState('standard');
  const [fieldValue, setFieldValue] = useState('');

  const resetState = () => {
    setError(null);
    setLoading(false);
    setFieldTitle('');
    setDataType('');
    setDataTypeTextFieldInput('text');
    setFieldValue('');
  };

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose();
    } else {
      resetState();
      onClose();
    }
  };

  const onSelectDataType = (event) => {
    setDataType(event.target.value);

    if (event.target.value === DynamicFieldTypes.TEXT) {
      setDataTypeTextFieldInput('text');
    } else if (event.target.value === DynamicFieldTypes.NUMBER) {
      setDataTypeTextFieldInput('number');
    }
  };

  const onConfirm = () => {
    if (fieldTitle.length === 0) {
      setError('title_required');
      return;
    }

    if (dataType === '' || dataType === 'none') {
      setError('dataType_required');
      return;
    }

    if (fieldValue.length === 0 && !titleOnly) {
      setError('fieldValue_required');
      return;
    }

    const data = {
      title: fieldTitle,
      dataType
    };

    if (!titleOnly) {
      data.value = fieldValue;
    }

    onCreated(data);
    resetState();
  };

  // Define the format based on the locale
  const getDateFormat = (locale) => {
    switch (locale) {
      case 'en':
        dayjs.locale('en');
        return 'MM/DD/YYYY';
      case 'fr':
        dayjs.locale('fr');
        return 'DD/MM/YYYY';
      default:
        dayjs.locale('pt'); // Default locale
        return 'DD/MM/YYYY';
    }
  };

  const locale = (navigator.language || navigator.userLanguage).substring(0, 2);
  const dateFormat = getDateFormat(locale);

  useEffect(() => {
    console.log('value', value);
    if (value) {
      setFieldTitle(value.title);
      setDataType(value.dataType);
      if (!titleOnly) {
        setFieldValue(value.value);
      }
    }
  }, [titleOnly, value]);

  return (
    <BaseDialog open={open} onClose={onCloseDialog} onConfirm={onConfirm} title={title} loading={loading}>
      <Grid container direction="column" spacing={4} marginBottom={4}>
        <Grid item marginTop={2}>
          <TextField
            error={error === 'title_required'}
            variant="standard"
            value={fieldTitle}
            onChange={(event) => {
              setFieldTitle(event.target.value);
            }}
            label={t('components.dynamic-field-selector.field-title')}
            required
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              {t('components.dynamic-field-selector.field-data-type.title')}
            </InputLabel>
            <Select
              id="data-type"
              labelId="data-type"
              label={t('components.dynamic-field-selector.field-data-type.title')}
              onChange={onSelectDataType}
              value={dataType}
              error={error === 'dataType_required'}
            >
              <MenuItem value={DynamicFieldTypes.TEXT}>
                {t('components.dynamic-field-selector.field-data-type.text')}
              </MenuItem>
              <MenuItem value={DynamicFieldTypes.NUMBER}>
                {t('components.dynamic-field-selector.field-data-type.number')}
              </MenuItem>
              <MenuItem value={DynamicFieldTypes.DATE}>
                {t('components.dynamic-field-selector.field-data-type.date')}
              </MenuItem>
              <MenuItem value={DynamicFieldTypes.BOOLEAN}>
                {t('components.dynamic-field-selector.field-data-type.boolean')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {!titleOnly ? (
          <Grid item marginTop={2}>
            {
              // eslint-disable-next-line no-nested-ternary
              dataType === DynamicFieldTypes.BOOLEAN ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={fieldValue}
                      onChange={(event) => {
                        setFieldValue(event.target.checked);
                      }}
                      required
                    />
                  }
                  label={t('components.dynamic-field-selector.field-boolean-label')}
                />
              ) : dataType === DynamicFieldTypes.DATE ? (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                  <DatePicker
                    label={t('components.dynamic-field-selector.field-date-label')}
                    value={dayjs(fieldValue)}
                    onChange={(val) => setFieldValue(val)}
                    dateFormat={dateFormat}
                  />
                </LocalizationProvider>
              ) : (
                <TextField
                  error={error === 'fieldValue_required'}
                  value={fieldValue}
                  variant="standard"
                  onChange={(event) => {
                    setFieldValue(event.target.value);
                  }}
                  label={t('components.dynamic-field-selector.field-value')}
                  inputProps={{ type: dataTypeTextFieldInput }}
                  required
                  fullWidth
                />
              )
            }
          </Grid>
        ) : null}
      </Grid>
    </BaseDialog>
  );
}
