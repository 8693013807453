import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography, Button } from '@mui/material';

const ButtonStyle = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  justifyContent: 'space-between',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1, 3),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

PageHeader.propTypes = {
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonIcon: PropTypes.any,
  onButtonClick: PropTypes.func,
};

export default function PageHeader({ title, buttonTitle, buttonIcon, onButtonClick }) {
  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Typography variant="h3">
          {title}
        </Typography>
        {(buttonTitle || buttonIcon) && (
          <ButtonStyle
            startIcon={buttonIcon}
            onClick={onButtonClick}>
            {buttonTitle}
          </ButtonStyle>
        )}
      </Stack>
    </Box>
  );
}