export const GET_FLOWS = 'GET_FLOWS';
export const GET_FLOWS_SUCCESS = 'GET_FLOWS_SUCCESS';
export const GET_FLOWS_FAILED = 'GET_FLOWS_FAILED';
export const ADD_FLOWS = 'ADD_FLOWS';
export const ADD_FLOWS_SUCCESS = 'ADD_FLOWS_SUCCESS';
export const ADD_FLOWS_FAILED = 'ADD_FLOWS_FAILED';
export const EDIT_FLOWS = 'EDIT_FLOWS';
export const EDIT_FLOWS_SUCCESS = 'EDIT_FLOWS_SUCCESS';
export const EDIT_FLOW_FAILED = 'EDIT_FLOWS_FAILED';
export const DELETE_FLOW = 'DELETE_FLOW';
export const DELETE_FLOW_SUCCESS = 'DELETE_FLOW_SUCCESS';
export const DELETE_FLOW_FAILED = 'DELETE_FLOW_FAILED';
export const MANAGE_ITEMS_FLOW = 'MANAGE_ITEMS_FLOW';
export const MANAGE_ITEMS_FLOW_SUCCESS = 'MANAGE_ITEMS_FLOW_SUCCESS';
export const MANAGE_ITEMS_FLOW_FAILED = 'MANAGE_ITEMS_FLOW_FAILED';
