import {
  LOAD_FEATURES,
  SET_FEATURES,
  FETCH_FEATURES,
  RECEIVED_FEATURES,
  ERROR_FETCH_FEATURES
} from './types';
import { apiAction } from '../../api/actions';
import { FeaturesMap } from '../../entities/features';

export function loadFeatures() {
  return {
    type: LOAD_FEATURES,
    payload: FeaturesMap
  };
}

export function fetchFeatures() {
  return apiAction({
    resource: '/su/features',
    onSuccess: (data) => broadcastReceivedFeatures(data),
    onFailure: onFetchFeaturesError,
    carry: {
      label: FETCH_FEATURES
    }
  });
}

function broadcastReceivedFeatures(data) {
  return {
    type: RECEIVED_FEATURES,
    payload: data
  };
}

function onFetchFeaturesError(error) {
  return {
    type: ERROR_FETCH_FEATURES,
    error
  };
}

export function setEnabledFeatures(features) {
  return {
    type: SET_FEATURES,
    payload: {
      data: features || FeaturesMap
    }
  };
}
