import {
  GET_RESOURCE_TYPES,
  GET_RESOURCE_TYPES_SUCCESS,
  GET_RESOURCE_TYPES_FAILED,
  ADD_RESOURCE_TYPE,
  ADD_RESOURCE_TYPE_SUCCESS,
  ADD_RESOURCE_TYPE_FAILED,
  EDIT_RESOURCE_TYPE,
  EDIT_RESOURCE_TYPE_SUCCESS,
  EDIT_RESOURCE_TYPE_FAILED,
  DEACTIVATE_RESOURCE_TYPE,
  DEACTIVATE_RESOURCE_TYPE_SUCCESS,
  DEACTIVATE_RESOURCE_TYPE_FAILED
} from './types';
import { apiAction } from '../../api/actions';

export function getResourceTypes(params) {
  return apiAction({
    resource: '/su/resource-type',
    params,
    method: 'GET',
    onSuccess: (data) => onGetSuccess(data),
    onFailure: (error) => onGetFailed(error),
    carry: {
      label: GET_RESOURCE_TYPES
    },
  });
}

function onGetSuccess(data) {
  return {
    type: GET_RESOURCE_TYPES_SUCCESS,
    payload: data
  };
}

function onGetFailed(error) {
  return {
    type: GET_RESOURCE_TYPES_FAILED,
    payload: {
      error
    }
  };
}

export function addResourceType(data, onSuccess, onFailure) {
  return apiAction({
    resource: '/su/resource-type',
    method: 'POST',
    data,
    onSuccess: (data) => onAddSuccess(data, onSuccess),
    onFailure: (error) => onAddFailed(error, onFailure),
    carry: {
      label: ADD_RESOURCE_TYPE
    },
  });
}

function onAddSuccess(data, onSuccess) {
  onSuccess()
  return {
    type: ADD_RESOURCE_TYPE_SUCCESS,
    payload: data
  };
}

function onAddFailed(error, onFailure) {
  onFailure()
  return {
    type: ADD_RESOURCE_TYPE_FAILED,
    payload: {
      error
    }
  };
}

export function editResourceType(id, data, onSuccess, onFailure) {
  return apiAction({
    resource: `/su/resource-type/${id}/edit`,
    method: 'PUT',
    data,
    onSuccess: (data) => onEditSuccess(data, onSuccess),
    onFailure: (error) => onEditFailed(error, onFailure),
    carry: {
      label: EDIT_RESOURCE_TYPE
    },
  });
}

function onEditSuccess(data, onSuccess) {
  onSuccess()
  return {
    type: EDIT_RESOURCE_TYPE_SUCCESS,
    payload: data
  };
}

function onEditFailed(error, onFailure) {
  onFailure()
  return {
    type: EDIT_RESOURCE_TYPE_FAILED,
    payload: {
      error
    }
  };
}

export function deactivateResourceType(id, onSuccess, onFailure) {
  return apiAction({
    resource: `/su/resource-type/${id}/deactivate`,
    method: 'POST',
    data: {},
    onSuccess: (data) => onDeleteSuccess(data, onSuccess),
    onFailure: (error) => onDeleteFailed(error, onFailure),
    carry: {
      label: DEACTIVATE_RESOURCE_TYPE
    },
  });
}

function onDeleteSuccess(data, onSuccess) {
  onSuccess()
  return {
    type: DEACTIVATE_RESOURCE_TYPE_SUCCESS,
    payload: data
  };
}

function onDeleteFailed(error, onFailure) {
  onFailure()
  return {
    type: DEACTIVATE_RESOURCE_TYPE_FAILED,
    payload: {
      error
    }
  };
}