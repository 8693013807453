import axios from 'axios';
import {
  SET_SESSION,
  CLEAR_SESSION,
  REFRESH_SESSION,
  REFRESH_SESSION_FAILED,
  REFRESH_SESSION_SUCCESS,
  RETRY_ACTION,
} from './types';
import { apiAction, accessDenied, apiError, apiStart, apiEnd } from '../api/actions';

export function setSession(session) {
  return {
    type: SET_SESSION,
    payload: session,
  };
}

export function clearSession() {
  return {
    type: CLEAR_SESSION,
    payload: null,
  };
}

export function refreshSession(dispatch, refreshToken, action) {
  return apiAction({
    resource: '/su/auth/refresh',
    method: 'POST',
    data: {
      refreshToken,
    },
    onSuccess: (data) => onSuccessRefresh(dispatch, data, action),
    onFailure: (error) => onFailedRefresh(dispatch, error),
    carry: {
      label: REFRESH_SESSION,
    },
  });
}

function onSuccessRefresh(dispatch, session, action) {
  dispatch(setSession(session));
  dispatch(retryAction(dispatch, session, action));
  return {
    type: REFRESH_SESSION_SUCCESS,
    payload: session,
  };
}

function onFailedRefresh(dispatch, error) {
  dispatch(clearSession());
  return {
    type: REFRESH_SESSION_FAILED,
    payload: error,
  };
}

function retryAction(dispatch, session, action) {
  const { url, method, data, onSuccess, onFailure, carry, headers } = action.payload;
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  if (session) {
    axios.defaults.headers.common.Authorization = `Bearer ${session.token}`;
  }

  if (carry) {
    dispatch(apiStart(carry));
  }

  let requestHeaders = null;
  if (headers == null) {
    requestHeaders = axios.defaults.headers.common;
  }

  axios
    .request({
      url,
      method,
      requestHeaders,
      [dataOrParams]: data,
    })
    .then(({ data }) => {
      dispatch(onSuccess(data));
    })
    .catch((error) => {
      dispatch(apiError(error));
      dispatch(onFailure(error));

      if (error.response && error.response.status === 403) {
        dispatch(accessDenied(window.location.pathname));
      }
    })
    .finally(() => {
      if (carry) {
        dispatch(apiEnd(carry));
      }
    });

  return {
    type: RETRY_ACTION,
    payload: action.payload,
  };
}
