import * as TYPE from './types';

const initialState = {
    current: {}
};

export default function permissionsReducerPage(action = {}, state = initialState) {
    switch (action.type) {
        case TYPE.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                current: action.payload,
            };
        case TYPE.GET_PERMISSIONS_FAILED:
            return {
                ...state,
                error: action.payload,
            };
        default:
            break;
    }
    return state;
}