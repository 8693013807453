/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// material
import { Grid, TextField, Checkbox, FormControlLabel, Button, Typography } from '@mui/material';
// component
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import 'dayjs/locale/fr';
import 'dayjs/locale/en'; // Default locale for fallback
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DynamicFieldTypes } from '../../entities/dynamic-field-type';
import { ReactComponent as EditIcon } from '../../assets/ic_edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/ic_delete.svg';

DynamicFieldValueComponent.propTypes = {
  value: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  titleOnly: PropTypes.bool,
};

export default function DynamicFieldValueComponent({ value, onEdit, onDelete, titleOnly = false }) {
  const { t } = useTranslation();

  const [fieldTitle] = useState(value && value.title);
  const [dataType] = useState(value && value.dataType);
  const [fieldValue] = useState(value && value.value);

  function translationForDataType() {
    switch (dataType) {
      case DynamicFieldTypes.BOOLEAN:
        return t('components.dynamic-field-selector.field-data-type.boolean');
      case DynamicFieldTypes.DATE:
        return t('components.dynamic-field-selector.field-data-type.date');
      case DynamicFieldTypes.NUMBER:
        return t('components.dynamic-field-selector.field-data-type.number');
      case DynamicFieldTypes.TEXT:
        return t('components.dynamic-field-selector.field-data-type.text');
      default:
        return '';
    }
  }

  // Define the format based on the locale
  const getDateFormat = (locale) => {
    switch (locale) {
      case 'en':
        dayjs.locale('en');
        return 'MM/DD/YYYY';
      case 'fr':
        dayjs.locale('fr');
        return 'DD/MM/YYYY';
      default:
        dayjs.locale('pt'); // Default locale
        return 'DD/MM/YYYY';
    }
  };

  const locale = (navigator.language || navigator.userLanguage).substring(0, 2);
  const dateFormat = getDateFormat(locale);

  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        {!titleOnly ? (
          dataType === DynamicFieldTypes.BOOLEAN ? (
            <FormControlLabel control={<Checkbox checked={fieldValue} required />} label={fieldTitle} />
          ) : dataType === DynamicFieldTypes.DATE ? (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
              <DatePicker label={fieldTitle} value={dayjs(fieldValue)} dateFormat={dateFormat} readOnly />
            </LocalizationProvider>
          ) : (
            <TextField
              label={fieldTitle}
              defaultValue={fieldValue}
              InputProps={{ readOnly: true }}
              variant="standard"
              required
              fullWidth
            />
          )
        ) : (
          <Typography>
            {`${fieldTitle} (${translationForDataType()})`}
          </Typography>
        )
        }
      </Grid>
      <Grid item>
        <Button
          onClick={() => {
            onEdit(value);
          }}
        >
          <EditIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => {
            onDelete(value);
          }}
        >
          <DeleteIcon />
        </Button>
      </Grid>
    </Grid>
  );
}
