import React from 'react'
import { ReactComponent as Resource } from '../../assets/ic_resource.svg';
import '../../theme/overrides/ative.css';
import '../../theme/overrides/inative.css';

const ResourceIcon = (state) => (
  <Resource className={state ? 'ative' : 'inative'}/>
);

export default ResourceIcon;
