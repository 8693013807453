import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILED,
  AUTH_GET_WORKSPACES_SUCCESS,
  AUTH_GET_WORKSPACES_FAILED,
  AUTH_CHANGE_WORKSPACE_SUCCESS,
  AUTH_CHANGE_WORKSPACE_FAILED
} from './types';

const initialState = {
  current: {
    user: null,
    workspaces: null
  },
};

export default function loginReducer(action = {}, state = initialState) {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return {
        ...initialState,
        current: {
          user: action.payload.user,
          workspaces: state.current?.workspaces
        }
      }
    case AUTH_LOGIN_FAILED:
      return {
        ...initialState,
        error: action.payload
      };
    case AUTH_GET_WORKSPACES_SUCCESS:
      return {
        ...initialState,
        current: {
          user: state.current?.user,
          workspaces: action.payload
        }
      };
    case AUTH_GET_WORKSPACES_FAILED:
      return {
        ...initialState,
        error: action.payload
      };
    case AUTH_CHANGE_WORKSPACE_SUCCESS:
      return {
        ...initialState,
        current: {
          user: action.payload.user,
          workspaces: state.current?.workspaces
        }
      }
    case AUTH_CHANGE_WORKSPACE_FAILED:
      return {
        ...initialState,
        error: action.payload
      };
    default:
      break;
  }
  return state;
}