// material
import { IconButton } from "@mui/material";
// component
import { ReactComponent as DeleteIcon } from '../../../assets/ic_delete.svg';

export default function generate({ t, onDelete, hasRemoveItemFromFlowPermission }) {
    const columns = [
        {
            name: 'title',
            label: t('items.items.identifier'),
        },
        {
            name: 'description',
            label: t('items.items.description'),
        }
    ];

    if (hasRemoveItemFromFlowPermission) {
        columns.push({
            name: 'onDelete',
            label: ' ',
            options: {
                customBodyRender: (val, tableMeta) => (
                    <IconButton
                        onClick={() => onDelete(tableMeta)}
                        color="primary"
                    >
                        <DeleteIcon />
                    </IconButton>
                ),
            },
        });
    }

    return columns;
};