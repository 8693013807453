import * as TYPE from './types';
import { apiAction } from '../../api/actions';

export function getPermissions() {
    return apiAction({
        resource: '/su/permissions',
        method: 'GET',
        onSuccess: (data) => onGetSuccess(data),
        onFailure: (error) => onGetFailed(error),
        carry: {
            label: TYPE.GET_PERMISSIONS
        },
    });
};

function onGetSuccess(data) {
    return {
        type: TYPE.GET_PERMISSIONS_SUCCESS,
        payload: data,
    };
};

function onGetFailed(error) {
    return {
        type: TYPE.GET_PERMISSIONS_FAILED,
        payload: {
            error
        }
    };
};

export function editPermission(data, onSuccess, onFailure) {
    return apiAction({
        resource: '/su/permissions',
        method: 'POST',
        data,
        onSuccess: () => onEditSuccess(data, onSuccess),
        onFailure: (error) => onEditFailed(error, onFailure),
        carry: {
            label: TYPE.EDIT_PERMISSIONS
        },
    });
};
  
function onEditSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.EDIT_PERMISSIONS_SUCCESS,
        payload: data,
    };
};
  
function onEditFailed(error, onFailure) {
    onFailure();
    return {
        type: TYPE.EDIT_PERMISSIONS_FAILED,
        payload: {
            error
        }
    };
};

export function deletePermission(data, onSuccess, onFailure) {
    return apiAction({
        resource: '/su/permissions',
        method: 'DELETE',
        data,
        onSuccess: () => onDeleteSuccess(data, onSuccess),
        onFailure: (error) => onDeleteFailed(error, onFailure),
        carry: {
            label: TYPE.DELETE_PERMISSIONS
        }
    });
};

function onDeleteSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.DELETE_PERMISSIONS_SUCCESS,
        payload: data,
    };
};

function onDeleteFailed(error, onFailure) {
    onFailure();
    return {
        type: TYPE.DELETE_PERMISSIONS_FAILED,
        payload: {
            error
        }
    };
};