export default function generate({ t }) {
    return [
        {
            name: 'title',
            label: t('items.items.identifier'),
        },
        {
            name: 'description',
            label: t('items.items.description'),
        }
    ];
};