import React from 'react';
import { ReactComponent as Dashboard } from '../../assets/ic_dashboard.svg';
import '../../theme/overrides/ative.css';
import '../../theme/overrides/inative.css';

const DashboardIcon = (state) => (
  <Dashboard className={state ? 'ative' : 'inative'}/>
);

export default DashboardIcon;
