import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { styled } from '@mui/material/styles';
import { Link, Stack, Typography, TextField, IconButton, InputAdornment, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNotificationsProvider } from '../../context/NotificationsContext';
// component
import Iconify from '../../components/Iconify';
import Logo from '../../components/Logo';

import { login } from './actions'
import { selectSession, selectUserError } from './selectors';

// ----------------------------------------------------------------------
const LoginStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const ContainerStyle = styled(Box)(({ theme }) => ({
  shadowColor: theme.palette.secondary,
  backgroundColor: theme.palette.grey,
  shadowOpacity: 0.8,
  shadowRadius: 2,
  shadowOffset: {
    height: 1,
    width: 1
  },
  elevation: 5,
}));

const LogoStyle = {
  width: 175,
  height: 48
};

export default function LoginForm() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showNotification } = useNotificationsProvider();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('login.invalid-email')).required(t('login.required-email')),
    password: Yup.string().required(t('login.required-password')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (data) => {
      dispatch(login(dispatch, data.email, data.password))
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;

  const isAuth = useSelector(selectSession());
  const userError = useSelector(selectUserError());
  useEffect(() => {
    if (isAuth) {
      navigate('/dashboard/app', { replace: true });
    } else if (userError) {
      setSubmitting(false);
      showNotification('error', t('login.invalid-credentials'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, userError, setSubmitting, navigate]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <LoginStyle>
      <ContainerStyle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={10}>
              <Stack spacing={3}>
                <Stack alignItems="center">
                  <Logo sx={LogoStyle} />
                </Stack>
                <Typography variant="h4">
                  {t('login.title')}
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label={t('login.email-label')}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label={t('login.password-label')}
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Stack>
              <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={2} sx={{ my: 2 }}>
                <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
                  {t('login.login-button')}
                </LoadingButton>
                <Link component={RouterLink} variant="subtitle2" to="/forgot-password" underline="hover">
                  {t('login.forgot-password')}
                </Link>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </ContainerStyle>
    </LoginStyle>
  );
};