import PropTypes from 'prop-types';
import { Box, Typography, Avatar } from "@mui/material";
import { ReactComponent as DefaultUser } from '../../assets/ic_default_user.svg';

LoggedCard.propTypes = {
    user: PropTypes.object
};

export default function LoggedCard({ user }) {
    console.log('user', user);

    return (
        <>
            <Avatar alt="User Photo">
                {user && user.photoUrl ? (
                    <img src={user.photoUrl} alt="photoUrl" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                ) : (
                    <DefaultUser />
                )}
            </Avatar>
            <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    {user && user.fullName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {user && user.rollName}
                </Typography>
            </Box>
        </>
    );
};