import { stringify } from 'query-string'
import {
  AUTOCOMPLETE,
  AUTOCOMPLETE_SUCCESS,
  AUTOCOMPLETE_FAILED,
} from './types';
import { apiAction } from '../../api/actions';

export function getAutocompleteResults(resource, query) {
  let url = `/su/${resource}`
  if (query) {
    url += `?${stringify(query)}`
  }
  return apiAction({
    resource: url,
    method: 'GET',
    onSuccess: (data) => onSuccess(data, resource),
    onFailure: (error) => onFailed(error, resource),
    carry: {
      label: AUTOCOMPLETE
    },
  });
}

function onSuccess(data, resource) {
  return {
    type: AUTOCOMPLETE_SUCCESS,
    payload: data,
    resource
  };
}

function onFailed(error, resource) {
  return {
    type: AUTOCOMPLETE_FAILED,
    payload: {
      error
    },
    resource
  };
}