import {
  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILED,
  ADD_ITEM,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_FAILED,
  REQUEST_UPLOAD_ITEM_IMAGE,
  REQUEST_UPLOAD_ITEM_IMAGE_SUCCESS,
  REQUEST_UPLOAD_ITEM_IMAGE_FAILED,
  UPLOAD_ITEM_IMAGE,
  UPLOAD_ITEM_IMAGE_SUCCESS,
  UPLOAD_ITEM_IMAGE_FAILED,
  EDIT_ITEM,
  EDIT_ITEM_SUCCESS,
  EDIT_ITEM_FAILED,
  DELETE_ITEM,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILED
} from './types';
import { apiAction, uploadAction } from '../../api/actions';

export function getItems(params) {
  return apiAction({
    resource: '/su/items',
    params,
    method: 'GET',
    onSuccess: (data) => onGetSuccess(data),
    onFailure: (error) => onGetFailed(error),
    carry: {
      label: GET_ITEMS
    },
  });
}

function onGetSuccess(data) {
  return {
    type: GET_ITEMS_SUCCESS,
    payload: data
  };
}

function onGetFailed(error) {
  return {
    type: GET_ITEMS_FAILED,
    payload: {
      error
    }
  };
}

export function requestUpload(requestUploadData, onSuccess, onFailure) {
  return apiAction({
    resource: '/su/upload',
    method: 'POST',
    data: requestUploadData,
    onSuccess: (data) => onRequestUploadSuccess(data, onSuccess),
    onFailure: (error) => onRequestUploadFailed(error, onFailure),
    carry: {
      label: REQUEST_UPLOAD_ITEM_IMAGE
    },
  });
}

function onRequestUploadSuccess(data, onSuccess) {
  onSuccess(data)
  return {
    type: REQUEST_UPLOAD_ITEM_IMAGE_SUCCESS,
    payload: data
  };
}

function onRequestUploadFailed(error, onFailure) {
  onFailure()
  return {
    type: REQUEST_UPLOAD_ITEM_IMAGE_FAILED,
    payload: {
      error
    }
  };
}

export function upload(url, formData, onSuccess, onFailure) {
  return uploadAction({
    url,
    method: 'POST',
    data: formData,
    onSuccess: () => onUploadSuccess(onSuccess),
    onFailure: (error) => onUploadFailed(error, onFailure),
    carry: {
      label: UPLOAD_ITEM_IMAGE
    },
  });
}

function onUploadSuccess(onSuccess) {
  onSuccess()
  return {
    type: UPLOAD_ITEM_IMAGE_SUCCESS,
    payload: {
      uploaded: true
    }
  };
}

function onUploadFailed(error, onFailure) {
  onFailure()
  return {
    type: UPLOAD_ITEM_IMAGE_FAILED,
    payload: {
      error
    }
  };
}

export function addItem(data, onSuccess, onFailure) {
  return apiAction({
    resource: '/su/items',
    method: 'POST',
    data,
    onSuccess: (data) => onAddSuccess(data, onSuccess),
    onFailure: (error) => onAddFailed(error, onFailure),
    carry: {
      label: ADD_ITEM
    },
  });
}

function onAddSuccess(data, onSuccess) {
  onSuccess()
  return {
    type: ADD_ITEM_SUCCESS,
    payload: data
  };
}

function onAddFailed(error, onFailure) {
  onFailure(error)
  return {
    type: ADD_ITEM_FAILED,
    payload: {
      error
    }
  };
}

export function editItem(id, data, onSuccess, onFailure) {
  return apiAction({
    resource: `/su/items/${id}/edit`,
    method: 'PUT',
    data,
    onSuccess: (data) => onEditSuccess(data, onSuccess),
    onFailure: (error) => onEditFailed(error, onFailure),
    carry: {
      label: EDIT_ITEM
    },
  });
}

function onEditSuccess(data, onSuccess) {
  onSuccess()
  return {
    type: EDIT_ITEM_SUCCESS,
    payload: data
  };
}

function onEditFailed(error, onFailure) {
  onFailure(error)
  return {
    type: EDIT_ITEM_FAILED,
    payload: {
      error
    }
  };
}

export function deleteItems(data, onSuccess, onFailure) {
  return apiAction({
    resource: `/su/items/delete`,
    method: 'DELETE',
    data,
    onSuccess: (data) => onDeleteSuccess(data, onSuccess),
    onFailure: (error) => onDeleteFailed(error, onFailure),
    carry: {
      label: DELETE_ITEM
    },
  });
}

function onDeleteSuccess(data, onSuccess) {
  onSuccess()
  return {
    type: DELETE_ITEM_SUCCESS,
    payload: data
  };
}

function onDeleteFailed(error, onFailure) {
  onFailure()
  return {
    type: DELETE_ITEM_FAILED,
    payload: {
      error
    }
  };
}