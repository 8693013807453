import React from 'react'
import { ReactComponent as Users } from '../../assets/ic_users.svg';
import '../../theme/overrides/ative.css';
import '../../theme/overrides/inative.css';

const UsersIcon = (state) => (
  <Users className={state ? 'ative' : 'inative'}/>
);

export default UsersIcon;
