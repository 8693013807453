import {
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// component
import { ReactComponent as PhotoPlaceholder } from '../../assets/ic_photo_placeholder.svg';

const Active = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main
}));

const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString));
  }
  catch (e) {
    return false;
  }
}

export default function generate({ t }, tenant) {
  return [
    {
      name: 'logo',
      label: t('components.workspace-selector.table-logo'),
      options: {
        customBodyRender: (val) => (
          val.length > 0 && isValidUrl(val) ?
            <img
              src={val}
              alt='logo'
              style={{ width: 50, height: 50, borderRadius: 5, objectFit: 'cover' }}
            /> :
            <PhotoPlaceholder width={28} style={{ marginLeft: '10px' }} />
        )
      },
    },
    {
      name: 'name',
      label: t('components.workspace-selector.table-name'),
    },
    {
      name: 'tenant',
      label: ' ',
      options: {
        customBodyRender: (val) => (
          val === tenant && (
            <Active variant='subtitle2'>{t('components.workspace-selector.table-current')}</Active>
          )
        )
      },
    }
  ];
};