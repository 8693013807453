const Permissions = Object.freeze({
    PAGE_DASHBOARD_ROOT: 'page-dashboard:root',
    PAGE_INSPECTIONS_ROOT: 'page-inspections:root',
    PAGE_INSPECTIONS_REMOVE: 'page-inspections:remove',
    PAGE_RESOURCE_TYPES_ROOT: 'page-resource-types:root',
    PAGE_RESOURCE_TYPES_ADD: 'page-resource-types:add',
    PAGE_RESOURCE_TYPES_EDIT: 'page-resource-types:edit',
    PAGE_RESOURCE_TYPES_REMOVE: 'page-resource-types:remove',
    PAGE_RESOURCES_ROOT: 'page-resources:root',
    PAGE_RESOURCES_ADD: 'page-resources:add',
    PAGE_RESOURCES_EDIT: 'page-resources:edit',
    PAGE_RESOURCES_REMOVE: 'page-resources:remove',
    PAGE_ITEMS_ROOT: 'page-items:root',
    PAGE_ITEMS_ADD: 'page-items:add',
    PAGE_ITEMS_EDIT: 'page-items:edit',
    PAGE_ITEMS_REMOVE: 'page-items:remove',
    PAGE_FLOWS_ROOT: 'page-flows:root',
    PAGE_FLOWS_ADD: 'page-flows:add',
    PAGE_FLOWS_EDIT: 'page-flows:edit',
    PAGE_FLOWS_REMOVE: 'page-flows:remove',
    PAGE_FLOWS_ADD_ITEM: 'page-flows:add-item',
    PAGE_FLOWS_REMOVE_ITEM: 'page-flows:remove-item',
    PAGE_USERS_ROOT: 'page-users:root',
    PAGE_USERS_ADD: 'page-users:add',
    PAGE_USERS_EDIT: 'page-users:edit',
    PAGE_USERS_REMOVE: 'page-users:remove',
    PAGE_PERMISSIONS_ROOT: 'page-permissions:root',
    PAGE_APPLICATION_ROOT: 'application:root',
});

const translations = {
    [Permissions.PAGE_DASHBOARD_ROOT]: 'permissions.pages.dashboard.root',
    [Permissions.PAGE_INSPECTIONS_ROOT]: 'permissions.pages.inspections.root',
    [Permissions.PAGE_INSPECTIONS_REMOVE]: 'permissions.pages.inspections.remove',
    [Permissions.PAGE_RESOURCE_TYPES_ROOT]: 'permissions.pages.resource-types.root',
    [Permissions.PAGE_RESOURCE_TYPES_ADD]: 'permissions.pages.resource-types.add',
    [Permissions.PAGE_RESOURCE_TYPES_EDIT]: 'permissions.pages.resource-types.edit',
    [Permissions.PAGE_RESOURCE_TYPES_REMOVE]: 'permissions.pages.resource-types.remove',
    [Permissions.PAGE_RESOURCES_ROOT]: 'permissions.pages.resources.root',
    [Permissions.PAGE_RESOURCES_ADD]: 'permissions.pages.resources.add',
    [Permissions.PAGE_RESOURCES_EDIT]: 'permissions.pages.resources.edit',
    [Permissions.PAGE_RESOURCES_REMOVE]: 'permissions.pages.resources.remove',
    [Permissions.PAGE_ITEMS_ROOT]: 'permissions.pages.items.root',
    [Permissions.PAGE_ITEMS_ADD]: 'permissions.pages.items.add',
    [Permissions.PAGE_ITEMS_EDIT]: 'permissions.pages.items.edit',
    [Permissions.PAGE_ITEMS_REMOVE]: 'permissions.pages.items.remove',
    [Permissions.PAGE_FLOWS_ROOT]: 'permissions.pages.flows.root',
    [Permissions.PAGE_FLOWS_ADD]: 'permissions.pages.flows.add',
    [Permissions.PAGE_FLOWS_EDIT]: 'permissions.pages.flows.edit',
    [Permissions.PAGE_FLOWS_REMOVE]: 'permissions.pages.flows.remove',
    [Permissions.PAGE_FLOWS_ADD_ITEM]: 'permissions.pages.flows.add-item',
    [Permissions.PAGE_FLOWS_REMOVE_ITEM]: 'permissions.pages.flows.remove-item',
    [Permissions.PAGE_USERS_ROOT]: 'permissions.pages.users.root',
    [Permissions.PAGE_USERS_ADD]: 'permissions.pages.users.add',
    [Permissions.PAGE_USERS_EDIT]: 'permissions.pages.users.edit',
    [Permissions.PAGE_USERS_REMOVE]: 'permissions.pages.users.remove',
    [Permissions.PAGE_PERMISSIONS_ROOT]: 'permissions.pages.permissions.root',
    [Permissions.PAGE_APPLICATION_ROOT]: 'permissions.pages.application.root',
}

const permissionTree = {
    [Permissions.PAGE_DASHBOARD_ROOT]: [],
    [Permissions.PAGE_INSPECTIONS_ROOT]: [
        Permissions.PAGE_INSPECTIONS_REMOVE,
    ],
    [Permissions.PAGE_RESOURCE_TYPES_ROOT]: [
        Permissions.PAGE_RESOURCE_TYPES_ADD,
        Permissions.PAGE_RESOURCE_TYPES_EDIT,
        Permissions.PAGE_RESOURCE_TYPES_REMOVE,
    ],
    [Permissions.PAGE_RESOURCES_ROOT]: [
        Permissions.PAGE_RESOURCES_ADD,
        Permissions.PAGE_RESOURCES_EDIT,
        Permissions.PAGE_RESOURCES_REMOVE,
    ],
    [Permissions.PAGE_ITEMS_ROOT]: [
        Permissions.PAGE_ITEMS_ADD,
        Permissions.PAGE_ITEMS_EDIT,
        Permissions.PAGE_ITEMS_REMOVE
    ],
    [Permissions.PAGE_FLOWS_ROOT]: [
        Permissions.PAGE_FLOWS_ADD,
        Permissions.PAGE_FLOWS_EDIT,
        Permissions.PAGE_FLOWS_REMOVE,
        Permissions.PAGE_FLOWS_ADD_ITEM,
        Permissions.PAGE_FLOWS_REMOVE_ITEM,
    ],
    [Permissions.PAGE_USERS_ROOT]: [
        Permissions.PAGE_USERS_ADD,
        Permissions.PAGE_USERS_EDIT,
        Permissions.PAGE_USERS_REMOVE,
    ],
    [Permissions.PAGE_PERMISSIONS_ROOT]: [],
    [Permissions.PAGE_APPLICATION_ROOT]: [],
}

const Roles = Object.freeze({
    ADMIN: 'admin',
    MANAGER: 'manager',
    OPERATOR: 'operator',
});

function translate(permission, t) {
    return t(translations[permission])
};

export { Permissions, translate, permissionTree, Roles };