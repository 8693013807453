import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import { selectSession } from '../../session/selectors';
import BaseDialog from '../base-dialog'
import BFTable from '../table'
import generateColumns from './columns';
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { getWorkspaces, changeWorkspace } from '../../pages/login/actions'
import { selectWorkspaces } from '../../pages/login/selectors';

WorkspaceSelectorDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default function WorkspaceSelectorDialog({
  title,
  open,
  onClose
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { showNotification } = useNotificationsProvider()

  const workspaces = useSelector(selectWorkspaces())
  const session = useSelector(selectSession())

  const [loading, setLoading] = useState(false)
  const [tableWorkspaces, setTableWorkspaces] = useState([])
  const [itemsTableState, setItemsTableState] = useState({
    page: 0,
    sort: null,
    filters: null
  })

  const resource = `workspace`

  const itemsTableColumns = generateColumns({
    t,
  }, session.tenant);

  const resetState = () => {
    // Clean form
    setLoading(false)
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = (row) => {
    setLoading(true)

    const workspaceName = row[1]

    // Get workspace by name from workspaces list
    const workspace = tableWorkspaces.find(ws => ws.name === workspaceName)

    dispatch(changeWorkspace(dispatch, workspace.id, () => {
      setLoading(false)
      resetState()
      onClose(true)
    }, () => {
      setLoading(false)
      showNotification('error', t('common.api-error'))
    }))
  }

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      dispatch(getWorkspaces(() => {
        showNotification('error', t('common.api-error'))
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (workspaces) {
      setTableWorkspaces(workspaces)
    }
    setLoading(false)
  }, [workspaces])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={title}
      loading={loading}
      hideActions
    >
      <Grid container direction="column" spacing={4}>
        <Grid item marginTop={2} marginBottom={2}>
          <BFTable
            id={resource}
            data={{ itemCount: tableWorkspaces.length, items: [...tableWorkspaces], range: { limit: 15 } }}
            columns={itemsTableColumns}
            page={itemsTableState.page}
            sort={itemsTableState.sort}
            onChangePage={(currentPage) => {
              setItemsTableState({ ...itemsTableState, page: currentPage });
            }}
            onColumnSortChange={(changedColumn, direction) => {
              const newSort = {
                field: changedColumn,
                direction: direction.toUpperCase(),
              };
              setItemsTableState({ ...itemsTableState, sort: newSort });
            }}
            onRowClick={(row) => {
              onConfirm(row)
            }}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}