import { configureStore } from '@reduxjs/toolkit';
import logActionsMiddleware from '../middleware/logActions';
import apiMiddleware from '../middleware/api';
import permissionsMiddleware from '../middleware/permissions';
import permissionsReducer from '../permissions/permissionsReducer';
import permissionsPageReducer from '../pages/permissions/permissionsReducer';
import featuresMiddleware from '../middleware/features';
import featuresReducer from './features/featuresReducer';
import sessionReducer from '../session/sessionReducer';
import sessionMiddleware from '../middleware/session';
import loginReducer from '../pages/login/loginReducer';
import inspectionsReducer from '../pages/inspections/inspectionsReducer';
import resourceTypeReducer from '../pages/resource-type/resourceTypeReducer';
import resourcesReducer from '../pages/resources/resourcesReducer';
import itemsReducer from '../pages/items/itemsReducer';
import usersReducer from '../pages/users/usersReducer';
import flowsReducer from '../pages/flows/flowsReducer';
import flowTypesReducer from './flow-types/flowTypeReducer';
import autocompleteReducer from '../components/autocomplete-input/autocompleteReducer';
import dashboardReducer from '../pages/dashboard/dashboardReducer';
import seeInspectionReducer from '../pages/inspections/see-inspection/seeInspectionReducer';
import isDev from '../utils/isDev';
import { API, API_ERROR, API_START, API_END, ACCESS_DENIED, UNAUTHORIZED, UPLOAD, UPLOAD_START, UPLOAD_END, UPLOAD_ERROR } from '../api/types';
import { REFRESH_SESSION, REFRESH_SESSION_FAILED, SET_SESSION, CLEAR_SESSION, RETRY_ACTION } from '../session/types';

let middleware = [apiMiddleware, sessionMiddleware, permissionsMiddleware, featuresMiddleware];

if (isDev()) {
  middleware = [logActionsMiddleware, ...middleware];
}

const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: [API, API_ERROR, API_START, API_END, ACCESS_DENIED, UNAUTHORIZED, REFRESH_SESSION, REFRESH_SESSION_FAILED, SET_SESSION, CLEAR_SESSION,
        UPLOAD, UPLOAD_START, UPLOAD_END, UPLOAD_ERROR, RETRY_ACTION],
    },
  }).concat(middleware),
  reducer: {
    permissions: permissionsReducer,
    permissionsPage: permissionsPageReducer,
    session: sessionReducer,
    user: loginReducer,
    inspections: inspectionsReducer,
    resourceTypes: resourceTypeReducer,
    resources: resourcesReducer,
    items: itemsReducer,
    users: usersReducer,
    flows: flowsReducer,
    flowTypes: flowTypesReducer,
    autocompleteResults: autocompleteReducer,
    dashboard: dashboardReducer,
    features: featuresReducer,
    seeInspection: seeInspectionReducer,
  },
});
export default store;
