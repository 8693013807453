import { AUTH_FORGOT_PASSWORD, AUTH_FORGOT_PASSWORD_FAILED, AUTH_FORGOT_PASSWORD_SUCCESS } from './types';
import { apiAction } from '../../api/actions';

export function resetPassword(email, onSuccess, onFailure) {
  return apiAction({
    resource: '/su/auth/forgot-password',
    method: 'POST',
    data: {
      email
    },
    onSuccess: (data) => onSuccessResetPassword(data, onSuccess),
    onFailure: (error) => onFailedResetPassword(error, onFailure),
    carry: {
      label: AUTH_FORGOT_PASSWORD
    },
  });
}

function onSuccessResetPassword(data, onSuccess) {
  onSuccess();
  return {
    type: AUTH_FORGOT_PASSWORD_SUCCESS,
    payload: data
  };
}

function onFailedResetPassword(error, onFailure) {
  onFailure();
  return {
    type: AUTH_FORGOT_PASSWORD_FAILED,
    payload: {
      error
    }
  };
}