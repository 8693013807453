import { Checkbox } from "@mui/material";

export default function generate({ t, itemsColumns, updateRole }) {

    const columns = [{
        name: 'translation',
        label: t('permissions.items.features'),
    }]

    if (itemsColumns) {
        Object.keys(itemsColumns).forEach((key) => {
            const role = {
                name: 'roles',
                label: t(`permissions.items.${key}`),
                options: {
                    sort: false,
                    customBodyRender: (val, tableMeta) => (
                        <Checkbox
                            onChange={() => updateRole(tableMeta.rowIndex, key, val?.[key] ?? false)}
                            checked={val?.[key] ?? false}
                        />
                    )
                }
            }

            columns.push(role)
        })
    }

    return columns;
};

/**
 * 
 */