import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BaseDialog from '../../base-dialog';
import { addItem, requestUpload, upload } from '../../../pages/items/actions';
import AutocompleteInput from '../../autocomplete-input';
import { useNotificationsProvider } from '../../../context/NotificationsContext';
import ImagePicker from '../../image-picker';

AddItemDialog.propTypes = {
  title: PropTypes.string,
  parent: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
};

export default function AddItemDialog({ title, parent, open, onClose, onCreated }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showNotification } = useNotificationsProvider();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [itemTitle, setItemTitle] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemParent, setItemParent] = useState({});
  const [images, setImages] = useState([]);

  const resetState = () => {
    // Clean form
    setLoading(false);
    setError(null);
    setItemTitle('');
    setItemDescription('');
    setItemParent(parent ?? parent);
    setImages([]);
  };

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose();
    } else {
      resetState();
      onClose();
    }
  };

  const onConfirm = () => {
    if (itemTitle.length === 0) {
      setError('title_required');
      return;
    }

    if (itemDescription.length === 0) {
      setError('description_required');
      return;
    }

    setLoading(true);

    console.log(`PARENT: ${itemParent}`)
    const data = {
      title: itemTitle,
      description: itemDescription
    };

    if (itemParent?.id) {
      data.parentId = itemParent.id
    }

    if (images.length > 0) {
      const imagesURLs = [];
      // Map images and upload them
      let successfulUploadsCounter = 0;
      images.forEach((image) => {
        // Get S3 url
        const requestUploadData = {
          contentType: image.type,
        };
        dispatch(
          requestUpload(requestUploadData, (response) => {
            // Prepare multiform data
            const formDataFields = response.formData.fields || {};
            const formData = new FormData();
            Object.keys(formDataFields).forEach((fieldName) => {
              const fieldValue = formDataFields[fieldName];
              formData.append(fieldName, fieldValue);
            });
            formData.append('file', image);
            // Upload image to S3
            dispatch(
              upload(response.formData.url, formData, () => {
                successfulUploadsCounter += 1;
                imagesURLs.push(response.url);
                if (successfulUploadsCounter === images.length) {
                  data.photosJson = JSON.stringify(imagesURLs)
                  // Finally create item
                  dispatch(
                    addItem(
                      data,
                      () => {
                        resetState();
                        onCreated();
                      },
                      (error) => {
                        const errorCode = error.response.data.validationErrors && error.response.data.validationErrors[0].errorCode;
                        if (errorCode === 'ITEM_DETAILS_ALREADY_USED') {
                          showNotification('error', t('items.add-items-dialog.already-exists-error'));
                        } else if (errorCode === 'ITEM_LEVEL_EXCEEDED') {
                          showNotification('error', t('items.add-items-dialog.level-exceeded-error'));
                        } else {
                          showNotification('error', t('common.api-error'));
                        }
                        setLoading(false);
                      }
                    )
                  );
                }
              }, () => {
                showNotification('error', t('common.api-error'));
              })
            )
          }, () => {
            showNotification('error', t('common.api-error'));
          })
        );
      });
    } else {
      dispatch(
        addItem(
          data,
          () => {
            resetState();
            onCreated();
          },
          (error) => {
            const errorCode = error.response.data.validationErrors && error.response.data.validationErrors[0].errorCode;
            if (errorCode === 'ITEM_DETAILS_ALREADY_USED') {
              showNotification('error', t('items.add-item-dialog.already-exists-error'));
            } else if (errorCode === 'ITEM_LEVEL_EXCEEDED') {
              showNotification('error', t('items.add-item-dialog.level-exceeded-error'));
            } else {
              showNotification('error', t('common.api-error'));
            }
            setLoading(false);
          }
        )
      );
    }
  };

  useEffect(() => {
    setItemParent(parent ?? parent);
  }, [parent]);


  return (
    <BaseDialog open={open} onClose={onCloseDialog} onConfirm={onConfirm} title={title} loading={loading}>
      <Grid container direction="column" spacing={4}>
        <Grid item marginTop={2}>
          <ImagePicker onChange={setImages} />
        </Grid>
        <Grid item marginTop={2}>
          <TextField
            error={error === 'title_required'}
            onChange={(event) => {
              setItemTitle(event.target.value);
            }}
            label={t('items.add-item-dialog.item-title')}
            variant="standard"
            required
            fullWidth
          />
        </Grid>
        <Grid item marginTop={2}>
          <TextField
            error={error === 'description_required'}
            onChange={(event) => {
              setItemDescription(event.target.value);
            }}
            label={t('items.add-item-dialog.description-title')}
            variant="standard"
            multiline
            required
            fullWidth
          />
        </Grid>
        <Grid item marginTop={2} marginBottom={2}>
          <AutocompleteInput
            onChange={(val) => {
              setItemParent(val);
            }}
            defaultValue={itemParent && itemParent}
            label={t('items.add-item-dialog.parent-item-title')}
            resource="items/autocomplete"
            canDelete
            refreshOnInputChange
            clearOnBlur={false}
            freeSolo
          />
        </Grid>
      </Grid>
    </BaseDialog>
  );
}
