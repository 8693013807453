import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { Grid, IconButton, TextField } from '@mui/material';
// components
import AutocompleteInput from '../../autocomplete-input';
import BaseDialog from '../../base-dialog';
import { addUser, requestUpload, upload } from '../../../pages/users/actions';
import { useNotificationsProvider } from '../../../context/NotificationsContext';
import { ReactComponent as DefaultUserIcon } from '../../../assets/ic_default_user.svg';
import { UserIcon, State } from '../../UserIcon';
import validators from '../../../utils/validators'

AddUserDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onCreated: PropTypes.func,
};

// TODO --> IMPLEMENT EMPLOYEE
export default function AddUserDialog({
    title,
    open,
    onClose,
    onCreated,
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { showNotification } = useNotificationsProvider();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [photoUrl, setPhotoUrl] = useState(null);
    const [photoState, setPhotoState] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');


    const resetState = () => {
        setLoading(false);
        setError(null);
        setPhoto(null);
        setPhotoUrl(null);
        setPhotoState(null);
        setFirstName('');
        setLastName('');
        setRole('');
        setEmail('');
    }

    const onCloseDialog = (event, reason) => {
        if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
            onClose();
        } else {
            resetState();
            onClose();
        }
    };

    const onConfirm = () => {
        if (firstName.length === 0) {
            setError('firstName_required');
            return;
        }

        if (lastName.length === 0) {
            setError('lastName_required');
            return;
        }

        if (role.length === 0) {
            setError('role_required');
            return;
        }

        if (email.length === 0) {
            setError('email_required');
            return;
        }

        if (!validators.isValidEmail(email)) {
            setError('email_invalid');
            return;
        }

        setLoading(true);

        // ADD THE MISSING FIELDS
        const data = {
            firstName,
            lastName,
            email,
            role: role.id
        };

        if (photoUrl) {
            data.photoUrl = photoUrl;
        }

        dispatch(addUser(data, () => {
            showNotification('success', t('user.add-user-dialog.success', { name: `${firstName} ${lastName}` }));
            resetState();
            onCreated();
        }, () => {
            showNotification('error', t('user.add-user-dialog.error', { name: `${firstName} ${lastName}` }));
        }));
    };

    const handleImageUpload = (event) => {
        const image = event.target.files[0];
        if (image) {
            setPhotoState(State.Loading);
            setPhoto(URL.createObjectURL(image));
            // Get S3 url
            const requestUploadData = {
                contentType: image.type,
            };
            console.log('requestUploadData', requestUploadData);
            dispatch(
                requestUpload(requestUploadData, (response) => {
                    // Prepare multiform data
                    const formDataFields = response.formData.fields || {};
                    const formData = new FormData();
                    Object.keys(formDataFields).forEach((fieldName) => {
                        const fieldValue = formDataFields[fieldName];
                        formData.append(fieldName, fieldValue);
                    });
                    formData.append('file', image);
                    // Upload image to S3
                    console.log('response.formData.url', response.formData.url);
                    dispatch(
                        upload(response.formData.url, formData, () => {
                            setPhotoUrl(response.url);
                            setPhotoState(State.Success);
                        }, () => {
                            setPhotoState(State.Error);
                            showNotification('error', t('common.api-error'));
                        })
                    )
                }, () => {
                    setPhotoState(State.Error);
                    showNotification('error', t('common.api-error'));
                })
            );
        }
    };

    return (
        <BaseDialog
            open={open}
            onClose={onCloseDialog}
            onConfirm={onConfirm}
            title={title}
            loading={loading}
        >
            <Grid container direction="column" spacing={4} >
                <Grid item marginTop={2} >
                    <Grid container justifyContent="center" alignItems="center" >
                        {photo ? (
                            <IconButton component="label">
                                <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
                                <UserIcon src={photo} width={58} height={58} state={photoState} />
                            </IconButton>
                        ) : (
                            <IconButton component="label">
                                <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
                                <DefaultUserIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField
                        error={error === 'firstName_required'}
                        onChange={({ target }) => {
                            setFirstName(target.value);
                        }}
                        label={t('user.add-user-dialog.firstName-title')}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        error={error === 'lastName_required'}
                        onChange={({ target }) => {
                            setLastName(target.value);
                        }}
                        label={t('user.add-user-dialog.lastName-title')}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        error={error === 'email_required' || error === 'email_invalid'}
                        onChange={({ target }) => {
                            setEmail(target.value);
                        }}
                        label={t('user.add-user-dialog.email-title')}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item marginBottom={2}>
                    <AutocompleteInput
                        error={error === 'role_required'}
                        onChange={(val) => {
                            setRole(val);
                        }}
                        onInputChange={(val) => {
                            setRole(val);
                        }}
                        label={t('user.add-user-dialog.role-title')}
                        resource="users/roles-autocomplete"
                        required
                        canDelete
                        refreshOnInputChange
                        clearOnBlur={false}
                        freeSolo
                    />
                </Grid>
            </Grid>
        </BaseDialog>
    );
};

/**
 * EMPLOYEE TEXTFIELD TO ADD LATER
 * <Grid item>
 *  <TextField 
 *      error={error === 'employee_required'}
 *      onChange={({ target }) => {
 *          setEmployee(target.value);
 *      }}
 *      label={t('user.add-users-dialog.employee-title')}
 *      required
 *      fullWidth
 *  />
 * </Grid>
 */