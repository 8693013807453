import { SET_PERMISSIONS } from './types';

const initialState = {
  map: {},
};

export default function permissionsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PERMISSIONS: {
      const map = (action.payload.data || []).reduce((map, permissionId) => {
        map[permissionId] = {
          enabled: true,
        };
        return map;
      }, {});
      return {
        ...state,
        map,
      };
    }
    default:
      break;
  }
  return state;
}
