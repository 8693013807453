import { GET_INSPECTIONS_SUCCESS, GET_INSPECTIONS_FAILED, GET_INSPECTION_ITEMS_SUCCESS, GET_INSPECTION_ITEMS_FAILED } from "./types";

const initialState = {
    current: {
        inspections: null,
        inspectionItems: null
    }
};

export default function inspectionsReducer(action = {}, state = initialState) {
    switch (action.type) {
        case GET_INSPECTIONS_SUCCESS:
            return {
                ...state,
                current: {
                    inspections: action.payload,
                    inspectionItems: state.current?.inspectionItems
                },
            };
        case GET_INSPECTIONS_FAILED:
            return {
                ...state,
                current: {
                    inspections: action.payload,
                    inspectionItems: state.current?.inspectionItems
                }
            };
        case GET_INSPECTION_ITEMS_SUCCESS:
            return {
                ...state,
                current: {
                    inspections: state.current?.inspections,
                    inspectionItems: action.payload
                },
            };
        case GET_INSPECTION_ITEMS_FAILED:
            return {
                ...state,
                current: {
                    inspections: state.current?.inspections,
                    inspectionItems: action.payload
                }
            };
        default:
            break;
    }
    return state;
};