/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { ReactComponent as PhotoPlaceholder } from '../../../../assets/ic_photo_placeholder.svg';
import { ReactComponent as ActiveIcon } from '../../../../assets/ic_check.svg';
import { ReactComponent as InactiveButton } from '../../../../assets/ic_uncheck.svg';

const ContainerStyle = styled(Grid)(() => ({
  margin: '10px 0',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginRight: 5,
}));

EvaluationFormCard.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
  onSee: PropTypes.func,
}

export default function EvaluationFormCard({ t, data, onSee }) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={5}>
          <Grid item>
            <ContainerStyle container direction="row" alignItems="center">
              <Label>
                {`${t('inspections.evaluation-form-card.image')}:`}
              </Label>
              {data?.image ?
                <img
                  src={data?.image}
                  alt=''
                  onClick={() => onSee([data?.image])}
                  style={{ width: 50, height: 50, borderRadius: 5, objectFit: 'cover', marginLeft: '10px', cursor: 'pointer' }}
                /> :
                <PhotoPlaceholder width={28} style={{ marginLeft: '10px' }} />}
            </ContainerStyle>
            <ContainerStyle container direction="row">
              <Label>
                {`${t('inspections.evaluation-form-card.date')}:`}
              </Label>
              <Typography>
                {data?.controlDate}
              </Typography>
            </ContainerStyle>
            {data?.dynamicFields?.map((field) => (
              <ContainerStyle key={field.title} container alignItems={'center'} direction="row">
                <Label>
                  {`${field.title}:`}
                </Label>
                {(typeof field.value === 'boolean') ? (
                  // Show boolean values with icons/assets instead of text
                  field.value ? <ActiveIcon /> : <InactiveButton />
                ) : (
                  <Typography>
                    {field.value}
                  </Typography>
                )}
              </ContainerStyle>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
