export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const UPLOAD = 'UPLOAD';
export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_END = 'UPLOAD_END';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const API_ERROR = 'API_ERROR';
