// material
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
// component
import { ReactComponent as PhotoPlaceholder } from '../../../assets/ic_photo_placeholder.svg';
import { FlowTypes } from '../../../entities/flow-type'

const Validation = styled(Typography)(({ theme, state }) => ({
    color: state === '1' ? theme.palette.success.main : theme.palette.error.main,
}));

const Attachment = styled(Typography)(({ theme, state }) => ({
    color: state === 'true' ? theme.palette.primary.main : theme.palette.text.primary,
    cursor: state === 'true' ? 'pointer' : 'default',
    '&:hover': {
        textDecoration: state === 'true' ? 'underline' : 'none',
    },
}));

export default function generate({ t, onSee, controlFlowType = { name: FlowTypes.SEQUENTIAL } }) {
    return controlFlowType.name === FlowTypes.SEQUENTIAL ? [
        {
            name: 'Item.photos',
            label: t('items.items.icon'),
            options: {
                customBodyRender: (val) => (
                    val.length > 0 ?
                        <img
                            src={val[0]}
                            alt=''
                            style={{ width: 50, height: 50, borderRadius: 5, objectFit: 'cover' }}
                        /> :
                        <PhotoPlaceholder width={28} style={{ marginLeft: '10px' }} />
                ),
            },
        },
        {
            name: 'Item.title',
            label: t('items.items.identifier'),
        },
        {
            name: 'conformity.id',
            label: t('inspections.info-card.complaint'),
            options: {
                customBodyRender: (val) => (
                    <Validation state={val.toString()}>{val ? t('inspections.items.yes') : t('inspections.items.no')}</Validation>
                ),
            },
        },
        {
            name: 'description',
            label: t('items.items.description'),
            options: {
                customBodyRender: (val) => (
                    <div style={{ maxHeight: '180px', overflowY: 'auto' }}>
                        {val && val}
                    </div>
                ),
            },
        },
        {
            name: 'photos',
            label: t('inspections.items.attachments', { number: '' }),
            options: {
                customBodyRender: (val) => {
                    if (val.length > 0) {
                        if (val.length === 1) {
                            return (
                                <Attachment
                                    state={(val.length > 0).toString()}
                                    onClick={() => onSee(val)}
                                >
                                    {t('inspections.items.attachment', { number: val.length })}
                                </Attachment>
                            );
                        }
                        return (
                            <Attachment
                                state={(val.length > 0).toString()}
                                onClick={() => onSee(val)}
                            >
                                {t('inspections.items.attachments', { number: val.length })}
                            </Attachment>
                        );
                    }
                    return (
                        <Attachment state={(val.length > 0).toString()}>
                            {t('inspections.items.non-attachment')}
                        </Attachment>
                    );
                },
            },
        },
    ] : [
        {
            name: 'photos',
            label: t('items.items.icon'),
            options: {
                customBodyRender: (val) => (
                    val.length > 0 ?
                        <img
                            src={val[0]}
                            alt=''
                            style={{ width: 50, height: 50, borderRadius: 5, objectFit: 'cover' }}
                        /> :
                        <PhotoPlaceholder width={28} style={{ marginLeft: '10px' }} />
                ),
            },
        },
        {
            name: 'title',
            label: t('items.items.identifier'),
        },
        {
            name: 'report.conformity.id',
            label: t('inspections.info-card.complaint'),
            options: {
                customBodyRender: (val, item) => {
                    console.log("Report conformity value: ", item.value.report?.conformity.id)
                    const conformity = item.value.report?.conformity.id
                    return conformity !== undefined ? <Validation state={conformity.toString()}>{conformity ? t('inspections.items.yes') : t('inspections.items.no')}</Validation> : <></>
                },
            },
        },
        {
            name: 'report.description',
            label: t('items.items.description'),
            options: {
                customBodyRender: (val, item) => (
                    <div style={{ maxHeight: '180px', overflowY: 'auto' }}>
                        {item.value.report && item.value.report.description}
                    </div>
                ),
            },
        },
        {
            name: 'report.photos',
            label: t('inspections.items.attachments', { number: '' }),
            options: {
                customBodyRender: (val, item) => {
                    const photos = item.value.report?.photos ?? []
                    console.log("Report photos value: ", photos)
                    if (photos.length > 0) {
                        if (photos.length === 1) {
                            return (
                                <Attachment
                                    state={(photos.length > 0).toString()}
                                    onClick={() => onSee(photos)}
                                >
                                    {t('inspections.items.attachment', { number: photos.length })}
                                </Attachment>
                            );
                        }
                        return (
                            <Attachment
                                state={(photos.length > 0).toString()}
                                onClick={() => onSee(photos)}
                            >
                                {t('inspections.items.attachments', { number: photos.length })}
                            </Attachment>
                        );
                    }
                    return (
                        <Attachment state={(photos.length > 0).toString()}>
                            {t('inspections.items.non-attachment')}
                        </Attachment>
                    );
                },
            },
        },
    ];
};