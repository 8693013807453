import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { TextField, CircularProgress, Autocomplete } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAutocompleteResults } from './actions'
import { selectAutocompleteResults } from './selectors'

AutocompleteInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  resource: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  query: PropTypes.string,
  refreshOnInputChange: PropTypes.bool,
  multiple: PropTypes.bool,
  getOptionLabel: PropTypes.any,
  disabled: PropTypes.bool,
  freeSolo: PropTypes.any,
  onInputChange: PropTypes.func,
  canDelete: PropTypes.bool,
  onKeyDown: PropTypes.func,
  clearOnBlur: PropTypes.bool,
};

export default function AutocompleteInput({
  onChange,
  label,
  resource,
  value,
  defaultValue,
  required,
  error,
  query,
  refreshOnInputChange,
  multiple,
  getOptionLabel,
  disabled,
  freeSolo,
  onInputChange,
  canDelete,
  onKeyDown,
  clearOnBlur,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [optionsReceived, setOptionsReceived] = useState({
    state: false,
    query: null,
  })
  const results = useSelector(selectAutocompleteResults(resource))

  let timerID
  const WAIT_INTERVAL = 1000

  const getOptions = (text) => {
    dispatch(getAutocompleteResults(resource, { q: text, ...query }))
  }

  useEffect(() => {
    if (results && Array.isArray(results)) {
      setLoading(false)
      setOptions(results)
      setOptionsReceived({
        state: true,
        query,
      })
    }
  }, [results, query, t])

  useEffect(() => {
    if (optionsReceived.state && query !== optionsReceived.query) {
      setOptions([])
      setOptionsReceived({
        state: false,
        query,
      })
    }
  }, [query, optionsReceived.state, optionsReceived.query])

  const getChipProps = () => {
    if (canDelete === false) {
      return {
        deleteIcon: null,
        onDelete: null,
      }
    }
    return null
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const buildOptionLabel = useCallback((option) => {
    if (!option) {
      return ''
    }
    if (getOptionLabel) {
      return getOptionLabel(option)
    }
    return option.title || ''
  })

  return (
    <Autocomplete
      id={`autocomple-${resource}`}
      loading={loading}
      loadingText={t('common.loading')}
      options={options}
      disabled={disabled}
      freeSolo={freeSolo}
      clearOnBlur={clearOnBlur}
      getOptionLabel={(option) => {
        if (!option) {
          return ''
        }
        if (getOptionLabel) {
          return getOptionLabel(option)
        }
        return option.title || ''
      }}
      noOptionsText={t('common.no-options')}
      value={value}
      defaultValue={defaultValue}
      multiple={multiple === true}
      disableClearable={canDelete === false}
      clearOnEscape={canDelete}
      ChipProps={getChipProps()}
      onChange={(event, newValue, reason) => {
        if (canDelete === true) {
          onChange(newValue)
        } else if (canDelete === false && reason !== 'remove-option') {
          onChange(newValue)
        } else {
          onChange(value)
        }
      }}
      renderOption={(props, option) => {
        // eslint-disable-next-line react/prop-types
        const customKey = option.id || props.key
        return (
          <li {...props} key={customKey}>
            {buildOptionLabel(option)}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          error={error}
          required={required}
          helperText={required ? t('common.required') : ''}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onKeyDown={(e) => {
            if (onKeyDown) {
              onKeyDown(e)
            }
          }}
        />
      )}
      onInputChange={(event, newTextValue) => {
        // Only search again if the event is about the user writing or deleting something and not selecting an option from the results
        if (event && event.type !== 'change') {
          return;
        }

        if (refreshOnInputChange) {
          setLoading(true)
          clearTimeout(timerID)
          timerID = setTimeout(() => {
            getOptions(newTextValue)
          }, WAIT_INTERVAL)
        }
        if (onInputChange) {
          onInputChange(newTextValue)
        }
      }}
      onOpen={() => {
        if (!optionsReceived.state && !loading) {
          setLoading(true)
          clearTimeout(timerID)
          getOptions()
        }
      }}
      onFocus={() => {
        if (!loading) {
          setLoading(true)
          clearTimeout(timerID)
          getOptions()
        }
      }}
      isOptionEqualToValue={(option, val) => (option.id ? option.id === val.id : option === val)}
    />
  )
}
