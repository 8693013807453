import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { Grid, TextField } from '@mui/material';
// components
import AutocompleteInput from '../../autocomplete-input';
import BaseDialog from '../../base-dialog';
import ImagePicker from '../../image-picker';
import { useNotificationsProvider } from '../../../context/NotificationsContext';
// actions
import { editItem, requestUpload, upload } from '../../../pages/items/actions';

EditItemDialog.propTypes = {
    title: PropTypes.string,
    value: PropTypes.object,
    parent: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onEdited: PropTypes.func,
};

export default function EditItemDialog({ title, value, parent, open, onClose, onEdited }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { showNotification } = useNotificationsProvider();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [itemTitle, setItemTitle] = useState('');
    const [itemDescription, setItemDescription] = useState('');
    const [itemParent, setItemParent] = useState({});
    const [images, setImages] = useState([]);

    const resetState = () => {
        setLoading(false);
        setError(null);
        setItemTitle(value.title);
        setItemDescription(value.description);
        setItemParent(parent ?? parent);
        setImages(value.photos);
    };

    const onCloseDialog = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            onClose();
        } else {
            resetState();
            onClose();
        }
    };

    const onConfirm = () => {
        if (itemTitle.length === 0) {
            setError('title_required');
            return;
        }

        if (itemDescription.length === 0) {
            setError('description_required');
            return;
        }

        setLoading(true);

        const data = {
            title: itemTitle,
            description: itemDescription
        };

        if (itemParent?.id) {
            data.parentId = itemParent.id
        }

        if (images.length > 0) {
            const imagesURLs = [];
            // Map images and upload them
            let successfulUploadsCounter = 0;
            images.forEach((image) => {
                // Get S3 url
                const requestUploadData = {
                    contentType: image.type,
                };
                dispatch(
                    requestUpload(requestUploadData, (response) => {
                        // Prepare multiform data
                        const formDataFields = response.formData.fields || {};
                        const formData = new FormData();
                        Object.keys(formDataFields).forEach((fieldName) => {
                            const fieldValue = formDataFields[fieldName];
                            formData.append(fieldName, fieldValue);
                        });
                        formData.append('file', image);
                        // Upload image to S3
                        dispatch(
                            upload(response.formData.url, formData, () => {
                                successfulUploadsCounter += 1;
                                imagesURLs.push(response.url);
                                if (successfulUploadsCounter === images.length) {
                                    data.photosJson = JSON.stringify(imagesURLs)
                                    // Finally create item
                                    dispatch(
                                        editItem(
                                            value.id,
                                            data,
                                            () => {
                                                resetState();
                                                onEdited();
                                            },
                                            (error) => {
                                                const errorCode = error.response.data.validationErrors && error.response.data.validationErrors[0].errorCode;
                                                if (errorCode === 'ITEM_DETAILS_ALREADY_USED') {
                                                    showNotification('error', t('items.add-item-dialog.already-exists-error'));
                                                } else if (errorCode === 'ITEM_LEVEL_EXCEEDED') {
                                                    showNotification('error', t('items.add-item-dialog.level-exceeded-error'));
                                                } else if (errorCode === 'ITEM_INVALID_DESCENDANT_AS_PARENT') {
                                                    showNotification('error', t('items.add-item-dialog.invalid-descendant-as-parent-error'));
                                                } else {
                                                    showNotification('error', t('common.api-error'));
                                                }
                                                setLoading(false);
                                            }
                                        )
                                    );
                                }
                            }, () => {
                                showNotification('error', t('common.api-error'));
                            })
                        )
                    }, () => {
                        showNotification('error', t('common.api-error'));
                    })
                );
            });
        } else {
            dispatch(
                editItem(
                    value.id,
                    data,
                    () => {
                        resetState();
                        onEdited();
                    },
                    (error) => {
                        const errorCode = error.response.data.validationErrors && error.response.data.validationErrors[0].errorCode;
                        if (errorCode === 'ITEM_DETAILS_ALREADY_USED') {
                            showNotification('error', t('items.add-item-dialog.already-exists-error'));
                        } else if (errorCode === 'ITEM_LEVEL_EXCEEDED') {
                            showNotification('error', t('items.add-item-dialog.level-exceeded-error'));
                        } else if (errorCode === 'ITEM_INVALID_DESCENDANT_AS_PARENT') {
                            showNotification('error', t('items.add-item-dialog.invalid-descendant-as-parent-error'));
                        } else {
                            showNotification('error', t('common.api-error'));
                        }
                        setLoading(false);
                    }
                )
            );
        }
    };

    useEffect(() => {
        console.log(`VALUE: ${JSON.stringify(value)}`)
        if (!value) {
            return;
        }

        setItemTitle(value.title);
        setItemDescription(value.description);
        setItemParent(parent ?? parent);
        setImages(value.photos);
    }, [value, parent]);

    return (
        <BaseDialog
            open={open}
            onClose={onCloseDialog}
            onConfirm={onConfirm}
            title={title}
            loading={loading}
        >
            <Grid container direction="column" spacing={4}>
                <Grid item marginTop={2}>
                    <ImagePicker initialImages={images} onChange={setImages} />
                </Grid>
                <Grid item marginTop={2}>
                    <TextField
                        error={error === 'title_required'}
                        onChange={(event) => {
                            setItemTitle(event.target.value);
                        }}
                        defaultValue={value && itemTitle}
                        label={t('items.edit-item-dialog.item-title')}
                        variant="standard"
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        error={error === 'description_required'}
                        onChange={(event) => {
                            setItemDescription(event.target.value);
                        }}
                        defaultValue={value && itemDescription}
                        label={t('items.edit-item-dialog.description-title')}
                        variant="standard"
                        multiline
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item marginTop={2} marginBottom={2}>
                    <AutocompleteInput
                        onChange={(val) => {
                            setItemParent(val);
                        }}
                        defaultValue={itemParent && itemParent}
                        label={t('items.add-item-dialog.parent-item-title')}
                        resource="items/autocomplete"
                        canDelete
                        refreshOnInputChange
                        clearOnBlur={false}
                        freeSolo
                    />
                </Grid>
            </Grid>
        </BaseDialog>
    );
};