import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const State = {
    Loading: 'loading',
    Error: 'error',
    Success: 'success'
}

UserIcon.propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    state: PropTypes.oneOf(Object.values(State))
};

function UserIcon({ src, width, height, state }) {
    // Determine overlay color and opacity based on the state
    let overlayColor = 'transparent';
    let overlayOpacity = 0;

    switch (state) {
        case State.Loading:
            overlayColor = 'white';
            overlayOpacity = 0.6;
            break;
        case State.Error:
            overlayColor = 'red';
            overlayOpacity = 0.6;
            break;
        case State.Success:
        default:
            overlayOpacity = 0; // No overlay for success state
            break;
    }

    return (
        <Box sx={{
            position: 'relative',
            width,
            height,
            borderRadius: '50%',
            overflow: 'hidden'
        }}>
            <Box
                component="img"
                src={src}
                sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }}
            />
            {overlayOpacity > 0 && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: overlayColor,
                        opacity: overlayOpacity
                    }}
                />
            )}
        </Box>
    );
};

export {
    UserIcon,
    State
}