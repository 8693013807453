import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { FilePond } from 'react-filepond'


ImagePicker.propTypes = {
  onChange: PropTypes.func,
  initialImages: PropTypes.array,
};

export default function ImagePicker({
  onChange,
  initialImages,
}) {
  const { t } = useTranslation()

  return (
    <FilePond
      files={initialImages}
      onupdatefiles={(files) => {
        onChange(files.map((file) => file.file))
      }}
      onreorderfiles={(files) => {
        onChange(files.map((file) => file.file))
      }}
      allowMultiple
      maxFiles={10}
      allowReorder
      acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'image/webp']}
      file
      labelIdle={t('components.image-picker.dropzone-text')}
      labelFileLoadError={t('components.image-picker.file-load-error')}
      labelFileProcessing={t('components.image-picker.file-processing')}
      labelFileProcessingComplete={t('components.image-picker.file-processing-complete')}
      labelFileProcessingAborted={t('components.image-picker.file-processing-aborted')}
      labelFileProcessingError={t('components.image-picker.file-processing-error')}
      labelTapToRetry={t('components.image-picker.tap-to-retry')}
      labelTapToCancel={t('components.image-picker.tap-to-cancel')}
      labelFileTypeNotAllowed={t('components.image-picker.file-type-not-allowed')}
      fileValidateTypeLabelExpectedTypes={t('components.image-picker.file-validate-type-expected-types')}
    />
  )
}
