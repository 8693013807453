import { SET_FEATURES } from './types';

const initialState = {
  map: {},
};

export default function featuresReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FEATURES: {
      const map = (action.payload.data || [])
      return {
        ...state,
        map,
      };
    }
    default:
      break;
  }
  return state;
}
