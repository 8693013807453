import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// material
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination,
    Paper,
    IconButton,
    Checkbox,
    Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// NOTE: 
// - Improve part of pagination;
// - Improve looks (row with not consistent);
// - Implement sorting;
// 
// OVERALL:
// Functional for receiving basic items.

BFHierarchyTable.propTypes = {
    id: PropTypes.string,
    columns: PropTypes.array,
    data: PropTypes.array,
    leaf: PropTypes.string,
    page: PropTypes.number,
    onChangePage: PropTypes.func,
    expandableRows: PropTypes.bool,
    selectableRows: PropTypes.bool,
    fullSelector: PropTypes.bool,
    rowsSelected: PropTypes.array,
    onRowSelectionChange: PropTypes.func,
};

export default function BFHierarchyTable({
    id,
    columns,
    data,
    leaf,
    page,
    onChangePage,
    expandableRows,
    selectableRows,
    fullSelector,
    rowsSelected,
    onRowSelectionChange,
}) {

    const { t } = useTranslation();

    // Method to manage the rows that are selected.
    const rowsSelectedHandler = (event, ID) => {
        const { checked } = event.target;
        let newRowsSelected = rowsSelected.slice();
        const leafIds = getLeafsIds(data.items, leaf, ID);
        let parentId = getParentId(data.items, leaf, ID);

        // Add or remove selected row.
        if (checked) {
            newRowsSelected.push(ID);
        } else {
            newRowsSelected = newRowsSelected.filter(id => id !== ID);
        }

        // Check if the row has leafs to added then
        // to the selected list with the obejct.
        if (leafIds.length !== 0) {
            if (checked) {
                leafIds.forEach(id => {
                    if (!newRowsSelected.includes(id)) {
                        newRowsSelected.push(id);
                    }
                })
            } else if (fullSelector) {
                newRowsSelected = newRowsSelected.filter(id => !leafIds.includes(id));
            }
        }

        // Check if the object has parent object, and if all
        // the leafs are inside the select array of rows to add
        // the parent with them.
        while (parentId !== null) {
            const parentLeafs = getLeafsIds(data.items, leaf, parentId.id);
            const allLeafsSelected = parentLeafs.every(id => newRowsSelected.includes(id));
            const anyLeafUnchecked = parentLeafs.some(id => !newRowsSelected.includes(id));

            if (allLeafsSelected && !anyLeafUnchecked && fullSelector) {
                newRowsSelected.push(parentId.id);
            } else {
                const parentIndex = newRowsSelected.indexOf(parentId.id);
                if (parentIndex !== -1) {
                    newRowsSelected.splice(parentIndex, 1);
                }
            }

            parentId = getParentId(data.items, leaf, parentId.id);
        }

        onRowSelectionChange(newRowsSelected);
    };


    // Component Betterfield-Hierarchy-Table
    return (
        data &&
        <Paper elevation={3}>
            <TableContainer>
                <Table key={id} aria-label='collapsible table'>
                    {columns &&
                        <TableHead>
                            <RowColumns
                                data={getAllIds(data.items, leaf)}
                                columns={columns}
                                expandableRows={expandableRows}
                                selectableRows={selectableRows}
                                allChecked={rowsSelected?.length === dataLength(data.items, leaf)}
                                indeterminate={rowsSelected?.length > 0 && rowsSelected?.length < dataLength(data.items, leaf)}
                                onRowSelectionChange={onRowSelectionChange}
                            />
                        </TableHead>
                    }
                    <TableBody>
                        {columns && data.itemCount > 0 ? (
                            addLevels(data.items, leaf).map((row) => (
                                <ExpandableRow
                                    key={row.id}
                                    item={row}
                                    leaf={leaf}
                                    columns={columns}
                                    expandableRows={expandableRows}
                                    selectableRows={selectableRows}
                                    rowsSelected={rowsSelected}
                                    rowsSelectedHandler={rowsSelectedHandler}
                                />
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length + 2} align='center'>
                                    <Typography variant='subtitle2'>
                                        {t('components.table.body.noMatch')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                count={data?.itemCount ?? dataLength(data.items, leaf)}
                                rowsPerPage={data.range.limit}
                                page={page ?? 0}
                                onPageChange={onChangePage ?? undefined}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Paper>
    );
}

RowColumns.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    expandableRows: PropTypes.bool,
    selectableRows: PropTypes.bool,
    allChecked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onRowSelectionChange: PropTypes.func,
};

// This method creates a row of columns. asks for an array 
// of all the ids that exist in the [data] object, asks for 
// an array with objects where it has the configuration for 
// the columns [columns], checks if a checkbox [selectableRows] 
// is implemented, receives two boolean values, one to check 
// if all the checkboxes in the table are filled [allChecked], 
// and another to put the undetermined sign in case there are 
// no checkboxes to be filled [indeterminate], and finally a 
// set function to update the array of selected rows, in case 
// the main checkbox is filled with the array of selected rows 
// must have all the ids of all rows, if it is deselected the 
// same array must be empty [onRowSelectionChange].
function RowColumns({ data, columns, expandableRows, selectableRows, allChecked, indeterminate, onRowSelectionChange }) {
    const [selectedAll, setSelectedAll] = useState(false);

    const selectedAllHandler = (event) => {
        const { checked } = event.target;
        setSelectedAll(checked);
        onRowSelectionChange(checked ? data : []);
    }

    return (
        <TableRow>
            {selectableRows && (
                <TableCell width={1}>
                    <Checkbox
                        checked={allChecked ?? selectedAll}
                        indeterminate={indeterminate}
                        onChange={selectedAllHandler}
                    />
                </TableCell>
            )}
            {columns.map(field => (
                <TableCell key={field.name} style={{ alignItems: 'right' }}>
                    {field.label}
                </TableCell>
            ))}
            {expandableRows && <TableCell />}
        </TableRow>
    );
}

ExpandableRow.propTypes = {
    key: PropTypes.number,
    item: PropTypes.object,
    columns: PropTypes.array,
    leaf: PropTypes.string,
    expandableRows: PropTypes.bool,
    selectableRows: PropTypes.bool,
    rowsSelected: PropTypes.array,
    rowsSelectedHandler: PropTypes.func,
};

/* Attention! This method uses recursion. */
// This method creates collapsible rows. It accepts a single  
// item [parent(object)/child(leaf)], the array of columns 
// to see what columns where applied and show just that 
// information the field where the array with its successors 
// is, whether Checkbox and Expandable Rows were implemented 
// and the boolean to check if the row Checkbox was checked
// and the onChange handler to put them in the array if checked.
function ExpandableRow({ key, item, columns, leaf, expandableRows, selectableRows, rowsSelected, rowsSelectedHandler }) {
    const [open, setOpen] = useState(expandableRows === undefined);
    let rowIndex = 0;

    const toggleCollapse = () => {
        setOpen(!open);
    };

    // The first row is the "parent" component, the second row 
    // is the succeeding "child". The second row calls its own  
    // method [ExpandableRow] consecutively until there are no 
    // more children to render.
    // The first cell is the Checkbox, the second cell is what
    // comes in the columns, and the last cell is the expandable
    // arrows.
    return (
        <>
            <TableRow key={key} hover sx={{ cursor: 'pointer' }}>
                {selectableRows && (
                    <TableCell width={1} style={{ paddingLeft: item?.level * 15 }}>
                        <Checkbox
                            checked={rowsSelected.includes(item.id)}
                            onChange={(event) => rowsSelectedHandler(event, item.id)}
                        />
                    </TableCell>
                )}

                {columns.map(column => {
                    rowIndex += 1;

                    if (column.options?.customBodyRender) {
                        return (
                            <TableCell key={column.name} style={{ paddingLeft: item?.level * 20 }}>
                                {column.options.customBodyRender(item[column.name], {
                                    rowIndex,
                                    value: item
                                })}
                            </TableCell>
                        );
                    }

                    return (
                        <TableCell key={column.name} style={{ paddingLeft: item?.level * 20 }}>
                            {item[column.name]}
                        </TableCell>
                    );
                })}

                {expandableRows &&
                    <TableCell align='right'>
                        {item.children.length > 0 && (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => toggleCollapse(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )}
                    </TableCell>
                }
            </TableRow>
            {open &&
                item[leaf].map((child) => (
                    <ExpandableRow
                        key={child.id}
                        item={child}
                        leaf={leaf}
                        columns={columns}
                        expandableRows={expandableRows}
                        selectableRows={selectableRows}
                        rowsSelected={rowsSelected}
                        rowsSelectedHandler={rowsSelectedHandler}
                    />
                ))
            }
        </>
    );
};

/* Attention! This method uses recursion. */
// Method to add levels to the rows, this way we know
// whether the row is an object or their child.
const addLevels = (data, leaf, level = 1) => {
    try {
        // Map each item to a new object with the updated level
        return data.map(item => {
            const newItem = { ...item, level };

            if (newItem[leaf] && newItem[leaf].length > 0) {
                newItem[leaf] = addLevels(newItem[leaf], leaf, level + 1);
            }

            return newItem;
        });
    } catch (error) {
        console.error("addLevels error: ", error);
        // In case of error, return the original data without modification
        return data;
    }
};

/* Attention! This method uses recursion. */
// Method to fetch all ids within the data array.
// Counting on the leafs of objects.
export const getAllIds = (data, leaf) => {
    try {
        return data.reduce((ids, item) => {
            if (!ids.includes(item.id)) {
                ids.push(item.id);
                if (item[leaf] && item[leaf].length > 0) {
                    const leafIds = getAllIds(item[leaf], leaf);
                    ids.push(...leafIds);
                }
            }
            return ids;
        }, []);
    } catch (error) {
        console.error("getAllIds error: ", error);
        return [];
    }
};

/* Attention! This method uses recursion. */
// Method to know the actual size of the data array.
// Counting on the leafs of objects.
const dataLength = (data, leaf) => {
    try {
        return data.reduce((count, item) => {
            count += 1;
            if (item[leaf] && item[leaf].length > 0) {
                count += dataLength(item[leaf], leaf);
            }
            return count;
        }, 0);
    } catch (error) {
        console.error("dataLength error: ", error);
        return 0;
    }
};

// Method to fetch the id of the object to which 
// the leaf belongs.
function getParentId(data, leaf, id) {
    let parent = null;
    let stack = [...data];

    while (stack.length > 0) {
        const item = stack.shift();

        if (item[leaf] && item[leaf].length > 0) {
            stack = [...stack, ...item[leaf]];
        }

        if (item[leaf] && item[leaf].some(child => child.id === id)) {
            parent = item;
            break;
        }
    }

    return parent;
}

/* Attention! This method uses recursion. */
// Method that returns all the leaves of an object 
// depending on its id. 
export const getLeafsIds = (data, leaf, id) => {
    const result = [];

    try {
        const dataLoop = (items) => {
            items.forEach((item) => {
                if (item.id === id && item[leaf] && item[leaf].length > 0) {
                    result.push(...getAllIds(item[leaf], leaf));
                }
                if (item[leaf] && item[leaf].length > 0) {
                    dataLoop(item[leaf]);
                }
            });
        };

        dataLoop(data);
    } catch (error) {
        console.error("getLeafsIds error: ", error);
        return [];
    }

    return result;
};