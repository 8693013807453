import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import { Button, Grid, SvgIcon, Typography } from '@mui/material';
// component
import BaseDialog from '../../base-dialog';
import BFTable from '../../table';
import BFHierarchyTable from '../../hierarchy-table';
import generateColumns from './columns';
import { manageItemsFlow } from '../../../pages/flows/actions';
import { ReactComponent as AddIcon } from '../../../assets/ic_add.svg';
import { useNotificationsProvider } from '../../../context/NotificationsContext';
import ItemFlowDialog from '../item-flow-dialog';
import { FlowTypes } from '../../../entities/flow-type'
import { hasPermission } from '../../../pages/login/selectors';
import { Permissions } from '../../../entities/permissions';

const ButtonStyle = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    justifyContent: 'space-between',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1, 3),
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
}));

SeeFlowDialog.propTypes = {
    open: PropTypes.bool,
    flow: PropTypes.object,
    onClose: PropTypes.func,
    onEdited: PropTypes.func,
};

export default function SeeFlowDialog({ flow, open, onClose, onEdited }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { showNotification } = useNotificationsProvider();

    const [openItemFlowDialog, setOpenItemFlowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [type, setType] = useState(null);

    // Permissions
    const hasAddItemToFlowPermission = useSelector(hasPermission(Permissions.PAGE_FLOWS_ADD_ITEM))
    const hasRemoveItemFromFlowPermission = useSelector(hasPermission(Permissions.PAGE_FLOWS_REMOVE_ITEM))

    const [itemsTableState, setItemsTableState] = useState({
        page: 0,
        sort: null,
        filters: null
    });

    const resource = `item`;

    const resetState = () => {
        setLoading(false);
        setItems([...flow.items]);
        setType(flow.flowType);
    };

    const onCloseDialog = (event, reason) => {
        if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
            onClose();
        } else {
            resetState();
            onClose();
        }
    };

    const getAllIds = (items) => {
        const ids = new Set();

        // Helper function to recursively extract IDs
        const extractIds = (item) => {
            ids.add(item.id); // Add the item's ID to the Set

            // If the item has children, recursively get their IDs
            if (item.children && item.children.length > 0) {
                item.children.forEach(child => extractIds(child));
            }
        };

        // Process each item
        items.forEach(item => extractIds(item));

        return Array.from(ids); // Convert the Set back to an array
    };

    const onConfirm = () => {
        if (!flow) {
            return;
        }

        setLoading(true);

        const data = {
            items: getAllIds(items),
        };

        dispatch(manageItemsFlow(flow.id, data, () => {
            setLoading(false);
            resetState();
            onEdited();
        }, () => {
            setLoading(false);
            showNotification('error', t('common.api-error'));
        }));
    };

    /* Attention! This method uses recursion. */
    // Method to remove the item chosen by the user to be 
    // absolved from the array.
    const removeObjectById = (data, leaf, id) => {
        if (!data) return [];

        try {
            return data.filter(item => item.id !== id)
                .map(item => ({
                    ...item,
                    [leaf]: item[leaf] ? removeObjectById(item[leaf], leaf, id) : []
                }));
        } catch (error) {
            console.error("removeObjectById error: ", error);
            return data;  // Return the original data in case of an error
        }
    };

    const onAdd = () => {
        if (!openItemFlowDialog) {
            setOpenItemFlowDialog(true);
        }
    };

    // TODO - I'm still not sure what to do here.
    // Create a modal to show the info ?
    // const onSee = () => {

    // };

    // Depending on the type of table we receive different 
    // data in the columns, if it is a BFTable we need to 
    // obtain the rowIndex to go to the array to fetch the 
    // object, if it is a BFHierarchyTable we already have 
    // the option of immediately receiving the item selected 
    // from the columns.
    const onDelete = (row) => {
        if (row !== undefined) {
            const updatedItems = type.name === FlowTypes.SEQUENTIAL
                ? removeObjectById(items, 'children', items[row.rowIndex].id)
                : removeObjectById(items, 'children', row.value.id);

            setItems(updatedItems);
        }
    };

    const itemsTableColumns = generateColumns({
        t,
        onDelete,
        hasRemoveItemFromFlowPermission,
    });

    useEffect(() => {
        if (flow) {
            setType(flow.flowType);
            setItems([...flow.items]);
        }
    }, [flow]);

    return (
        <>
            <ItemFlowDialog
                flow={{ ...flow }}
                previouslySelectedItems={(items && getAllIds(items)) ?? []}
                open={openItemFlowDialog}
                onClose={() => setOpenItemFlowDialog(false)}
                onAddItems={(newItems) => {
                    setItems(newItems);
                }}
            />
            <BaseDialog
                title={flow?.name}
                open={open}
                onClose={onCloseDialog}
                onConfirm={onConfirm}
                loading={loading}
                openButtonTitle={t('common.save')}
                hideActions={!hasAddItemToFlowPermission && !hasRemoveItemFromFlowPermission}
            >
                <Grid direction="column" spacing={4} marginTop={2}>
                    <Grid container direction="row" justifyContent="space-between" marginBottom={items?.length > 0 ? 2 : 20}>
                        <Grid item >
                            <Typography variant="subtitle2">{type && type.name.toLowerCase() === FlowTypes.SEQUENTIAL ? t('flows.edit-flow-dialog.select-type.sequencial') : t('flows.edit-flow-dialog.select-type.free')}</Typography>
                        </Grid>
                        {
                            hasAddItemToFlowPermission && (
                                <Grid item >
                                    <ButtonStyle
                                        startIcon={(<SvgIcon><AddIcon style={{ width: 20 }} /></SvgIcon>)}
                                        onClick={onAdd}>
                                        {t('items.add-item-dialog.title')}
                                    </ButtonStyle>
                                </Grid>
                            )
                        }
                    </Grid>
                    {items?.length > 0 && (type.name === FlowTypes.SEQUENTIAL ? (
                        <Grid item marginBottom={2}>
                            <BFTable
                                id={resource}
                                data={{ itemCount: items.length, items: [...items], range: { limit: 15 } }}
                                columns={itemsTableColumns}
                                page={itemsTableState.page}
                                sort={itemsTableState.sort}
                                onChangePage={(currentPage) => {
                                    setItemsTableState({ ...itemsTableState, page: currentPage });
                                }}
                                onColumnSortChange={(changedColumn, direction) => {
                                    const newSort = {
                                        field: changedColumn,
                                        direction: direction.toUpperCase(),
                                    };
                                    setItemsTableState({ ...itemsTableState, sort: newSort });
                                }}
                            />
                        </Grid>) : (
                        <Grid item marginBottom={2}>
                            <BFHierarchyTable
                                id={resource}
                                data={{ itemCount: items.length, items: [...items], range: { limit: 15 } }}
                                columns={itemsTableColumns}
                                leaf={'children'}
                                page={itemsTableState.page}
                                sort={itemsTableState.sort}
                                onChangePage={(currentPage) => {
                                    setItemsTableState({ ...itemsTableState, page: currentPage });
                                }}
                                onColumnSortChange={(changedColumn, direction) => {
                                    const newSort = {
                                        field: changedColumn,
                                        direction: direction.toUpperCase(),
                                    };
                                    setItemsTableState({ ...itemsTableState, sort: newSort });
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </BaseDialog>
        </>
    );
};
