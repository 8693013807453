import { LOAD_PERMISSIONS, RECEIVED_PERMISSIONS } from '../permissions/types';
import { SET_SESSION } from '../session/types';
import { fetchPermissions, setEnabledPermissions, loadPermissions } from '../permissions/actions';
import LocalStorage from '../utils/storage';

const STORAGE_KEY_PREFIX = 'permissions_';

const permissionsMiddleware =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);

        switch (action.type) {
          case SET_SESSION:
            dispatch(loadPermissions(action.payload));
            break;
          case LOAD_PERMISSIONS: {
            const session = action.payload;
            if (session && session.user && session.user.id) {
              // Use stored permissions while fresh ones are requested from API
              const userId = session.user.id;
              const storedPermissions = loadPermissionsFromStorage(LocalStorage, userId);
              dispatch(setEnabledPermissions(storedPermissions));

              dispatch(fetchPermissions(userId));
            } else {
              dispatch(setEnabledPermissions([]));
            }
            break;
          }
          case RECEIVED_PERMISSIONS: {
            const { permissions, userId } = action.payload;
            storePermissionsInStorage(LocalStorage, userId, permissions);
            const storedPermissions = loadPermissionsFromStorage(LocalStorage, userId);
            dispatch(setEnabledPermissions(storedPermissions));
            break;
          }
          default:
            break;
        }
      };

function loadPermissionsFromStorage(storage, userId) {
  const key = `${STORAGE_KEY_PREFIX}${userId}`;
  return storage.get(key, true);
}

function storePermissionsInStorage(storage, userId, permissions) {
  const key = `${STORAGE_KEY_PREFIX}${userId}`;
  return storage.set(key, permissions, true);
}

export default permissionsMiddleware;
