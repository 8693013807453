// material
import { IconButton } from '@mui/material';
// component
import { ReactComponent as EditIcon } from '../../assets/ic_edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/ic_delete.svg'
import { ReactComponent as SeeIcon } from '../../assets/ic_see.svg'

export default function generate({ t, onOpen, onEdit, onDelete, hasEditPermission, hasRemovePermission }) {
    const columns = [
        {
            name: 'name',
            label: t('flows.items.title'),
        },
        {
            name: 'flowType.translatedName',
            label: t('flows.items.type'),
        },
        {
            name: 'open',
            label: ' ',
            options: {
                sort: false,
                customBodyRender: (val, tableMeta) => (
                    <IconButton
                        onClick={() => onOpen(tableMeta.rowIndex)}
                        color="primary"
                    >
                        <SeeIcon />
                    </IconButton>
                ),
            },
        },
    ];

    if (hasEditPermission) {
        columns.push({
            name: 'edit',
            label: ' ',
            options: {
                sort: false,
                customBodyRender: (val, tableMeta) => (
                    <IconButton
                        onClick={() => onEdit(tableMeta.rowIndex)}
                        color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                ),
            },
        });
    }

    if (hasRemovePermission) {
        columns.push({
            name: 'delete',
            label: ' ',
            options: {
                sort: false,
                customBodyRender: (val, tableMeta) => (
                    <IconButton
                        onClick={() => onDelete(tableMeta.rowIndex)}
                        color="primary"
                    >
                        <DeleteIcon />
                    </IconButton>
                ),
            },
        });
    }

    return columns;
};