import { BOOTSTRAP_APP } from '../events/types';
import { LOAD_FEATURES, RECEIVED_FEATURES } from '../redux/features/types';
import { SET_SESSION } from '../session/types';
import { fetchFeatures, setEnabledFeatures, loadFeatures } from '../redux/features/actions';
import LocalStorage from '../utils/storage';

const STORAGE_KEY = 'features';

const featuresMiddleware =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);

        switch (action.type) {
          case BOOTSTRAP_APP:
            dispatch(loadFeatures());
            break;
          case SET_SESSION:
            dispatch(loadFeatures());
            break;
          case LOAD_FEATURES: {
            // Use stored features while fresh ones are requested from API
            const storedFeatures = loadFeaturesFromStorage(LocalStorage);
            dispatch(setEnabledFeatures(storedFeatures));

            dispatch(fetchFeatures());
            break;
          }
          case RECEIVED_FEATURES: {
            const features = action.payload;
            storeFeaturesInStorage(LocalStorage, features);
            const storedFeatures = loadFeaturesFromStorage(LocalStorage);
            dispatch(setEnabledFeatures(storedFeatures));
            break;
          }
          default:
            break;
        }
      };

function loadFeaturesFromStorage(storage) {
  const key = `${STORAGE_KEY}`;
  return storage.get(key, true);
}

function storeFeaturesInStorage(storage, features) {
  const key = `${STORAGE_KEY}`;
  return storage.set(key, features, true);
}

export default featuresMiddleware;
