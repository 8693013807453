/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Card, CardContent, Grid, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { ReactComponent as CheckIcon } from '../../../../assets/ic_check.svg';
import { ReactComponent as UncheckIcon } from '../../../../assets/ic_uncheck.svg';
import { DynamicFieldTypes } from '../../../../entities/dynamic-field-type';

const ContainerStyle = styled(Grid)(() => ({
  margin: '5px 0',
  alignItems: 'center',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginRight: 5,
}));

const checkValidation = ((checked) => checked ? <CheckIcon /> : <UncheckIcon />);

InfoCard.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
}

export default function InfoCard({ t, data }) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={5}>
          <Grid item>
            <ContainerStyle container direction="row">
              <Label>
                {`${t('resource.items.resource')}:`}
              </Label>
              <Typography>
                {data?.name}
              </Typography>
            </ContainerStyle>
            <ContainerStyle container direction="row">
              <Label>
                {`${t('resource.items.resource-type')}:`}
              </Label>
              <Typography>
                {data?.resourceType.name}
              </Typography>
            </ContainerStyle>
            {
              data?.dynamicData?.map((item, index) => (
                <ContainerStyle key={index} container direction="row">
                  <Label>
                    {`${item.title}:`}
                  </Label>
                  {
                    item.dataType === DynamicFieldTypes.BOOLEAN ?
                      checkValidation(item.value) :
                      <Typography>
                        {item.dataType === DynamicFieldTypes.DATE ?
                          dayjs(item.value).format('DD/MM/YYYY') :
                          item.value
                        }
                      </Typography>
                  }
                </ContainerStyle>
              ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
