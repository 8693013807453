import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { styled } from '@mui/material/styles';
import { Stack, Typography, TextField, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNotificationsProvider } from '../../context/NotificationsContext';
// component
import Logo from '../../components/Logo';

import { resetPassword } from './actions'

// ----------------------------------------------------------------------
const LoginStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const ContainerStyle = styled(Box)(({ theme }) => ({
  shadowColor: theme.palette.secondary,
  backgroundColor: theme.palette.grey,
  shadowOpacity: 0.8,
  shadowRadius: 2,
  shadowOffset: {
    height: 1,
    width: 1
  },
  elevation: 5,
}));

const LogoStyle = {
  width: 175,
  height: 48
};

export default function LoginForm() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showNotification } = useNotificationsProvider();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t('forgot-password.invalid-email')).required(t('forgot-password.required-email'))
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: (data) => {
      dispatch(resetPassword(data.email, () => {
        setSubmitting(false)
        showNotification('success', t('forgot-password.email-sent'))
        navigate('/login')
      }, () => {
        setSubmitting(false)
        showNotification('error', t('forgot-password.email-not-sent'))
      }))
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;

  return (
    <LoginStyle>
      <ContainerStyle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={10}>
              <Stack spacing={3}>
                <Stack alignItems="center">
                  <Logo sx={LogoStyle} />
                </Stack>
                <Typography variant="h4">
                  {t('forgot-password.title')}
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label={t('forgot-password.email-label')}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>
              <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={2} sx={{ my: 2 }}>
                <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
                  {t('forgot-password.send-button')}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </ContainerStyle>
    </LoginStyle>
  );
};