import * as TYPE from './types';
import { apiAction } from '../../api/actions';

export function getFlows(params) {
    return apiAction({
        resource: '/su/flows',
        params,
        onSuccess: (data) => onGetSuccess(data),
        onFailure: (error) => onGetFailed(error),
        carry: {
            label: TYPE.GET_FLOWS
        },
    });
};

function onGetSuccess(data) {
    return {
        type: TYPE.GET_FLOWS_SUCCESS,
        payload: data,
    };
};

function onGetFailed(error) {
    return {
        type: TYPE.GET_FLOWS_FAILED,
        payload: {
            error
        }
    };
};

export function addFlow(data, onSuccess, onFailure) {
    return apiAction({
        resource: '/su/flows',
        method: 'POST',
        data,
        onSuccess: (data) => onAddSuccess(data, onSuccess),
        onFailure: (error) => onAddFailed(error, onFailure),
        carry: {
            label: TYPE.ADD_FLOWS
        },
    });
};

function onAddSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.ADD_FLOWS_SUCCESS,
        payload: data
    };
};

function onAddFailed(error, onFailure) {
    onFailure(error);
    return {
        type: TYPE.ADD_FLOWS_FAILED,
        payload: {
            error
        }
    };
};

export function editFlow(id, data, onSuccess, onFailure) {
    return apiAction({
        resource: `/su/flows/${id}/edit`,
        method: 'PUT',
        data,
        onSuccess: (data) => onEditSuccess(data, onSuccess),
        onFailure: (error) => onEditFailed(error, onFailure),
        carry: {
            label: TYPE.EDIT_FLOWS
        }
    });
};

function onEditSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.EDIT_FLOWS_SUCCESS,
        payload: data
    };
};

function onEditFailed(error, onFailure) {
    onFailure(error);
    return {
        type: TYPE.EDIT_FLOW_FAILED,
        payload: {
            error
        }
    };
};

export function deleteFlow(id, data, onSuccess, onFailure) {
    return apiAction({
        resource: `/su/flows/${id}/delete`,
        method: 'DELETE',
        data,
        onSuccess: (data) => onDeleteSuccess(data, onSuccess),
        onFailure: (error) => onDeleteFailed(error, onFailure),
        carry: {
            label: TYPE.DELETE_FLOW
        }
    });
};

function onDeleteSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.DELETE_FLOW_SUCCESS,
        payload: data
    };
};

function onDeleteFailed(error, onFailure) {
    onFailure(error);
    return {
        type: TYPE.DELETE_FLOW_FAILED,
        payload: {
            error
        }
    };
};

export function manageItemsFlow(id, data, onSuccess, onFailure) {
    return apiAction({
        resource: `/su/flows/${id}/manage-items`,
        method: 'PUT',
        data,
        onSuccess: (data) => onManageItemsSuccess(data, onSuccess),
        onFailure: (error) => onManageItemsFailed(error, onFailure),
        carry: {
            label: TYPE.MANAGE_ITEMS_FLOW
        }
    });
};

function onManageItemsSuccess(data, onSuccess) {
    onSuccess();
    return {
        type: TYPE.MANAGE_ITEMS_FLOW_SUCCESS,
        payload: data
    };
};

function onManageItemsFailed(error, onFailure) {
    onFailure(error);
    return {
        type: TYPE.MANAGE_ITEMS_FLOW_FAILED,
        payload: {
            error
        }
    };
};